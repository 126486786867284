import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Motion, spring } from 'react-motion';
import { ICON_MAPPING } from './constants';
import {
  selectCustomerToken,
  selectCustomerStatus,
} from '../Profile/selectors';

const ActionButton = ({
  actionName,
  action,
  token,
  currentStatus,
  hide,
  tiny,
  wideButton,
  lastMinuteAllowed,
  screen,
  bundleExpired,
  bundled,
}) => {
  if (hide) {
    return null;
  }

  const customerToken = useSelector(selectCustomerToken);
  const customerStatus = useSelector(selectCustomerStatus);

  const [loading, setLoading] = useState(false);

  const loadingLabels = {
    claim: 'Claiming...',
    release: 'Releasing...',
    transfer: 'Transferring...',
  };

  useEffect(() => {
    setLoading(false);
    return () => setLoading(false);
  }, []);

  return (
    <>
      {tiny && action && (
        <div className='medium-3 large-3'>
          <Link to={`/perks/${customerToken}/${token}/${actionName}`}>
            <button
              type='button'
              className={classNames(`ds-offer-button ds-${actionName}`, {
                'tiny-btn': tiny,
              })}
              style={{ fontSize: '0.9em', height: '10px' }}
            >
              {loading
                ? startCase(loadingLabels[actionName])
                : startCase(actionName)}
            </button>
          </Link>
        </div>
      )}
      {!tiny && (
        <button
          type='button'
          className={classNames(`ds-offer-button ds-${actionName}`, {
            'disabled-btn': !currentStatus.match(actionName) && !currentStatus.match(/sent/) && currentStatus !== 'claimed',
            'tiny-btn': tiny,
            'bundled-btn': bundled && currentStatus !== 'claimed',
            'wide-button-btn': wideButton || actionName === 'more_details',
            suspended: ['unpaid', 'canceled', 'past_due'].includes(customerStatus) && actionName !== 'more_details',
            pulsing: loading && actionName !== 'transfer',
          })}
          onClick={() => {
            if (currentStatus.match(/sent/) || (currentStatus === 'claimed' && actionName === 'transfer') || actionName === 'release') {
              if (actionName !== 'more_details' && !['lmp', 'release'].includes(screen)) {
                setLoading(true);
              }
              const timeout = actionName === 'more_details' ? 0 : 500;
              setTimeout(() => action(actionName, token), timeout);
              if (['unpaid', 'canceled', 'past_due'].includes(customerStatus)) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            }
            window.gtag('event', `member_${actionName}`, {
              app_name: 'member_portal',
              screen_name: 'Tickets',
            });
          }}
          disabled={
            currentStatus.match(actionName) ||
            (['unpaid', 'canceled', 'past_due'].includes(customerStatus) &&
              actionName !== 'more_details') ||
            (screen === 'lmp' && !lastMinuteAllowed) ||
            bundleExpired
          }
        >
          {!loading && currentStatus.match(actionName) && startCase(currentStatus)}
          {!loading && !currentStatus.match(actionName) && actionName !== 'more_details' && startCase(actionName)}
          {loading && loadingLabels[actionName]}
          <Motion style={{ rotate: spring(loading ? 360 : 0) }}>
            {({ rotate }) => (
              <span
                className='ds-offer-icon'
                style={{
                  transform: `rotate( ${rotate}deg )`,
                }}
              >
                {ICON_MAPPING[actionName]}
              </span>
            )}
          </Motion>
        </button>
      )}
    </>
  );
};

ActionButton.propTypes = {
  actionName: PropTypes.string,
  action: PropTypes.func,
  token: PropTypes.string,
};

export default ActionButton;
