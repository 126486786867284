import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Auth from '../Auth/Auth';
import { selectSiteName, selectCityName } from '../../helpers/metro/selectors';
import {
  selectUserEmail,
  selectUser,
} from '../Profile/selectors';

const User = () => {
  const siteName = useSelector(selectSiteName);
  const cityName = useSelector(selectCityName);
  const userEmail = useSelector(selectUserEmail);
  const user = useSelector(selectUser);
  const [showAuth, setShowAuth] = useState(false);

  return (
    <div className="cell small-12 large-6 user-info">
      <div className="card">
        <div className="card-divider">
          <h4>{siteName} Account</h4>
        </div>
        <div className="card-section">
          <div className="callout">
            { user && <h5>{userEmail}</h5>}
            { !user && <h5>Please login or create a {siteName} account</h5>}
            <p className="helper-text">This is the {siteName} account connected to your DoMORE {cityName} subscription. This account will receive 10 bonus entries.</p>
          </div>
          <p>
            <button onClick={() => setShowAuth(!showAuth)}>
              { user && 'Change account' }
              { !user && 'Connect account' }
            </button>
          </p>
        </div>
      </div>
      <Auth isOpen={showAuth} switchUser connectAccount={!user} onClose={setShowAuth} />
    </div>
  );
};

export default User;
