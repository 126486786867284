import React, { useState } from 'react';

const Truncate = ({ value, num }) => {
  if (value === '' || value === null || value === undefined) {
    return null;
  }
  const [expanded, setExpanded] = useState(false);
  const [truncate, setTruncate] = useState(
    value.length > num 
  );

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
    setTruncate((prev) => !prev);
  };

  if (num >= value.length) {
    return <div><span dangerouslySetInnerHTML={{ __html: value }} /></div>;
  }

  return truncate && !expanded ? (
    <div>
      <span dangerouslySetInnerHTML={{ __html: value.slice(0, num) }} />
      <a
        role='button'
        onClick={toggleExpand}
        style={{ fontSize: '14px', verticalAlign: 'bottom' }}
      >
        ...more▼
      </a>
    </div>
  ) : (
    <div>
      <span dangerouslySetInnerHTML={{ __html: value }} />
      <a
        role='button'
        onClick={toggleExpand}
        style={{ fontSize: '14px', verticalAlign: 'bottom' }}
      >
        ...less▲
      </a>
    </div>
  );
};
export default Truncate;
