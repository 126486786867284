import { pick, get } from 'lodash';
import {
  SET_CUSTOMER_AND_PROFILE,
  SET_OPTION,
  STORE_LEAD,
  TOGGLE_LAST_MINUTE_ALLOWED,
  SET_SMS_OPTIN,
} from './constants';
import { 
  SUBSCRIPTION_UPDATED,
} from '../Account/constants';

const initialState = {
  customer: get(window.member_data, 'customer', {}),
  profile: get(window.member_data, 'profile', {}),
  isNew: window.location.pathname.split('/').findIndex(n => n === 'new') !== -1,
  option_free_trial: window.option && !!window.option.has_trial,
  option: window.option,
  sms_optin: false,
  perk_referral: window.perk_referral,
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER_AND_PROFILE: {
      return {
        ...state,
        customer: action.customer,
        profile: action.profile,
      };
    }
    case SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        customer: {
          ...state.customer,
          status: action.payload[0].customer.status,
          member_subscriptions: action.payload,
        },
      };
    }
    case STORE_LEAD: {
      const params = pick(action.params, ['email', 'first_name', 'last_name', 'existing', 'token', 'phone_number']);
      return {
        ...state,
        lead: {
          ...state.lead,
          ...params,
        },
      };
    }
    case SET_SMS_OPTIN: {
      return {
        ...state,
        sms_optin: action.sms_optin,
      };
    }
    case SET_OPTION: {
      return {
        ...state,
        option: action.option,
        option_free_trial: action.option.has_trial,
      };
    }
    case TOGGLE_LAST_MINUTE_ALLOWED: {
      return {
        ...state,
        customer: {
          ...state.customer,
          last_minute_allowed: false,
        },
      };
    }
    default:
      return state;
  }
}

export default profileReducer;
