import { takeEvery, call, select, put } from 'redux-saga/effects';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import {
  UPDATE_SUBSCRIPTION,
  SUBSCRIPTION_UPDATED,
  UPDATE_CARD,
  GET_CARD_INFO,
  ANNUAL_UPGRADE,
  CANCEL_SUBSCRIPTION,
} from './constants';
import { selectCustomerToken } from '../Profile/selectors';
import { selectSupportEmail } from '../../helpers/metro/selectors';

export default function* watcherSaga() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
  yield takeEvery(UPDATE_CARD, updateCard);
  yield takeEvery(GET_CARD_INFO, getCardInfo);
  yield takeEvery(ANNUAL_UPGRADE, annualUpgrade);
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

function* cancelSubscription({ resolve }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const cancelSubscriptionUrl = `/cancel/${customerToken}`;
    const payload = yield call(getCancel, cancelSubscriptionUrl);
    resolve(payload);
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error(
      'Error',
      `Oops. Something went wrong! Please contact ${supportEmail} if this error persists. Code: GC01`
    );
  }
}

function* annualUpgrade({ resolve, reject, stripeToken }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const annualUpgradeUrl = `/account/${customerToken}/annual`;
    const payload = yield call(
      postUpdate,
      { upgrade: { stripeToken } },
      annualUpgradeUrl
    );
    resolve(payload);
  } catch (e) {
    reject();
  }
}

function* getCardInfo({ resolve }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const getCardInfoUrl = `/account/${customerToken}/ci`;
    const payload = yield call(getCardInfoRequest, {}, getCardInfoUrl);
    resolve(payload);
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error(
      'Error',
      `Oops. Something went wrong! Please contact ${supportEmail} if this error persists. Code: GC01`
    );
  }
}

function* updateCard({ cardToken, resolve, reject }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const updateCardUrl = `/account/${customerToken}`;
    const payload = yield call(
      postUpdate,
      { card: { token: cardToken } },
      updateCardUrl
    );
    resolve(payload);
  } catch (e) {
    reject();
  }
}

function* updateSubscription({ status, token }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const updateSubscriptionUrl = `/subscriptions/${customerToken}`;
    const payload = yield call(
      postUpdate,
      { change_subscription: { status, token } },
      updateSubscriptionUrl
    );
    yield put({ type: SUBSCRIPTION_UPDATED, payload: payload.data });
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error(
      'Error',
      `Oops. Something went wrong! Please contact ${supportEmail} if this error persists. Code: US01`
    );
  }
}

const getCancel = (url) => {
  const request = axios.get(url).then((res) => res);
  return request;
};

const postUpdate = (params, url) => {
  const request = axios.post(url, params).then((res) => res);
  return request;
};

const getCardInfoRequest = (params, url) => {
  const request = axios.get(url, params).then((res) => res);
  return request;
};
