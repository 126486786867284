import { SETUP_METRO } from './constants';

const initialState = {
  ...window.active_metro,
  settings: {
    ...window.site_settings,
  },
  steps: window.steps,
  partner_steps: window.partner_steps,
  neighborhoods: window.neighborhoods,
  partner: window.partner,
  available_metros: window.available_metros,
};

function metroReducer(state = initialState, action) {
  switch (action.type) {
    case SETUP_METRO: {
      return {
        ...state,
        ...action.active_metro,
        settings: {
          ...action.settings,
        },
        steps: action.steps,
        partner_steps: action.partner_steps,
        neighborhoods: action.neighborhoods,
      };
    }
    default:
      return state;
  }
}

export default metroReducer;
