import {
  CHECK_SESSION,
  CLAIM,
} from './constants';

export const checkSession = (sessionId, page, resolve, reject) => ({
  type: CHECK_SESSION,
  sessionId,
  page,
  resolve,
  reject,
});

export const claim = (customerToken, token, sessionId, source, resolve, reject) => ({
  type: CLAIM,
  customer_token: customerToken,
  perk_token: token,
  cs_token: sessionId,
  source,
  resolve,
  reject,
});
