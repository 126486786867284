import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  startCase,
  isEmpty,
  get,
} from 'lodash';
import {
  selectAllPerkGiveaways,
} from '../selectors';
import { selectCustomerToken } from '../../Profile/selectors';
import {
  ACTIONED_STATUSES,
} from '../constants';
import PerkGiveaway from '../PerkGiveaway';
import Injector from '../../../helpers/Injector';
import Perk from '../../Confirmation/Perk';
import { claim } from '../../Confirmation/actions';

const Title = ({ status, action, perkGiveaway }) => <h2 className="sub-header">{ACTIONED_STATUSES.includes(status) ? startCase(status) : <Injector perkGiveaway={perkGiveaway} body={get(perkGiveaway, `perk.copy_context.templates.${action}.subject`, `${startCase(action)} this perk!`)} />}</h2>;

const Show = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { action } = params;
  const perkGiveaways = useSelector(selectAllPerkGiveaways);
  const customerToken = useSelector(selectCustomerToken);
  const [perkGiveaway, setPerkGiveaway] = useState(null);
  const [messages, setMessages] = useState([]);
  const [offerPerks, setOfferPerks] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [hasClaimed, setHasClaimed] = useState(false);

  const claimPerk = (token) => {
    new Promise((resolve, reject) => {
      dispatch(claim(customerToken, token, null, resolve, reject));
    }).then(() => {
      setSelectedOffer(offerPerks.find(p => p.token === token));
      setHasClaimed(true);
    });
  };

  const showMessages = (data, offers) => {
    const mapping = [
      {
        action: 'claim',
        template_for: 'confirmation',
        medium: 'email',
      },
    ];
    if (offers && !isEmpty(offers)) {
      setOfferPerks(offers);
    } else {
      const options = mapping.filter(m => m.action === action);
      setMessages(options.map(o => data[o.medium].find(m => m.template_for === o.template_for)));
    }
  };

  useEffect(() => setPerkGiveaway(perkGiveaways.find(o => o.token === params.token)), [perkGiveaways]);

  const togglePerk = (p) => {
    if (selectedOffer) {
      setSelectedOffer(null);
    } else {
      setSelectedOffer(p);
    }
  };

  return (
    <div className="grid-x grid-padding-x grid-padding-y perks">
      <div className="small-12 cell float-center ds-container">
        <div className='pg-messages'>
          {!isEmpty(messages) && isEmpty(offerPerks) && messages.map(m => <Injector key={`pg-message-${m.template_for}`} perkGiveaway={perkGiveaway} body={m.body} />)}
        </div>
        {!hasClaimed && perkGiveaway && isEmpty(messages) && isEmpty(offerPerks) && <Title status={perkGiveaway.status} action={action} perkGiveaway={perkGiveaway} />}
        {!hasClaimed && perkGiveaway && isEmpty(messages) && isEmpty(offerPerks) && <PerkGiveaway show buttons actionResolve={showMessages} perkGiveaway={perkGiveaway} action={action} />}
        {!selectedOffer && !isEmpty(offerPerks) && perkGiveaway && perkGiveaway.status === 'released' && <h4>Thanks for letting us know that event didn’t work for you. Here’s a couple other we think you might like:</h4>}
        {!hasClaimed && selectedOffer && offerPerks.filter(p => selectedOffer && selectedOffer.token === p.token).map(perk => <Perk togglePerk={togglePerk} selectedPerk={selectedOffer} perk={perk} key={`${perk.token}-component`} claimPerk={claimPerk} />)}
        {!hasClaimed && !selectedOffer && offerPerks.map(perk => <Perk button togglePerk={togglePerk} selectedPerk={selectedOffer} perk={perk} key={`${perk.token}-component`} claimPerk={claimPerk} />)}
        {hasClaimed && <h3>You have claimed {selectedOffer.event_title}, please check your email!</h3>}
        {!isEmpty(offerPerks) && !selectedOffer && <p>Don't like any of these? No problem, we'll send you an email soon with a great pair of tix</p>}
      </div>
    </div>
  );
};

export default Show;
