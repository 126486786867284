import React from 'react';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';

const Referral = ({ referral: { url, share_url, perk: { name: perkName } } }) => (
  <div className="grid-x grid-margin-x ds-offer" style={{ fontSize: '.8em' }}>
    <div className="grid-x cell card ds-offer-card medium-6 large-8 medium-12 large-12" role="button">
      <div className="cell ds-offer-details medium-12 large-12">
        <p>
            As one of the first to claim <strong>{perkName}</strong>, we have an extra pair of tickets available. Just invite a friend to join DoMORE (first month free) and their first pair of tickets will be to this event so you can go together! Not to mention they’ll now be getting great tickets every month just like you. 
        </p>
        <p>
            Send them this link:
        </p>
        <p data-tip="Copy link">
          <CopyToClipboard
            text={url}
            onCopy={() => toastr.success('Copied', 'Link copied!')}
          >
            <span style={{ color: 'blue' }}>{url}</span>
          </CopyToClipboard>
        </p>
        <p>
            or click below to invite them. But act fast as there’s a limited number available and they’re first come first serve.
        </p>
        <button
          onClick={() => window.open(share_url, '_blank')}
        >
            Invite a Friend
        </button>
      </div>
    </div>
    <ReactTooltip />
  </div>
);

export default Referral;
