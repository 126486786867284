import { useEffect, useContext } from 'react';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { spotifyImport } from '../actions';
import { selectImportedBands, selectCustomerToken } from '../selectors';

const Tracker = ({ cableCarRoute, spotifyData, items, append, setTracking }) => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch();
  const importedBands = useSelector(selectImportedBands);
  const customerToken = useSelector(selectCustomerToken);

  useEffect(() => {
    const options = {
      params: { customer_id: customerToken },
    };

    const customerConnection = cableCarRoute.connect(store, 'CustomerChannel', options);

    dispatch(spotifyImport(spotifyData));

    return () => {
      customerConnection.destroy();
    };
  }, [spotifyData]);

  useEffect(() => {
    const currentItemIds = items.map(i => i.band_id.toString());
    if (importedBands.length > 0) {
      const newBands = importedBands.filter(ib => !currentItemIds.includes(ib.band_id.toString())).map(band => ({
        id: band.id,
        title: band.title,
        band_id: band.band_id.toString(),
        _destroy: false,
      }));
      append(newBands);
      setTimeout(() => setTracking(false), 1000);
    } else {
      setTracking(false);
    }
  }, [importedBands]);

  return null;
};

export default Tracker;
