import { createSelector } from 'reselect';

export const selectConfirmation = createSelector(
  state => state.gift_redeem,
  giftRedeemState => giftRedeemState.confirmation,
);

export const selectErrors = createSelector(
  state => state.gift_redeem,
  giftRedeemState => giftRedeemState.errors,
);

