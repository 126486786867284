import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import { useFieldArray } from 'react-hook-form';
import { spring, TransitionMotion } from 'react-motion';
import { fetchResults, clearSearchResults } from '../../helpers/elasticsearch/actions';
import { selectResults } from '../../helpers/elasticsearch/selectors';

const Search = ({ control, entity, group_name, watch, setValue, register, placeholder, externalAppend, externalItems }) => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const results = useSelector(selectResults);
  let items;

  const entityMapping = ent => {
    const mappings = {
      band: 'band',
      venue: 'venue',
      bar: 'venue',
    };

    return mappings[ent];
  };

  let {
    append,
  } = useFieldArray({
    control,
    name: `${entity}s`,
    keyName: 'fieldId',
  });

  if (!externalItems) {
    items = watch(`${entity}s`);
  } else {
    append = externalAppend;
    items = externalItems;
  }

  const handleRemoveItem = (index, data) => {
    const itemIndex = items.findIndex(item => item[`${entityMapping(entity)}_id`].toString() === data[`${entityMapping(entity)}_id`].toString());
    setValue(`${entity}s[${itemIndex}]._destroy`, true);
  };

  const willLeave = () => ({
    opacity: spring(0),
  });

  const willEnter = () => ({
    opacity: 0,
  });

  const defaultStyles = () => items.map(item => ({ data: { ...item }, key: `${entity}-${item.fieldId}`, style: { opacity: 0 } }));

  const onSearch = ({ value }) => dispatch(fetchResults(value, entity));

  const onChange = (event, { newValue }) => setTerm(newValue);

  const onClear = () => dispatch(clearSearchResults());

  const onBlur = () => {
    dispatch(clearSearchResults());
    setTerm('');
  };
  
  const getSuggestionValue = suggestion => suggestion._source.title;

  const onSuggestionSelected = (event, { suggestion }) => {
    const existingItem = items.findIndex(item => suggestion._id.toString() === item[`${entityMapping(entity)}_id`].toString());
    if (existingItem !== -1) {
      setValue(`${entity}s[${existingItem}]._destroy`, false);
    } else {
      const newItem = {
        title: suggestion._source.title,
        [`${entityMapping(entity)}_id`]: suggestion._id.toString(),
        _destroy: false,
      };
      if (group_name) {
        newItem.group_name = group_name;
      }
      append(newItem);
    }
    setTerm('');
  };

  const renderSuggestion = suggestion => (
    <span className='search-suggestion'>
      {suggestion._source.title}
    </span>
  );

  const suggestionsContainer = ({ containerProps, children }) => (
    <div
      className={classNames('dropdown-pane search-results has-position-bottom has-alignment-left')}
      id={`${entity}-search-dropdown`}
      data-dropdown
      data-close-on-click="true"
      {...containerProps}
    >
      {children}
    </div>
  );

  const renderInputComponent = inputProps => (
    <fieldset className='form-row search-wrapper'>
      <label className='search-field' htmlFor={`${entity}_search`}>
        <span className="fa fa-search" />
        <input
          {...inputProps}
        />
      </label>
    </fieldset>
  );

  const inputProps = {
    placeholder,
    value: term,
    className: 'field',
    onChange,
    onBlur,
  };

  return (
    <>
      <Autosuggest
        suggestions={results}
        onSuggestionsFetchRequested={onSearch}
        onSuggestionsClearRequested={onClear}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={suggestionsContainer}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
      />
      <TransitionMotion
        willLeave={willLeave}
        willEnter={willEnter}
        defaultStyles={defaultStyles()}
        styles={items.filter(item => item._destroy === 'false' && group_name === item.group_name).map(item => ({
          key: `${entity}-${item[`${entityMapping(entity)}_id`]}-${group_name}`,
          style: {
            opacity: spring(1),
          },
          data: { ...item },
        }))}
      >
        {interpolatedStyles => (
          <fieldset className="form-row favorites">
            {interpolatedStyles.map((config, index) => (
              <div
                className={classNames('favorite-wrapper favorite-bar callout removable selected')}
                key={config.key}
                style={{ ...config.style }}
              >
                <label htmlFor={`${entity}-${config.data[`${entityMapping(entity)}_id`]}`}>{config.data.title}</label> 
                <button className="close-button" aria-label="Dismiss alert" type="button">
                  <span
                    aria-hidden="true"
                    onClick={() => handleRemoveItem(index, config.data)}
                  >
                    &times;
                  </span>
                </button>
              </div>
            ))}
          </fieldset>
        )}
      </TransitionMotion>
      {items.map((item, index) => (
        <React.Fragment key={`${entity}s[${item[`${entityMapping(entity)}_id`]}]-${entity}.id-form`}>
          <input
            name={`${entity}s[${index}].${entityMapping(entity)}_id`}
            defaultValue={item[`${entityMapping(entity)}_id`]}
            type='hidden'
            ref={register({ required: true })}
          />
          <input
            name={`${entity}s[${index}]._destroy`}
            type='hidden'
            defaultValue={item._destroy || false}
            ref={register()}
          />
          <input
            name={`${entity}s[${index}].title`}
            type='hidden'
            defaultValue={item.title}
            ref={register()}
          />
          {group_name && (
            <input
              name={`${entity}s[${index}].group_name`}
              type='hidden'
              defaultValue={group_name}
              ref={register()}
            />
          )}
          {item.id && (
            <input
              name={`${entity}s[${index}].id`}
              type='hidden'
              defaultValue={item.id}
              ref={register()}
            />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Search;
