import {
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
} from './constants';

const initialState = {
  results: [],
};

function elasticsearchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        results: action.results,
      };
    }
    case CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        results: [],
      };
    }
    default:
      return state;
  }
}

export default elasticsearchReducer;
