import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import { startCase, lowerCase, get, isEmpty } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import { selectCustomerToken, selectUser } from '../Profile/selectors';
import { selectPendingEvents, selectBundles } from '../PerkGiveaways/selectors';

const Navigation = () => {
  const location = useLocation();
  const [currentNav, setCurrentNav] = useState([]);
  const customerToken = useSelector(selectCustomerToken);
  const user = useSelector(selectUser);
  const pendingEvents = useSelector(selectPendingEvents);
  const bundles = useSelector(selectBundles);

  const navigationItems = [
    'tickets',
    'current offers',
    'last minute tix',
    'profile',
    'account',
    'FAQ',
  ];

  const linkMapping = {
    'last minute tix': 'lmp',
    'current offers': 'offers',
  };

  useEffect(
    () =>
      setCurrentNav(
        navigationItems.map((i) => ({
          name: i,
          is_active: location.pathname.match(i),
        }))
      ),
    [location]
  );

  return (
    <div className='grid-x grid-padding-x grid-padding-y'>
      <div className='small-12 cell float-center ds-container'>
        <ul className='menu simple user-dashboard-navigation'>
          {currentNav.map((navItem) => {
            if (
              navItem.name === 'current offers' &&
              (moment().unix() > moment(bundles[0]?.expires_at).unix() ||
                isEmpty(bundles)) &&
              isEmpty(pendingEvents)
            ) {
              return null;
            }
            return (
              <li
                key={`nav-item-${navItem.name}`}
                className={classNames(
                  `nav-link-${navItem.name}`,
                  navItem.is_active && 'is-active',
                  !user && 'no-user'
                )}
              >
                <NavLink
                  to={`/${get(
                    linkMapping,
                    lowerCase(navItem.name),
                    lowerCase(navItem.name)
                  )}/${customerToken}`}
                >
                  {startCase(navItem.name)}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
