import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { EMAIL_REGEX } from '../Profile/constants';
import { magicLink } from './actions';

const MagicLink = () => {
  const { handleSubmit, errors, register } = useForm();
  const dispatch = useDispatch();
  const [success, setSuccess] = React.useState(false);

  const onSubmit = ({ email }) => {
    new Promise((resolve, reject) =>
      dispatch(magicLink(email, resolve, reject))
    ).then(() => setSuccess(true));
  };
  return (
    <div className="grid-x grid-padding-x grid-padding-y magic-link-container">
      <div className="small-12 cell float-center ds-container">
        {success &&
          <p>
            SUCCESS! Click the 🪄 magic link we just sent to your inbox.
          </p>
        }
        {!success && (
          <>
            <p>
              Enter your email address and we'll send you a magic link to log
              into your DoMORE account.
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="form-magic-link">
              <fieldset className="form-row no-bg user-info">
                <label htmlFor="first_name">
                  <input
                    name="email"
                    ref={register({
                      required: 'Required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'invalid email address',
                      },
                    })}
                    placeholder="Enter your email"
                    className={classNames({
                      'input-field-error': errors && errors.email,
                    })}
                  />
                </label>
                <button
                  type="submit"
                  className="magic-link-button"
                  style={{ fontSize: '20px' }}
                >
                  SEND MY LINK
                </button>
              </fieldset>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default MagicLink;
