import {
  SET_CUSTOMER_AND_PROFILE,
  SAVE_CUSTOMER_PROFILE,
  SAVE_CUSTOMER_PROFILE_LITE,
  SPOTIFY_IMPORT,
  SETUP_CUSTOMER,
  SUBSCRIBE,
  PICK_CITY,
  TRACK_LEAD,
  STORE_LEAD,
  SMS_OPTIN,
  CREATE_BUNDLE,
  SET_SMS_OPTIN,
} from './constants';

export const createBundle = (customer_token, ticket_dispatch_id, partner_name, optin, resolve, reject) => ({
  type: CREATE_BUNDLE,
  customer_token,
  ticket_dispatch_id,
  partner_name,
  optin,
  resolve,
  reject,
});

export const setCustomerAndProfile = (customer, profile) => ({
  type: SET_CUSTOMER_AND_PROFILE,
  customer,
  profile,
});

export const smsOptin = (params, resolve, reject) => ({
  type: SMS_OPTIN,
  params,
  resolve,
  reject,
});

export const setSmsOptin = (sms_optin) => ({
  type: SET_SMS_OPTIN,
  sms_optin,
});

export const saveCustomerProfile = (params, selectedNeighborhoods) => ({
  type: SAVE_CUSTOMER_PROFILE,
  params,
  selectedNeighborhoods,
});

export const saveLiteProfile = (params, customer_token, resolve, reject) => ({
  type: SAVE_CUSTOMER_PROFILE_LITE,
  params,
  customer_token,
  resolve,
  reject,
});

export const setupCustomerProfile = (params, selectedNeighborhoods, plan_name, resolve, reject) => ({
  type: SETUP_CUSTOMER,
  params,
  selectedNeighborhoods,
  id: plan_name,
  resolve,
  reject,
});

export const spotifyImport = (bands) => ({
  type: SPOTIFY_IMPORT,
  bands,
});

export const pickCity = (metro_id, option_name) => ({
  type: PICK_CITY,
  metro_id,
  option_name,
});

export const subscribe = (params, resolve, reject) => ({
  type: SUBSCRIBE,
  params,
  resolve,
  reject,
});

export const trackLead = (params, utm, resolve, reject) => ({
  type: TRACK_LEAD,
  params,
  utm,
  resolve,
  reject,
});

export const storeLead = (params) => ({
  type: STORE_LEAD,
  params,
});
