import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { updatePerkGiveaway } from './actions';
import { selectCurrentPerkGiveaway } from './selectors';
import {
  selectProfileFirstName,
  selectProfileLastName,
} from '../Profile/selectors';
import { selectSiteName } from '../../helpers/metro/selectors'; 

const TransferForm = () => {
  const dispatch = useDispatch();
  const currentPerkGiveaway = useSelector(selectCurrentPerkGiveaway);
  const firstName = useSelector(selectProfileFirstName);
  const lastName = useSelector(selectProfileLastName);
  const siteName = useSelector(selectSiteName);
  const [error, setError] = useState(null);

  const {
    perk: { name },
  } = currentPerkGiveaway;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      transfer_note: `Hey! Your pal, ${firstName} ${lastName}, is a ${siteName} MORE member and they just transferred two free tickets to you for ${name}. Follow the steps below to redeem them and have a blast!`,
    },
  });

  const onSubmit = (data) => {
    new Promise((resolve, reject) =>
      dispatch(
        updatePerkGiveaway({
          status: 'transfer',
          token: currentPerkGiveaway.token,
          ...data,
        }, resolve, reject)
      )
    ).then().catch((e) => {
      setError(e.response.data.message);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <div
          style={{ color: 'red', fontWeight: 'bold', textAlign: 'center', fontSize: '15px' }}
        >
          {error}
        </div>
      )}
      <fieldset className='form-row'>
        <label
          htmlFor='transfer_first_name'
          className={classNames(errors.transfer_first_name && 'has-error')}
        >
          <input
            name='transfer_first_name'
            placeholder='First name'
            className='field'
            ref={register({ required: true })}
          />
        </label>
        {errors.transfer_first_name && (
          <span className='required-field-error'>First name is required!</span>
        )}
      </fieldset>
      <fieldset className='form-row'>
        <label
          htmlFor='transfer_last_name'
          className={classNames(errors.transfer_last_name && 'has-error')}
        >
          <input
            name='transfer_last_name'
            placeholder='Last name'
            className='field'
            ref={register({ required: true })}
          />
        </label>
        {errors.transfer_last_name && (
          <span className='required-field-error'>Last name is required!</span>
        )}
      </fieldset>
      <fieldset className='form-row'>
        <label
          htmlFor='transfer_email'
          className={classNames(errors.transfer_email && 'has-error')}
        >
          <input
            name='transfer_email'
            placeholder='Email'
            className='field'
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
          />
        </label>
        {errors.transfer_email && (
          <span className='required-field-error'>Email is invalid!</span>
        )}
      </fieldset>
      <span>
        <h5>Customize your message below:</h5>
      </span>
      <fieldset className='form-row'>
        <label
          htmlFor='transfer_note'
          className={classNames(errors.transfer_note && 'has-error')}
        >
          <textarea
            style={{
              width: '100%',
              minHeight: '120px',
              border: '1px solid #c1bebe',
              padding: '12px 16px',
              fontSize: '16px',
            }}
            name='transfer_note'
            placeholder='Note'
            ref={register({ required: true })}
          />
        </label>
        {errors.transfer_note && (
          <span className='required-field-error'>Note is required!</span>
        )}
      </fieldset>
      <button>Transfer</button>
    </form>
  );
};

export default TransferForm;
