import React from 'react';
import { isFunction, isObject, map, get } from 'lodash';
import {
  FaSpotify,
  FaYoutube,
  FaTumblr,
  FaInstagram,
  FaGooglePlay,
  FaFacebook,
  FaLink,
  FaItunes,
  FaLastfm,
} from 'react-icons/fa';
import Truncate from '../Confirmation/Truncate';

const SocialLinks = ({ social_links }) => {
  const mapping = {
    lastfm: FaLastfm,
    facebook: FaFacebook,
    twitter: {
      type: 'custom',
      icon: (value, key) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          key={`social-link-${key}`}
          height="1em"
          viewBox="0 0 512 512"
          title={key}
          className="social-link"
          style={{ marginLeft: '10px' }}
          onClick={() => {
            window.open(value.url, '_blank').focus();
          }}
        >
          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
        </svg>
      ),
    },
    youtube: FaYoutube,
    instagram: FaInstagram,
    spotify: FaSpotify,
    tumblr: FaTumblr,
    google_play: FaGooglePlay,
    itunes: FaItunes,
    home: FaLink,
  };
  return map(social_links, (value, key) => {
    const icon = mapping[key];
    if (isObject(icon) && isFunction(icon.icon)) {
      return icon.icon(value, key);
    }
    const Icon = mapping[key];
    return (
      <Icon
        className="social-link"
        style={{ marginLeft: '10px' }}
        key={`social-link-${key}`}
        title={key}
        onClick={() => {
          window.open(value.url, '_blank').focus();
        }}
      />
    );
  });
};

const Artists = ({
  artists,
  isBundle,
  showDetails,
  perkGiveaway,
  isPicker,
}) => (
  <>
    {showDetails && isBundle && (
      <div
        style={{ margin: '15px 0px 0px 0px' }}
        className="ds-detail-description"
      >
        <Truncate
          value={get(perkGiveaway, 'perk.event.description', '')}
          num={150}
        />
      </div>
    )}
    <div className="artists">
      {artists.length > 0 &&
          ((!isBundle && ['claimed', 'sent'].includes(perkGiveaway?.status)) ||
            isPicker) && (
        <h5 style={{ margin: '15px 0px 0px 0px' }}>Artists:</h5>
      )}
      {artists.length > 0 && isBundle && (
        <h5 style={{ margin: '15px 0px 0px 0px' }}>Artists:</h5>
      )}
      {artists.map((artist) => (
        <div className="artist" key={`artist-${artist.title}`}>
          <div className="artist_title">
            {artist.title} <SocialLinks social_links={artist.social_links} />
          </div>
        </div>
      ))}
    </div>
  </>
);

export default Artists;
