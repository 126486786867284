import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  selectMetroId,
  selectMetroSettings,
} from '../../helpers/metro/selectors';
import Navigation from './Navigation';
import SubscriptionStatus from './SubscriptionStatus';

const logos = {
  47: 'https://assets0.dostuffmedia.com/property_asset/75065/DoXYZMORE-email_Do502More-Horizontal-Black.png',
  2: 'https://assets0.dostuffmedia.com/property_asset/75060/DoXYZMORE-email_Do210More-Horizontal-Black.png',
  3: 'https://assets0.dostuffmedia.com/property_asset/75063/DoXYZMORE-email_Do312More-Horizontal-Black.png',
  31: 'https://assets0.dostuffmedia.com/property_asset/75071/DoXYZMORE-email_DoSDMore-Horizontal-Black.png',
  25: 'https://assets0.dostuffmedia.com/property_asset/75067/DoXYZMORE-email_Do617More-Horizontal-Black.png',
  9: 'https://assets0.dostuffmedia.com/property_asset/75059/DoXYZMORE-email_Do206More-Horizontal-Black.png',
  18: 'https://assets0.dostuffmedia.com/property_asset/75245/DTBMore-email_DTBMore-Horizontal-Black.png',
  30: 'https://assets0.dostuffmedia.com/property_asset/75068/DoXYZMORE-email_DoLAMore-Horizontal-Black.png',
  65: 'https://assets0.dostuffmedia.com/property_asset/78125/Do215-MORE-white-stroke__1_.png',
  34: 'https://assets0.dostuffmedia.com/property_asset/75062/DoXYZMORE-email_Do303More-Horizontal-Black.png',
  64: 'https://assets0.dostuffmedia.com/property_asset/77530/Do713-MORE-Logo-Blackwhite.png',
  10: 'https://assets0.dostuffmedia.com/property_asset/75069/DoXYZMORE-email_DoNYCMore-Horizontal-Black.png',
  42: 'https://assets0.dostuffmedia.com/property_asset/75064/DoXYZMORE-email_Do317More-Horizontal-Black.png',
  24: 'https://assets0.dostuffmedia.com/property_asset/75066/DoXYZMORE-email_Do615More-Horizontal-Black.png',
  60: 'https://assets0.dostuffmedia.com/property_asset/72195/DoMORE_Austin_DarkText.png',
  1: 'https://assets0.dostuffmedia.com/property_asset/72195/DoMORE_Austin_DarkText.png',
  11: 'https://assets0.dostuffmedia.com/property_asset/75072/DoXYZMORE-email_Do214More-Horizontal-Black.png',
  19: 'https://assets0.dostuffmedia.com/property_asset/75070/DoXYZMORE-email_DoPDXMore-Horizontal-Black.png',
  32: 'https://assets0.dostuffmedia.com/property_asset/78452/Do604More-Horizontal-Black.png',
};

const Header = ({ noNav, doMoreLogo }) => {
  const metroSettings = useSelector(selectMetroSettings);
  const metroId = useSelector(selectMetroId);
  const { city_name } = metroSettings;
  const more_logo_url = get(logos, metroId, 'https://dvr1tqe2n5gnv.cloudfront.net/DoMORE_DarkText.png');

  return (
    <>
      <div className="grid-x grid-padding-x grid-padding-y">
        <div className="small-12 cell float-center ds-container">
          <img
            src={
              doMoreLogo
                ? 'https://dvr1tqe2n5gnv.cloudfront.net/DoMORE_DarkText.png'
                : more_logo_url
            }
            alt={`${noNav ? 'DoMORE Logo' : `DoMORE ${city_name}`}`}
            className="ds-more-logo"
          />
        </div>
      </div>
      {!noNav && <Navigation />}
      {!noNav && <SubscriptionStatus />}
    </>
  );
};

export default Header;
