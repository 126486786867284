import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectCustomer } from './selectors';
import { selectMetroSettings } from '../../helpers/metro/selectors';

const ShortToken = () => {
  const customer = useSelector(selectCustomer);
  const metroSettings = useSelector(selectMetroSettings);
  const { city_name, more_logo_url } = metroSettings;

  return (
    <>
      <div className="grid-x grid-padding-x grid-padding-y">
        <div className="small-12 cell float-center ds-container">
          <img
            src={more_logo_url}
            alt={`DoMORE ${isEmpty(city_name) ? 'Stuff' : city_name}`}
            className="ds-more-logo"
          />
        </div>
      </div>
      <div className="grid-x grid-padding-x grid-padding-y profile-container">
        <div className="small-12 cell float-center ds-container">
          <h1 className="profile-title" style={{ fontSize: '64px' }}>
            You got tickets!
          </h1>
        </div>
        <div className="small-12 cell float-center ds-container">
          Hey, {customer.first_name}! You have tickets to claim!
        </div>
      </div>
    </>
  );
};

export default ShortToken;
