import { createSelector } from 'reselect';
import { get } from 'lodash';
import { DAYS_OF_WEEK } from './constants';
import { selectExperiences, selectGenres } from '../../helpers/metro/selectors';

export const selectCustomer = createSelector(
  state => state.profile,
  profileStore => profileStore.customer,
);

export const selectPerkReferral = createSelector(
  state => state.profile,
  profileStore => profileStore.perk_referral,
);

export const selectCustomerToken = createSelector(
  selectCustomer,
  customer => customer.token
);

export const selectCustomerPlanOption = createSelector(
  selectCustomer,
  customer => customer.plan_option
);

export const selectLMPLastVisited = createSelector(
  selectCustomer,
  customer => customer.lmp_last_visited
);

export const selectCustomerEmail = createSelector(
  selectCustomer,
  customer => customer.email
);

export const selectCustomerFirstName = createSelector(
  selectCustomer,
  customer => customer.first_name
);

export const selectCustomerName = createSelector(
  selectCustomer,
  customer => [customer.first_name, customer.last_name].join(' ')
);

export const selectLastMinuteAllowed = createSelector(
  selectCustomer,
  customer => customer.last_minute_allowed
);

export const selectLastMinuteClaimedAt = createSelector(
  selectCustomer,
  customer => customer.last_minute_claimed_at
);

export const selectCustomerLastName = createSelector(
  selectCustomer,
  customer => customer.last_name
);

export const selectProfile = createSelector(
  state => state.profile,
  profileStore => profileStore.profile,
);

export const selectDateOfBirth = createSelector(
  selectProfile,
  profile => profile.date_of_birth,
);

export const selectDrinkPreference = createSelector(
  selectProfile,
  profile => profile.drink,
);

export const selectUser = createSelector(
  selectCustomer,
  customer => customer.user,
);

export const selectNeighborhoods = createSelector(
  selectProfile,
  profile => profile.neighborhoods
);

export const selectLeadToken = createSelector(
  state => state.profile,
  profile => get(profile, 'lead.token', null)
);

export const selectLeadPhoneNumber = createSelector(
  state => state.profile,
  profile => get(profile, 'lead.phone_number', null)
);

export const selectLead = createSelector(
  state => state.profile,
  profile => get(profile, 'lead', null)
);

export const selectLeadEmail = createSelector(
  selectLead,
  lead => lead && lead.email
);

export const selectGenreList = createSelector(
  selectProfile,
  profile => profile.genre_list
);

export const selectUserExperiences = createSelector(
  selectProfile,
  profile => profile.experiences
);

export const selectUserBands = createSelector(
  selectProfile,
  profile => profile.more_preference_bands.filter(b => b.band).map(b => b.band)
);

export const selectIsNew = createSelector(
  state => state.profile,
  profile => profile.isNew
);

export const selectImportedBands = createSelector(
  state => state.spotify,
  spotify => spotify.imported_bands
);

export const selectUserEmail = createSelector(
  selectUser,
  user => user && user.email
);

export const selectCustomerStatus = createSelector(
  selectCustomer,
  customer => customer.status
);

export const selectSubscriptions = createSelector(
  selectCustomer,
  customer => customer.member_subscriptions
);

export const selectProfileFirstName = createSelector(
  selectProfile,
  profile => profile.first_name
);

export const selectProfileLastName = createSelector(
  selectProfile,
  profile => profile.last_name
);

export const selectProfileFullName = createSelector(
  selectProfileFirstName,
  selectProfileLastName,
  (first_name, last_name) => [first_name, last_name].join(' ')
);

export const selectProfilePhoneNumber = createSelector(
  selectProfile,
  profile => profile.phone_number
);

export const selectIsFreeTrial = createSelector(
  state => state.profile,
  profile => profile.option_free_trial
);

export const selectOption = createSelector(
  state => state.profile,
  profile => profile.option
);

export const selectOptin = createSelector(
  state => state.profile,
  profile => profile.sms_optin
);

export const selectInitialProfileForm = createSelector(
  [selectProfile, selectIsNew, selectExperiences, selectGenres],
  (profile, isNew, experiences, genres) => ({
    first_name: profile.first_name,
    last_name: profile.last_name,
    email: profile.email,
    date_of_birth: profile.date_of_birth,
    lived: profile.lived,
    children: profile.children ? 'true' : 'false',
    phone_number: profile.phone_number,
    occupation: profile.occupation,
    neighborhoods: profile.neighborhoods.length > 0 ? profile.neighborhoods.map(n => ({ name: n })) : [],
    experiences: isNew && profile.experiences.length === 0 ? experiences.map(experience => ({ name: experience.trim() })) : profile.experiences.map(experience => ({ name: experience.trim() })),
    days: profile.days && profile.days.length > 0 ? profile.days.map(f => ({ name: f })) : DAYS_OF_WEEK.map(f => ({ name: f })),
    genres: isNew && profile.my_genres.length === 0 ? genres.map(genre => ({ name: genre.trim() })) : profile.my_genres.map(genre => ({ name: genre.trim() })),
    food: profile.food.map(f => ({ name: f })),
    bands: profile.more_preference_bands.filter(obj => obj.band !== undefined).map(obj => ({ id: obj.id, band_id: obj.band.id, title: obj.band.title, _destroy: false })),
    bars: profile.bars.length > 0 ? profile.bars : [],
    venues: profile.venues.length > 0 ? profile.venues : [],
    drink: profile.drink ? 'true' : 'false',
    referrer: profile.referrer,
    signup_reason: profile.signup_reason,
    improve_survey: profile.improve_survey,
  })
);
