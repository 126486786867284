import { takeEvery, call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import {
  UPDATE_PERK_GIVEAWAY,
  SET_ALL_PERK_GIVEAWAYS,
  TOGGLE_MODAL,
  SET_CURRENT_PERK_GIVEAWAY,
  DISPLAY_TRANSFER_MODAL,
  ACTIVATE_BUNDLE,
  REQUEST_TICKETS,
} from './constants';
import { selectCustomerToken, selectLeadToken } from '../Profile/selectors';
import { selectSupportEmail } from '../../helpers/metro/selectors';

export default function* watcherSaga() {
  yield takeEvery(UPDATE_PERK_GIVEAWAY, updatePerkGiveaway);
  yield takeEvery(DISPLAY_TRANSFER_MODAL, displayTransferModal);
  yield takeEvery(ACTIVATE_BUNDLE, activateBundle);
  yield takeEvery(REQUEST_TICKETS, requestTickets);
}

function* requestTickets({ token, params, resolve, reject }) {
  try {
    const payload = yield call(requestTicketsRequest, token, params);
    yield put({
      type: SET_ALL_PERK_GIVEAWAYS,
      perk_giveaways: payload.data.perk_giveaways,
      bundles: payload.data.bundles,
    });
    if (resolve) {
      resolve();
    }
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    if (reject) {
      reject(e);
    } else {
      toastr.error(
        'Error',
        `Oops. Something went wrong trying to activate your bundle! Please contact ${supportEmail} if this error persists.`
      );
    }
  }
}

function* activateBundle({ token, resolve, reject }) {
  try {
    const payload = yield call(activateBundleRequest, token);
    if (resolve) {
      resolve();
    }
    yield put({
      type: SET_ALL_PERK_GIVEAWAYS,
      perk_giveaways: payload.data.perk_giveaways,
      bundles: payload.data.bundles,
    });
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    if (reject) {
      reject(e);
    } else {
      toastr.error(
        'Error',
        `Oops. Something went wrong trying to activate your bundle! Please contact ${supportEmail} if this error persists.`
      );
    }
  }
}

function* updatePerkGiveaway({ params, resolve, reject }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const leadToken = yield select(selectLeadToken);
    const payload = yield call(postUpdate, params, customerToken || leadToken);
    if (params.status === 'transfer') {
      yield put({ type: TOGGLE_MODAL });
    }
    yield put({
      type: SET_ALL_PERK_GIVEAWAYS,
      perk_giveaways: payload.data.upcoming,
      bundles: payload.data.bundles,
    });
    if (resolve) {
      resolve(payload.data);
    }
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    if (reject) {
      reject(e);
    } else {
      toastr.error(
        'Error',
        `Oops. Something went wrong trying to ${params.status}! Please contact ${supportEmail} if this error persists.`
      );
    }
  }
}

function* displayTransferModal({ token }) {
  yield put({ type: SET_CURRENT_PERK_GIVEAWAY, token });
  yield put({ type: TOGGLE_MODAL });
}

const activateBundleRequest = (token) => {
  const activateBundleUrl = `/bundle/${token}`;
  const request = axios.get(activateBundleUrl).then((res) => res);
  return request;
};

const requestTicketsRequest = (token, params) => {
  const ticketRequestUrl = `/bundle/${token}`;
  const request = axios
    .post(ticketRequestUrl, {
      ticket_request: {
        ...params,
      },
    })
    .then((res) => res);
  return request;
};

const postUpdate = (params, customerToken) => {
  const {
    status,
    token,
    transfer_first_name,
    transfer_last_name,
    transfer_email,
    transfer_note,
  } = params;
  const updatePerkGiveawayUrl = `/upg/${token}`;
  const request = axios
    .post(updatePerkGiveawayUrl, {
      customer_token: customerToken,
      api_perk_giveaway: {
        status,
        transfer_first_name,
        transfer_last_name,
        transfer_email,
        transfer_note,
      },
    })
    .then((res) => res);
  return request;
};
