import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createCableCarRoute } from 'redux-cablecar';
import { get } from 'lodash';
import sagas from './rootSaga';
import reducers from './reducers';

const setupStore = (appName) => {
  const initialiseSagaMiddleware = createSagaMiddleware();
  const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  if (appName === 'gift-redeem') {
    const store = createStore(
      reducers,
      storeEnhancers(
        applyMiddleware(initialiseSagaMiddleware)
      )
    );
    initialiseSagaMiddleware.run(sagas);

    return { store };
  }

  const cableCarRoute = createCableCarRoute({
    webSocketURL: `${window.ws_host}/cable?customer_id=${get(window.member_data, 'customer.token', '')}`,
  });

  const cableCarMiddleware = cableCarRoute.createMiddleware();

  const store = createStore(
    reducers,
    storeEnhancers(
      applyMiddleware(initialiseSagaMiddleware),
      applyMiddleware(cableCarMiddleware)
    )
  );
  initialiseSagaMiddleware.run(sagas);

  return { store, cableCarRoute };
};

export default setupStore;
