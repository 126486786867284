import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { useForm } from 'react-hook-form';
import { Input } from '../../../helpers/form';
import { selectSiteName, selectCityName } from '../../../helpers/metro/selectors';
import { userRegister } from '../actions';

const Register = ({ setDisplay, switchUser, onClose }) => {
  const dispatch = useDispatch();
  const siteName = useSelector(selectSiteName);
  const cityName = useSelector(selectCityName);
  const { customerToken } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = params => (
    new Promise((resolve, reject) => (
      dispatch(userRegister(params, customerToken, resolve, reject))
    )).then(() => switchUser && onClose(false))
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='form-row' style={{ marginBottom: 0, marginTop: '10px' }}>
        <Input
          name='first_name'
          register={register}
          label='First Name'
          errorMessage='First Name is required!'
          errors={errors}
        />
        <Input
          name='last_name'
          register={register}
          label='Last Name'
          errorMessage='Last name is required!'
          errors={errors}
        />
        <Input
          name='email'
          register={register}
          label='Email'
          errorMessage='Email is required!'
          errors={errors}
        />
        <Input
          name='password'
          register={register}
          label='Password'
          errorMessage='password is required!'
          errors={errors}
          password
        />
        {switchUser &&
          <input
            name='switch_user'
            register={register}
            type='hidden'
          />
        }
        <div className='checkboxes' style={{ marginLeft: '15px' }}>
          <input
            name="email_list_opt_in"
            id="mail_list"
            type="checkbox"
            ref={register}
          />
          <label htmlFor="mail_list">Send me emails featuring the best events in {cityName}.</label>
        </div>
      </fieldset>
      <button type='submit' style={{ marginTop: '10px' }}>
        Register
      </button>
      <p style={{ textAlign: 'center', marginTop: '20px' }}>Are you already a {siteName} user? &nbsp;
        <a onClick={() => setDisplay('login')} role='button' className='auth-switch'>
          Login to your account
        </a>.
      </p>
    </form>
  );
};

export default Register;
