import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import moment from 'moment/moment';
import PuffLoader from 'react-spinners/PuffLoader';

const UpdateCard = ({
  callback,
  updating,
  setUpdating,
  cardUpdated,
  stripeError,
  cardInfo,
  setUpdateCard,
}) => (
  <div className="cell small-12 large-12 billing-info">
    <div className="card">
      <div
        style={{
          background: '#E5E5E5',
          color: 'black',
          padding: '0px 10px 0px 10px',
        }}
      >
        <h4>LOCK IN $5/MONTH FOR ONE YEAR</h4>
        <div>
          <p>Enjoy nearly 30% off your next 12 months. Cancel anytime.</p>
        </div>
      </div>
      <div className="card-section">
        {updating && (
          <p style={{ textAlign: 'center' }}>
            {updating &&
              !cardUpdated &&
              'Updating your subscription!  May take a minute.'}
            {updating && cardUpdated && 'Subscription updated!'}
            <br />
            <PuffLoader loading={updating} size={25} />
          </p>
        )}
        {!updating && (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                <h4
                  className="required has-helper-text"
                  style={{ fontSize: '28px' }}
                >
                  12 Month plan
                </h4>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <h4
                  className="required has-helper-text"
                  style={{ fontSize: '28px' }}
                >
                  $60
                </h4>
              </div>
            </div>
            <p>
              Ends{' '}
              {moment
                .unix(cardInfo.next_billing_date)
                .add(1, 'years')
                .format('MMM Do, YYYY')}
              . $7/mo thereafter.
            </p>
          </>
        )}
        <div style={updating ? { display: 'none' } : {}}>
          <form>
            <section style={{ marginBottom: 0 }}>
              <div className="payment-info stepper-payment-info">
                {stripeError && (
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '.8em',
                      color: 'red',
                      marginBottom: '10px',
                    }}
                  >
                    {stripeError}
                  </div>
                )}
                <fieldset>
                  <label htmlFor="card-element">
                    <CardElement id="card-element" className="field" />
                  </label>
                </fieldset>
                <button
                  disabled={updating}
                  type="button"
                  onClick={() => {
                    setUpdating(true);
                    callback();
                  }}
                >
                  Charge $60
                </button>
                <div style={{ display: 'flex' }}>
                  <a
                    style={{
                      margin: 'auto',
                      marginTop: '15px',
                      textDecoration: 'underline',
                      color: 'black',
                    }}
                    role="button"
                    onClick={() => setUpdateCard(false)}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </div>
);

export default UpdateCard;
