import React from 'react';

const Misc = ({ cityName, register }) => (
  <section>
    <h2 className="sub-header">More About You</h2>
    <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>How'd you hear about DoMORE {cityName}?</h4>
    <fieldset className="form-row textbox">
      <label htmlFor='referrer'>
        <input
          id="referrer"
          name="referrer"
          type="text"
          ref={register}
        />
      </label>
    </fieldset>
    <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>What made you decide to subscribe to DoMORE {cityName}?</h4>
    <fieldset className="form-row textbox">
      <label htmlFor='signup_reason'>
        <input
          id="signup_reason"
          name="signup_reason"
          type="text"
          ref={register}
        />
      </label>
    </fieldset>
    <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>How can we improve this survey?</h4>
    <fieldset className="form-row textbox">
      <label htmlFor='improve_survey'>
        <input
          id="improve_survey"
          name="improve_survey"
          type="text"
          ref={register}
        />
      </label>
    </fieldset>
  </section>
);

export default Misc;
