import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import TransferForm from './TransferForm';
import { toggleModal } from './actions';

const TransferModal = ({ isModalOpen }) => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(toggleModal());

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Example Modal'
      className='members-modal'
    >
      <div className='auth-modal'>
        <div className='grid-x grid-padding-x grid-padding-y'>
          <div className='small-12 medium-7 cell ds-auth-modal float-center'>
            <div className='confirmation-body has-close-button'>
              <span
                role='button'
                className='close-button'
                onClick={() => closeModal()}
              >
                x
              </span>
              <h3 className='float-center' style={{ textAlign: 'center' }}>
                Transfer your perk to a friend!
              </h3>
              <p style={{ textAlign: 'center', fontSize: '.9em' }}>
                Fill in the info below to transfer your tickets and customize
                the message.
                <br />
                FYI tickets can only be gifted to others{' '}
                <strong>and never resold.</strong>
                <br />
                Violation of these terms will result in termination of your
                membership.
              </p>
              <TransferForm />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransferModal;
