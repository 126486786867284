import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import PerkGiveaway from './PerkGiveaway';
import Referral from './Referral';
import { claim } from '../Confirmation/actions';
import { selectPendingEvents, selectBundles } from './selectors';
import { selectCustomerToken } from '../Profile/selectors';
import { selectReleaseOffers } from '../../helpers/metro/selectors';
import Bundle from './Bundle';

const CurrentOffers = () => {
  const pendingEvents = useSelector(selectPendingEvents);
  const customerToken = useSelector(selectCustomerToken);
  const releaseOffers = useSelector(selectReleaseOffers);
  const bundles = useSelector(selectBundles);
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [hasOffers, setHasOffers] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [referral, setReferral] = useState({});
  const [bundleExpired, setBundleExpired] = useState([]);
  const history = useHistory();
  const bundleActive = moment(bundles[0]?.expires_at).isBefore(moment());

  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'Tickets',
    });
  }, []);

  const claimPerk = (token) => {
    new Promise((resolve, reject) => {
      dispatch(claim(customerToken, token, null, 'release', resolve, reject));
    }).then(() => {
      setSelectedOffer(offers.find((p) => p.token === token));
      setTimeout(() => window.location.reload(), 5000);
    });
  };

  const updateOffersOrReferral = (t, off, ref, actionName) => {
    if (actionName === 'release') {
      if (releaseOffers) {
        setOffers(off);
        setHasOffers(!isEmpty(off));
      }
    }
    if (!isEmpty(ref)) {
      setReferral(ref);
    }
  };

  const releaseFlow = () => {
    if (!isEmpty(selectedOffer)) {
      toastr.success(
        'Success!',
        `You have claimed ${selectedOffer.event.title}! We'll send you the details shortly.`
      );
      history.push(`/tickets/${customerToken}`);
    }
  };

  return (
    <div className='grid-x grid-padding-x grid-padding-y perks'>
      <div className='small-12 cell float-center ds-container'>
        {bundles[0]?.expires_at !== null && pendingEvents.length === 0 && bundleActive && (
          <p>
            Nothing new at this time. We’ll be in touch soon with more tickets!
            If you need help{' '}
            <a
              href='mailto:domore@dostuffmedia.com'
              target='_blank'
              rel='noreferrer'
            >
              email
            </a>{' '}
            support.
          </p>
        )}
        {!isEmpty(referral) && <Referral referral={referral} />}
        <div className='perk-selector'>
          {isEmpty(selectedOffer) && !isEmpty(offers) && (
            <h4>Thanks for releasing. Here's a couple more you might like:</h4>
          )}
          {isEmpty(selectedOffer) &&
            !isEmpty(offers) &&
            offers.map((perk) => (
              <PerkGiveaway
                claimPerk={claimPerk}
                lastMinuteAllowed
                wideButton
                key={`perk-${perk.token}`}
                perk={perk}
                screen='release'
              />
            ))}
          {!hasOffers && (
            <p>
              Uh oh. Looks like we ran out of tickets that match your profile.
              Never fear though - we'll soon be emailing you another pair. Or,
              you can email us at{' '}
              <a
                href='mailto:domore@dostuffmedia.com'
                target='_blank'
                rel='noreferrer'
              >
                domore@dostuffmedia.com
              </a>{' '}
              and we'll try and find you something sooner!
            </p>
          )}
        </div>
        {!isEmpty(selectedOffer) && releaseFlow()}
        {isEmpty(selectedOffer) &&
          isEmpty(offers) &&
          bundles.length > 0 &&
          bundles
            .filter(
              (p) =>
                p.expires_at === null || moment(p.expires_at).isAfter(moment())
            )
            .map((bundle) => (
              <Bundle
                key={`bundle-${bundle.token}`}
                bundle={bundle}
                setBundleExpired={setBundleExpired}
                bundleExpired={bundleExpired}
                updateOffersOrReferral={updateOffersOrReferral}
                claimPerk={claimPerk}
              />
            ))}
        {isEmpty(selectedOffer) && isEmpty(offers) && (
          <>
            {pendingEvents.length > 0 && (
              <>
                <h2 className='sub-header'>Pending Offers</h2>
                <p>
                  <b>Action Required</b> - "Claim" your pair of tickets if you want to go. You can also transfer to a friend after claiming if you can't make it.
                </p>
              </>
            )}
            {pendingEvents.length > 0 &&
              pendingEvents.map((perkGiveaway) => (
                <PerkGiveaway
                  actionResolve={updateOffersOrReferral}
                  key={`pending-perk-giveway-${perkGiveaway.token}`}
                  perkGiveaway={perkGiveaway}
                  lastMinuteAllowed
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CurrentOffers;
