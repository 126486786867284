import React, { useState } from 'react';
import SpotifyLogin from 'react-spotify-login';
import Tracker from './Tracker';

const Spotify = ({ cableCarRoute, append, items }) => {
  const secret = window.spotify_secret;
  const redirectUri = 'https://domorestuff.com/spotify_callback';
  const [tracking, setTracking] = useState(false);
  const [spotifyData, setSpotifyData] = useState(null);

  const onSuccess = (res) => {
    fetch('https://api.spotify.com/v1/me/top/artists?limit=10', {
      headers: {
        Authorization: `Bearer ${res.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSpotifyData(data);
        setTracking(true);
        window.location.reload(false);
      });
  };

  return (
    <>
      <SpotifyLogin
        clientId={secret}
        redirectUri={redirectUri}
        onSuccess={onSuccess}
        className='spotify-sync-button'
        buttonText='Connect your Spotify'
        type='button'
        scope='user-library-read user-read-email user-top-read'
      />
      {tracking && (
        <Tracker
          append={append}
          items={items}
          spotifyData={spotifyData}
          cableCarRoute={cableCarRoute}
          setTracking={setTracking}
        />
      )}
    </>
  );
};

export default Spotify;
