import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import UpdateCard from './UpdateCard';
import {
  selectCustomerStatus,
  selectCustomerToken,
} from '../Profile/selectors';
import { selectSupportEmail } from '../../helpers/metro/selectors';
import { getCardInfo, annualUpgrade } from './actions';
import ExistingCard from './ExistingCard';

const Annual = () => {
  const customerStatus = useSelector(selectCustomerStatus);
  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'Annual Offer',
    });
  }, []);

  const supportEmail = useSelector(selectSupportEmail);
  const customerToken = useSelector(selectCustomerToken);
  const [stripeError, setStripeError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cardUpdated, setCardUpdated] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [updateCard, setUpdateCard] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const requestCi = () =>
    new Promise((resolve, reject) =>
      dispatch(getCardInfo(resolve, reject))
    ).then(({ data }) => {
      setLoading(false);
      setCardInfo(data);
    });

  useEffect(() => requestCi(), []);

  const stripeRequest = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      if (error.code === 'incomplete_zip') {
        setStripeError('Zipcode is required!');
      } else {
        setStripeError(
          `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
        );
      }
    } else {
      setUpdating(true);
      handleUpdate(token.id, cardElement);
    }
  };

  const handleUpdate = (token, cardElement) =>
    new Promise((resolve, reject) =>
      dispatch(annualUpgrade(resolve, reject, token))
    )
      .then(() => {
        if (cardElement) {
          cardElement.clear();
        }
        setCardUpdated(true);
        setTimeout(() => {
          location.href = `/offers/${customerToken}`;
        }, 4000);
      })
      .catch(() => {
        setUpdating(false);
        setStripeError(
          `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
        );
      });

  if (window.annual) {
    location.href = `/profile/${customerToken}`;
  }

  return (
    <div className="grid-x grid-padding-x grid-padding-y account">
      <div className="small-12 cell float-center ds-container">
        <div className="grid-x grid-margin-x grid-padding-y">
          {!loading &&
            updateCard &&
            ['active', 'trialing', 'past_due', 'unpaid'].includes(customerStatus) && (
            <UpdateCard
              callback={stripeRequest}
              cardUpdated={cardUpdated}
              stripeError={stripeError}
              updating={updating}
              cardInfo={cardInfo}
              setUpdating={setUpdating}
              setUpdateCard={setUpdateCard}
            />
          )}
          {!loading && !updateCard && cardInfo &&
            ['active', 'trialing', 'past_due', 'unpaid'].includes(customerStatus) && (
            <ExistingCard
              cardInfo={cardInfo}
              updating={updating}
              setUpdating={setUpdating}
              cardUpdated={cardUpdated}
              setCardInfo={setCardInfo}
              handleUpdate={handleUpdate}
              setUpdateCard={setUpdateCard}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Annual;
