import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { selectProfilePhoneNumber } from './selectors';
import { PHONE_NUMBER_REGEX } from './constants';
import { smsOptin } from './actions';

const SmsOptin = () => {
  const phoneNumber = useSelector(selectProfilePhoneNumber);
  const [optinComplete, setOptinComplete] = useState(false);
  const dispatch = useDispatch();
  const { control, errors, handleSubmit } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      phone_number: phoneNumber,
    },
  });

  const onSubmit = (data) =>
    new Promise((resolve, reject) => dispatch(smsOptin(data, resolve, reject)))
      .then(() => {
        setOptinComplete(true);
      })
      .catch(
        ({
          response: {
            data: { error },
          },
        }) => {
          toastr.error('Error', error, { timeOut: 5000 });
        }
      );

  const checkboxStyles = {
    width: '20px',
    height: '20px',
    margin: 0,
    marginRight: '5px',
  };

  return (
    <>
      <div className="grid-x grid-padding-x grid-padding-y profile-container">
        <div className="small-12 cell float-center ds-container">
          <h1 className="profile-title" style={{ fontSize: '64px' }}>
            Sign Up for Text Alerts
          </h1>
        </div>
      </div>
      <div className="grid-x grid-padding-x grid-padding-y profile-container">
        <div className="small-12 cell float-center ds-container">
          {optinComplete && (
            <>
              <h2 className="profile-title" style={{ fontSize: '32px' }}>
                Text incoming! Check your messages to confirm.
              </h2>
              <p>It may take a minute or two for it to arrive.</p>
            </>
          )}
          {!optinComplete && (
            <form className="form-profile">
              <div className="form-row">
                <span>Phone Number</span>
                <br />
                <label htmlFor="phone_number">
                  <Controller
                    as={InputMask}
                    control={control}
                    mask="999-999-9999"
                    name="phone_number"
                    placeholder="312-555-4242"
                    maskPlaceholder={null}
                    style={
                      errors.phone_number
                        ? {
                          border: '1px solid red',
                          backgroundColor: '#fdf3f3',
                          color: '#ff2c2c',
                        }
                        : {}
                    }
                    type="tel"
                    rules={{
                      required: 'Phone number is required',
                      validate: (value) => {
                        if (value.match(PHONE_NUMBER_REGEX)) {
                          return;
                        }
                        return 'Invalid phone number';
                      },
                    }}
                  />
                </label>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Controller
                  control={control}
                  name="optin"
                  defaultValue
                  render={({ onChange, value }) => (
                    <input
                      style={checkboxStyles}
                      type="checkbox"
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      value={value}
                      id="optin"
                    />
                  )}
                  rules={{
                    required: 'You must opt in to receive text alerts',
                    validate: (value) => {
                      if (value) {
                        return;
                      }
                      return 'You must opt in to receive text alerts';
                    },
                  }}
                />
                <label
                  htmlFor="optin"
                  style={
                    errors.optin
                      ? { color: 'red', fontSize: '1em' }
                      : { fontSize: '1em' }
                  }
                >
                  {' '}
                  <span>YES, send my tickets via text</span>
                </label>
              </div>
              <div style={{ marginTop: '10px', fontSize: '16px' }}>
                <div>
                  <span>
                    Expect up to 6 texts/ month. Message and data rates may
                    apply. Text STOP to cancel. HELP for help. See our{' '}
                    <a
                      href="https://dostuffmedia.com/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </a>{' '}
                    for details
                  </span>
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <button type="button" onClick={() => handleSubmit(onSubmit)()}>
                  Sign Me Up
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default SmsOptin;
