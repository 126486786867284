import {
  SEARCH_REQUEST,
  SET_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
} from './constants';

export const fetchResults = (term, entity) => ({
  type: SEARCH_REQUEST,
  term,
  entity,
});

export const setSearchResults = results => ({
  type: SET_SEARCH_RESULTS,
  results,
});

export const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});
