import axios from 'axios';
import {
  ES_HOST,
} from './constants';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const entityMapping = (entity) => ({
  bands: 'bands',
  bars: 'venues',
  venues: 'venues',
}[entity]);

const buildQuery = (term, metroId) => ({
  bands: {
    sort: [
      {
        popularity: {
          order: 'desc',
          mode: 'max',
        },
      },
    ],
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            match_phrase: {
              title: {
                query: term,
                slop: 3,
              },
            },
          },
          {
            match: {
              'title_keyword.downcase': {
                query: term,
              },
            },
          },
        ],
        must: [
          {
            term: {
              public_or_private: {
                value: 'public',
              },
            },
          },
        ],
      },
    },
  },
  venues: {
    query: {
      bool: {
        minimum_should_match: 1,
        should: [
          {
            match_phrase: {
              title: {
                query: term,
                slop: 4,
              },
            },
          },
          {
            match: {
              title: {
                query: term,
              },
            },
          },
          {
            match: {
              'title_keyword.downcase': {
                query: term,
              },
            },
          },
        ],
        must: [
          {
            term: {
              public_or_private: {
                value: 'public',
              },
            },
          },
          {
            term: {
              metro_id: {
                value: metroId,
              },
            },
          },
        ],
      },
    },
  },
});

const search = (entity, term, metroId) => {
  const searchUrl = `//${ES_HOST}/${entityMapping(entity)}_search_${window.env}/_search`;
  const query = JSON.stringify(buildQuery(term, metroId)[entityMapping(entity)]);
  const request = axios.post(searchUrl, query, config).then(res => res);
  return request;
};

export {
  search,
};
