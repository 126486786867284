import React from 'react';
import { useSelector } from 'react-redux';
import { useFieldArray } from 'react-hook-form';
import Search from '../Search/Search';
import {
  selectCityName,
  selectGenres,
} from '../../helpers/metro/selectors';
import CheckboxGroup from '../../helpers/form/CheckboxGroup';
import Spotify from './Spotify/Spotify';

const MusicPreferences = ({ control, register, watch, setValue, cableCarRoute }) => {
  const cityName = useSelector(selectCityName);
  const genres = useSelector(selectGenres);

  const {
    append,
  } = useFieldArray({
    control,
    name: 'bands',
    keyName: 'fieldId',
  });
 
  const externalItems = watch('bands');

  return (
    <section>
      <h2 className="sub-header">Music Preferences</h2>
      <p className="helper-text">We want to get you out to shows where you’ll dig the vibe! Here’s your chance to tell us how to do exactly that.</p>
      <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>What are your favorite music venues in {cityName}?</h4>
      <Search
        control={control}
        entity='venue'
        setValue={setValue}
        watch={watch}
        register={register}
        group_name='venues'
        placeholder='Search favorite venues...'
      />
      <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>Who are your favorite artists? (try to add at least 10)</h4>
      <p>Link your Spotify account to make it easy. </p>
      <p><Spotify cableCarRoute={cableCarRoute} append={append} items={externalItems} /></p>
      <Search
        control={control}
        entity='band'
        setValue={setValue}
        watch={watch}
        register={register}
        placeholder='Search favorite artists...'
        externalAppend={append}
        externalItems={externalItems}
      />
      <section>
        <CheckboxGroup
          items={genres}
          fieldName='genres'
          register={register}
          control={control}
          columns
          questionText='Which of the following genres of music are you interested in?'
        />
      </section>
    </section>
  );
};

export default MusicPreferences;
