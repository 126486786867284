import { createSelector } from 'reselect';

const activeMetro = state => state.metro;

export const selectMetro = createSelector(
  activeMetro,
  metro => metro
);

export const selectPartner = createSelector(
  activeMetro,
  metro => metro.partner
);

export const selectMetroId = createSelector(
  selectMetro,
  metro => metro.id,
);

export const selectMetroSettings = createSelector(
  activeMetro,
  metro => metro.settings,
);

export const selectAvailableMetros = createSelector(
  activeMetro,
  metro => metro.available_metros,
);

export const selectSupportEmail = createSelector(
  selectMetroSettings,
  settings => settings.support_email
);

export const selectSiteName = createSelector(
  selectMetroSettings,
  settings => settings.site_name
);

export const selectCityName = createSelector(
  selectMetroSettings,
  settings => settings.city_name
);

export const selectReleaseOffers = createSelector(
  selectMetroSettings,
  settings => settings.release_offers
);

export const selectAutoReg = createSelector(
  selectMetroSettings,
  settings => settings.auto_reg
);

export const selectSiteDomain = createSelector(
  selectMetro,
  metro => metro.domain
);

export const selectNeighborhoods = createSelector(
  selectMetroSettings,
  settings => settings.neighborhoods,
);

export const selectSteps = createSelector(
  selectMetro,
  metro => metro.steps,
);

export const selectPartnerSteps = createSelector(
  selectMetro,
  metro => metro.partner_steps.filter(step => step.enabled),
);

export const selectExperiences = createSelector(
  selectMetroSettings,
  settings => settings.experiences,
);

export const selectGenres = createSelector(
  selectMetroSettings,
  settings => settings.genres,
);

export const selectGTMId = createSelector(
  selectMetroSettings,
  settings => settings.tag_manager_id,
);

export const selectFood = createSelector(
  selectMetroSettings,
  settings => settings.food,
);

export const selectMapCenter = createSelector(
  selectMetroSettings,
  settings => settings.map_center.split(',')
);

export const selectTos = createSelector(
  selectMetroSettings,
  settings => settings.tos_url
);

export const selectNeighborhoodGeoJson = createSelector(
  selectMetro,
  neighborhoods => neighborhoods.neighborhoods
);
