import { takeEvery, call } from 'redux-saga/effects';
import axios from 'axios';
import {
  CHECK_SESSION,
  CLAIM,
} from './constants';

export default function* watcherSaga() {
  yield takeEvery(CHECK_SESSION, checkSession);
  yield takeEvery(CLAIM, claim);
}

function* checkSession({ sessionId, resolve }) {
  try {
    const payload = yield call(sessionRequest, sessionId);
    resolve(payload.data);
  } catch (e) {
    console.log(e);
  }
}

function* claim({ customer_token, perk_token, cs_token, source, resolve, reject }) {
  try {
    const payload = yield call(claimPerk, customer_token, perk_token, cs_token, source);
    resolve(payload);
  } catch (e) {
    reject(e);
  }
}

const claimPerk = (customer_token, perk_token, cs_token, source) => {
  const claimUrl = '/claim';
  const request = axios.post(claimUrl, {
    claim: {
      customer_token,
      perk_token,
      cs_token,
      source,
    },
  });
  return request;
};

const sessionRequest = (sessionId) => {
  const sessionRequestUrl = `/session_check/${sessionId}`;
  const request = axios.get(sessionRequestUrl).then(res => res);
  return request;
};
