import React from 'react';

const Checkout = () => (
  <div id="plan-selector-container">
    <div className="plan-card">
      <h3>3 Month</h3>
      <a href='https://pay.dostuffmedia.com/checkout/3-month-gift'>Click here to buy</a>
    </div>
    <div className="plan-card">
      <h3>6 Month</h3>
      <a href='https://pay.dostuffmedia.com/checkout/6-month-gift'>Click here to buy</a>
    </div>
    <div className="plan-card">
      <h3>12 Month</h3>
      <a href='https://pay.dostuffmedia.com/checkout/12-month-gift'>Click here to buy</a>
    </div>
    <div className="plan-card">
      <h3>Join</h3>
      <a href='https://pay.dostuffmedia.com/checkout/monthly'>Join</a>
    </div>
  </div>
);

export default Checkout;
