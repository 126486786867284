import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Countdown from 'react-countdown';
import { fetchLastMinute } from './actions';
import {
  selectCustomerToken,
  selectLastMinuteAllowed,
  selectLastMinuteClaimedAt,
} from '../Profile/selectors';
import { claim } from '../Confirmation/actions';
import ClaimModal from './ClaimModal';
import PerkGiveaway from '../PerkGiveaways/PerkGiveaway';

const Picker = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lastMinuteAllowed = useSelector(selectLastMinuteAllowed);
  const lastMinuteClaimedAt = useSelector(selectLastMinuteClaimedAt);
  const [rescinded, setRescinded] = useState([]);

  const customerToken = useSelector(selectCustomerToken);
  const [store, setStore] = useState({
    perks: [],
    loading: true,
    loadingMessage: 'Finding last minute tickets!',
    claimed: false,
    selectedPerk: null,
    modalOpen: false,
  });

  useEffect(() => {
    new Promise((resolve, reject) => dispatch(fetchLastMinute(resolve, reject)))
      .then(
        ({
          data: {
            perks: { records: perks },
          },
        }) => {
          setStore(() => ({ loading: false, perks }));
        }
      )
      .catch(() => {
        history.push(`/account/${customerToken}`);
      });
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'LMP',
    });
  }, []);

  const claimPerk = (token) => {
    const perk = perks.find((p) => p.token === token);
    setStore((oldStore) => ({
      ...oldStore,
      loading: true,
      loadingMessage: `Claiming ${perk.name}`,
    }));
    new Promise((resolve, reject) => {
      dispatch(
        claim(customerToken, token, null, 'last_minute', resolve, reject)
      );
    })
      .then(() => {
        window.location.href = `/tickets/${customerToken}`;
      })
      .catch((e) => {
        setStore((oldStore) => ({
          ...oldStore,
          loading: false,
          claimed: false,
        }));
        setRescinded([...rescinded, token]);
        toastr.error('Oops', e.response.data.message);
      });
  };

  const toggleModal = () =>
    setStore((oldStore) => ({ ...oldStore, modalOpen: !oldStore.modalOpen }));

  const { perks, loading, loadingMessage, claimed, modalOpen, selectedPerk } =
    store;

  return (
    <div className='grid-x grid-padding-x grid-padding-y perks'>
      <div className='small-12 cell float-center ds-container'>
        <h1 className='profile-title' style={{ fontSize: '48px' }}>
          Last Minute Tix
        </h1>
        <p style={{ color: 'black' }}>
          The Details: You can claim tickets once within seven days. If you see
          something you like - claim it fast! Once they're gone they're gone.
        </p>
        {!lastMinuteAllowed && (
          <>
            Next claim is available in:
            <Countdown
              date={moment.unix(lastMinuteClaimedAt).add(7, 'days').toDate()}
              renderer={(data) => (
                <div>
                  {data.days} days {data.hours} hours {data.minutes} minutes{' '}
                  {data.seconds} seconds
                </div>
              )}
            />
            <br />
          </>
        )}
        {loading && (
          <div className='small-12 large-6 cell text-center'>
            <br />
            <br />
            {loadingMessage}
            <br />
            <PuffLoader loading={loading} size={25} />
          </div>
        )}
        {claimed && (
          <div className='small-12 large-6 cell text-center'>
            <h3>Thanks for claiming...</h3>
          </div>
        )}
        {!loading &&
          perks
            .map((perk) => ({ ...perk, image_url: perk.event_image_url }))
            .filter((perk) => !rescinded.includes(perk.token))
            .sort((a, b) => moment(a?.event?.begin_time).unix() - moment(b?.event?.begin_time).unix())
            .map((perk) => (
              <PerkGiveaway
                claimPerk={() =>
                  setStore((oldStore) => ({
                    ...oldStore,
                    selectedPerk: perk,
                    modalOpen: true,
                  }))
                }
                lastMinuteAllowed={lastMinuteAllowed}
                wideButton
                key={`perk-${perk.token}`}
                perk={perk}
                screen='lmp'
              />
            ))}
      </div>
      {modalOpen && (
        <ClaimModal
          perk={selectedPerk}
          isOpen={modalOpen}
          toggleModal={toggleModal}
          claimPerk={claimPerk}
        />
      )}
    </div>
  );
};

export default Picker;
