import React from 'react';

const Input = ({ name, errorMessage, register, label, password }) => (
  <>
    <label htmlFor={name}>
      <span>{label}</span>
      <input name={name} placeholder={label} type={password ? 'password' : 'text'} className="field" ref={register({ required: errorMessage })} />
    </label>
  </>
);

export default Input;
