import { takeEvery, call, select } from 'redux-saga/effects';
import axios from 'axios';
import {
  FETCH_LAST_MINUTE,
} from './constants';
import { selectCustomerToken } from '../Profile/selectors';

export default function* watcherSaga() {
  yield takeEvery(FETCH_LAST_MINUTE, fetchLastMinute);
}

function* fetchLastMinute({ resolve, reject }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const payload = yield call(fetchLastMinuteRequest, customerToken);
    resolve(payload);
  } catch (e) {
    reject();
  }
}

const fetchLastMinuteRequest = (customerToken) => {
  const lmUrl = `/lm/${customerToken}`;
  const request = axios.get(lmUrl).then(res => res);
  return request;
};

