import React from 'react';
import { useFieldArray } from 'react-hook-form';
import classNames from 'classnames';

const CheckboxGroup = ({ control, fieldName, items, register, questionText, columns }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const toggleCheckbox = item => {
    const found = fields.findIndex(field => field.name === item.name);
    if (found === -1) {
      append({ name });
    } else {
      remove(found);
    }
  };

  return (
    <>
      <h4 className="required has-helper-text" style={{ fontSize: '28px' }}>{questionText}</h4>
      <fieldset
        className={classNames(
          'form-row favorites',
          columns && 'checkbox-columns',
        )}
      >
        {items.map((item, index) => (
          <div
            onClick={() => toggleCheckbox(item)}
            role="button"
            key={`${fieldName}-${item}-tainer`}
            className={classNames(
              'favorite-wrapper favorite-bar callout',
              fields.find(field => field.name === item) && 'selected',
            )}
            style={{ opacity: 1 }}
          >
            <label htmlFor={`${fieldName}-${item}`}>{item}</label>
            <div style={{ display: 'none' }} className="checkbox-wrapper" key={`${fieldName}-checkbox-${item}`}>
              <input
                name={`${fieldName}[${index}].name`}
                ref={register}
                type='checkbox'
                className='radio-button'
                value={item}
                id={`${fieldName}-${item}`}
                onChange={() => toggleCheckbox(item)}
                checked={fields.find(field => field.name === item) || false}
              />
              <label htmlFor={`${fieldName}-${item}`}>{item}</label>
            </div>
          </div>
        ))}
      </fieldset>
    </>
  );
};

export default CheckboxGroup;
