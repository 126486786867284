import {
  SET_ALL_PERK_GIVEAWAYS,
  UPDATE_PERK_GIVEAWAY,
  TOGGLE_MODAL,
  TOGGLE_OFFER_MODAL,
  DISPLAY_TRANSFER_MODAL,
  ACTIVATE_BUNDLE,
  REQUEST_TICKETS,
} from './constants';

export const updatePerkGiveaway = (params, resolve, reject) => ({
  type: UPDATE_PERK_GIVEAWAY,
  params,
  resolve,
  reject,
});

export const setAllPerkGiveaways = (perk_giveaways) => ({
  type: SET_ALL_PERK_GIVEAWAYS,
  perk_giveaways,
});

export const toggleModal = () => ({
  type: TOGGLE_MODAL,
});

export const toggleOfferModal = () => ({
  type: TOGGLE_OFFER_MODAL,
});

export const displayTransferModal = (actionName, token) => ({
  type: DISPLAY_TRANSFER_MODAL,
  token,
});

export const activateBundle = (token, resolve, reject) => ({
  type: ACTIVATE_BUNDLE,
  token,
  resolve,
  reject,
});

export const requestTickets = (params, token, resolve, reject) => ({
  type: REQUEST_TICKETS,
  params,
  token,
  resolve,
  reject,
});
