import { createSelector } from 'reselect';
import moment from 'moment';

export const selectAllPerkGiveaways = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.all
);

export const selectBundles = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.bundles
);

export const selectAllPerkGiveawaysCount = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.all.length
);

export const selectPendingEvents = createSelector(
  selectAllPerkGiveaways,
  (perkGiveaways) =>
    perkGiveaways.filter((p) => {
      const claimBy = moment(p.claim_by);
      const now = moment();
      return (
        claimBy.isSameOrAfter(now) &&
        p.status === 'sent' &&
        p.perk.event &&
        !p.bundled
      );
    })
);

export const selectExpiredTickets = createSelector(
  selectAllPerkGiveaways,
  (perkGiveaways) =>
    perkGiveaways.filter((p) => {
      const claimBy = moment(p.claim_by);
      const now = moment();
      return now.isSameOrAfter(claimBy) && p.status === 'sent';
    })
);

export const selectUpcomingTickets = createSelector(
  selectAllPerkGiveaways,
  (perkGiveaways) =>
    perkGiveaways.filter((p) => {
      const now = moment();
      const startTime = moment(p.perk.event?.begin_time);
      return (
        startTime.isSameOrAfter(now) &&
        p.perk_type_id === 1 &&
        p.status === 'claimed'
      );
    })
);

export const selectPastEvents = createSelector(
  selectAllPerkGiveaways,
  (perkGiveaways) =>
    perkGiveaways
      .filter((p) => {
        const startTime = moment(p.perk.event?.begin_time);
        const now = moment();
        if (p.bundled && p.status === 'claimed' && now.isSameOrAfter(startTime)) {
          return true;
        }
        return ((now.isSameOrAfter(startTime) && p.status === 'claimed') || p.status === 'released' || p.status === 'transferred');
      })
);

export const selectModalVisibility = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.isModalOpen
);

export const selectCurrentPerkGiveaway = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.current
);

export const selectOfferModalVisibility = createSelector(
  (state) => state.perk_giveaways,
  (perkGiveaways) => perkGiveaways.offerDetailsModalOpen
);
