import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { BsHeart, BsHeartFill } from 'react-icons/bs';
import { selectCustomerStatus } from '../Profile/selectors';

const Perk = ({
  perk,
  selectedPerk,
  togglePerk,
  claimPerk,
  button,
  picked,
  wideButton,
  lastMinuteAllowed,
}) => {
  const logo_url = perk.image === '' ? null : perk.image;
  const customerStatus = useSelector(selectCustomerStatus);
  const [highlighted, setHighlighted] = useState(false);
  const heartState = () => {
    if (!button) {
      return null;
    }
    if (selectedPerk && selectedPerk.token === perk.token) {
      return <BsHeartFill color='red' />;
    }
    if (highlighted) {
      return <BsHeart color='red' />;
    }
    return <BsHeart />;
  };

  return (
    <>
      <div
        className='grid-x grid-margin-x ds-offer'
        key={`giveaway-${perk.name}`}
      >
        <div
          className={classNames('grid-x cell card ds-offer-card', {
            'medium-8 large-8': true,
            'selected-perk': selectedPerk && selectedPerk.token === perk.token,
            'wide-button-wrapper': wideButton,
            'last-minute-decline': !lastMinuteAllowed && wideButton,
            'non-selected-perk':
              !isEmpty(selectedPerk) && selectedPerk.token !== perk.token,
          })}
          role='button'
          onClick={() => {
            if (togglePerk) {
              togglePerk(perk);
            }
          }}
          onMouseEnter={() => setHighlighted(true)}
          onMouseLeave={() => setHighlighted(false)}
        >
          {selectedPerk && selectedPerk.token === perk.token && (
            <span className='label ds-offer-status ds-offer-status-claimed selected'>
              {picked ? 'claimed' : 'selected'}
            </span>
          )}
          {logo_url && (
            <div
              className={classNames('cell ds-offer-image', {
                'medium-4 large-4': true,
              })}
              style={{ backgroundImage: `url('${logo_url}')` }}
            />
          )}
          <div
            className={classNames('cell ds-offer-details', {
              'medium-8 large-8': true,
            })}
          >
            <h4
              className='ds-offer-details-title'
              style={{ minHeight: '30px' }}
            >
              {perk.event_title}
            </h4>
            <div className='grid-x ds-offer-details-lower'>
              <p className='cell medium-12 large-8 helper-text'>
                <span className='ds-venue'>{perk.venue_title}</span>{' '}
                <span className='ds-date'>
                  {moment(perk.event_date).format('ddd, MMM Do YYYY')}
                </span>
              </p>
              {!button && heartState()}
              {!wideButton && button && (
                <button
                  className='ds-claim-after-release'
                  onClick={() => claimPerk(perk.token)}
                >
                  Claim
                </button>
              )}
            </div>
          </div>
        </div>
        <div className='grid-x wide-button'>
          {wideButton && (
            <button
              className={classNames('wide-button-btn', {
                'last-minute-decline': !lastMinuteAllowed,
              })}
              disabled={!lastMinuteAllowed || ['past_due', 'unpaid', 'canceled'].includes(customerStatus)}
              onClick={() => claimPerk(perk.token)}
            >
              Claim
            </button>
          )}
        </div>
      </div>
    </>
  );
};
export default Perk;
