import { get } from 'lodash';
import {
  SET_ALL_PERK_GIVEAWAYS,
  SET_CURRENT_PERK_GIVEAWAY,
  TOGGLE_MODAL,
  TOGGLE_OFFER_MODAL,
} from './constants';

const initialState = {
  all: get(window.member_data, 'perk_giveaways', []),
  bundles: get(window.member_data, 'bundles', []),
  current: {},
  isModalOpen: false,
  offerDetailsModalOpen: false,
};

function perkGiveawaysReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PERK_GIVEAWAY: {
      const perkGiveaway = state.all.find(pg => pg.token === action.token);
      return {
        ...state,
        current: {
          ...perkGiveaway,
        },
      };
    }
    case SET_ALL_PERK_GIVEAWAYS: {
      return {
        ...state,
        all: action.perk_giveaways,
        bundles: action.bundles,
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    }
    case TOGGLE_OFFER_MODAL: {
      return {
        ...state,
        offerDetailsModalOpen: !state.offerDetailsModalOpen,
      };
    }

    default:
      return state;
  }
}

export default perkGiveawaysReducer;
