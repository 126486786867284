import React from 'react';
import { BiCheck, BiTransfer, BiX } from 'react-icons/bi';

export const API_ERRORED = 'API_ERRORED';
export const UPDATE_PERK_GIVEAWAY = 'UPDATE_PERK_GIVEAWAY';
export const SET_CURRENT_PERK_GIVEAWAY = 'SET_CURRENT_PERK_GIVEAWAY';
export const SET_ALL_PERK_GIVEAWAYS = 'SET_ALL_PERK_GIVEAWAYS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_OFFER_MODAL = 'TOGGLE_OFFER_MODAL';
export const DISPLAY_TRANSFER_MODAL = 'DISPLAY_TRANSFER_MODAL';
export const ACTIVATE_BUNDLE = 'ACTIVATE_BUNDLE';
export const REQUEST_TICKETS = 'REQUEST_TICKETS';

export const ICON_MAPPING = {
  claim: <BiCheck />,
  release: <BiX />,
  transfer: <BiTransfer />,
};

export const CLAIMED_STATUS = 'claimed';
export const RELEASED_STATUS = 'released';
export const TRANSFERRED_STATUS = 'transferred';
export const SENT_STATUS = 'sent';
export const ACTIONED_STATUSES = [CLAIMED_STATUS, RELEASED_STATUS, TRANSFERRED_STATUS];
export const ALL_STATUSES = [...ACTIONED_STATUSES, SENT_STATUS];
