import React from 'react';
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import { FaCalendar } from 'react-icons/fa';
import moment from 'moment';

const AddToCalendar = ({ perk }) => {
  const handleClick = () => {
    atcb_action({
      name: `${perk.perk.event?.title ?? ''}`,
      startDate: `${perk.perk.event?.begin_date ?? moment().format('YYYY-MM-DD')}`,
      startTime: `${
        moment(perk.perk.event?.begin_time).local().format('HH:mm') ??
        moment().format('HH:mm')
      }`,
      endTime: `${
        moment(perk.perk.event?.begin_time)
          .endOf('day')
          .local()
          .format('HH:mm') ?? moment().endOf('day').format('HH:mm')
      }`,
      location: `${perk.perk.event?.venue.address ?? ''} ${
        perk.perk.event?.venue.city ?? ''
      },${perk.perk.event?.venue.state ?? ''} ${
        perk.perk.event?.venue.zip ?? ''
      }`,
      description: `${perk.perk.event?.description ?? ''}`,
      options: ['Google', 'iCal'],
      timeZone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      iCalFileName: 'DoMore',
    });
  };

  return (
    <>
      <button className='atcb' type='button' onClick={handleClick}>
        <FaCalendar size='13px' /> Add to Calendar
      </button>
    </>
  );
};

export default AddToCalendar;
