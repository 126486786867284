import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import Injector from '../../helpers/Injector';

import {
  selectMetroId,
  selectSupportEmail,
} from '../../helpers/metro/selectors';
import { selectCustomerEmail, selectCustomerName, selectCustomerPlanOption, selectCustomerStatus, selectCustomerToken, selectOption, selectProfile } from '../Profile/selectors';
import { subscribe, setCustomerAndProfile, pickCity } from '../Profile/actions';

const CreateSubscription = () => {
  const supportEmail = useSelector(selectSupportEmail);
  const customerToken = useSelector(selectCustomerToken);
  const customerStatus = useSelector(selectCustomerStatus);
  const customerPlanOption = useSelector(selectCustomerPlanOption);
  const name = useSelector(selectCustomerName);
  const option = useSelector(selectOption);
  const metroId = useSelector(selectMetroId);
  const email = useSelector(selectCustomerEmail);
  const pro = useSelector(selectProfile);
  const [stripeError, setStripeError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatchGTM = useGTMDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const doPickCity = () => {
    if (customerStatus === 'lead') {
      dispatch(pickCity(metroId, customerPlanOption));
    } else {
      dispatch(pickCity(metroId, 'monthly'));
    }
  };

  useEffect(() => {
    if (metroId) {
      doPickCity();
    }
  }, [metroId]);

  const doSubscribe = (token) => {
    new Promise((resolve, reject) => {
      setSubmitting(true);
      setStripeError(null);
      dispatch(
        subscribe(
          {
            stripe_token: token,
            customer_token: customerToken,
            experiences: pro.experiences,
            name: pro.name,
            first_name: pro.first_name,
            last_name: pro.last_name,
            email: pro.email,
            phone_number: pro.phone_number,
          },
          resolve,
          reject
        )
      );
    })
      .then((payload) => {
        const {
          data: {
            customer: { customer, profile },
            tracking,
          },
        } = payload;
        const product = tracking.transactionProducts[0];
        window.gtag('event', 'purchase', {
          transaction_id: tracking.transactionId,
          value: tracking.transactionTotal,
          tax: 0,
          shipping: 0,
          currency: 'USD',
          items: [
            {
              item_id: product.sku,
              item_name: `${product.name} ${product.variation}`,
              affiliation: 'DoMORE',
              discount: 0,
              index: 0,
              item_brand: product.name,
              item_category: product.category,
              price: product.price,
              quantity: product.quantity,
            },
          ],
        });
        dispatchGTM(tracking);
        dispatch(setCustomerAndProfile(customer, profile));
        setSubmitting(false);
      })
      .catch(
        ({
          response: {
            data: { message },
          },
        }) => {
          setSubmitting(false);
          setStripeError(message);
        }
      );
  };

  const stripeRequest = async () => {
    setSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      setSubmitting(false);
      if (error.code === 'incomplete_zip') {
        setStripeError('Zipcode is required!');
      } else {
        setStripeError(
          `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
        );
      }
    } else {
      doSubscribe(token.id, setSubmitting);
    }
  };
  return (
    <div className='cell small-12 large-12 billing-info'>
      <div className='card'>
        <div className='card-divider'>
          <h4>Subscription Plan</h4>
        </div>
        <div className='card-section'>
          <>
            <div className="ds-subscription-details">
              <div
                className={classNames('ds-subscription-subtotal', {
                  strikeout: option.has_trial,
                })}
              >
                {option.subtotal_text && <Injector body={option.subtotal_text} />}
              </div>
              {option.discount_amount && <div className="ds-subscription-total"><Injector body={option.discount_amount} /></div>}
            </div>
            <div className="grid-x ds-subscription-final-total">
              <>
                {option.discount_info &&
                  <div className="cell small-6 ds-discount-info">
                    <Injector body={option.discount_info} />
                  </div>
                }
                {option.total_text && <div className="cell small-6 text-right ds-due-today"><Injector body={option.total_text} /></div>}
              </>
            </div>
            <div className="grid-x ds-subscription-contact-info">
              <p>
                Name: {name || 'Jon Thomas'}
                <br />
                Email: {email || 'jon@domsehing.com'}
              </p>
            </div>
          </>
          {submitting && <p>Creating your account, please standby!</p>}
          <div style={submitting ? { display: 'none' } : {}}>
            <form>
              <section style={{ marginBottom: 0 }}>
                <div className='payment-info stepper-payment-info'>
                  {stripeError && (
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '.8em',
                        color: 'red',
                        marginBottom: '10px',
                      }}
                    >
                      {stripeError}
                    </div>
                  )}
                  <fieldset>
                    <label htmlFor='card-element'>
                      <CardElement id='card-element' className='field' />
                    </label>
                  </fieldset>
                  <button
                    type='button'
                    disabled={submitting}
                    onClick={() => {
                      setSubmitting(true);
                      stripeRequest();
                    }}
                  >
                    {submitting ? 'Creating your subscription...' : 'Sign Up'}
                  </button>
                </div>
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSubscription;
