import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { get } from 'lodash';
import ReduxToastr from 'react-redux-toastr';
import axios from 'axios';
import Modal from 'react-modal';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-step-progress-bar/styles.css';
import * as Sentry from '@sentry/react';
import App from './App';
import GiftRedeem from './components/GiftRedeem/GiftRedeem';
import Checkout from './components/Checkout/Checkout';
import reportWebVitals from './reportWebVitals';
import setupStore from './store';

Sentry.init({
  dsn: 'https://81e2b7014052f32c8ceaf5162c1a2dc3@sentry.dostff.co//8',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/domorestuff\.com\/(.*)$/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

const rootElement = document.getElementById('root');
let comps;

const appName = get(rootElement.dataset, 'appname', 'members');

Modal.setAppElement('body');

if (appName === 'members') {
  const { store, cableCarRoute } = setupStore('members');

  comps = (
    <Provider store={store}>
      <App cableCarRoute={cableCarRoute} />
      <ReduxToastr
        timeOut={40000000}
        preventDuplicates
        position='top-right'
        getState={(state) => state.toastr}
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        closeOnToastrClick
      />
    </Provider>
  );
} else if (appName === 'checkout') {
  const { store } = setupStore('gift-redeem');
  comps = (
    <Provider store={store}>
      <Checkout />
    </Provider>
  );
} else if (appName === 'gift-redeem') {
  const { store } = setupStore('gift-redeem');
  comps = (
    <Provider store={store}>
      <GiftRedeem />
    </Provider>
  );
}

ReactDOM.render(comps, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
