import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Input } from '../../../helpers/form';
import { selectSiteName, selectSiteDomain } from '../../../helpers/metro/selectors';
import { login } from '../actions';
import Errors from '../Errors';

const Login = ({ setDisplay, switchUser, onClose }) => {
  const dispatch = useDispatch();
  const siteName = useSelector(selectSiteName);
  const siteDomain = useSelector(selectSiteDomain);
  const { customerToken } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = params => (
    new Promise((resolve, reject) => (
      dispatch(login(params, customerToken, resolve, reject))
    )).then(() => switchUser && onClose(false))
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isEmpty(errors) && <Errors errors={errors} />}
      <fieldset className='form-row' style={{ marginBottom: 0, marginTop: '10px' }}>
        <Input
          name='email'
          register={register}
          label='Email'
          errorMessage='Email is required!'
        />
        <Input
          name='password'
          register={register}
          label='Password'
          errorMessage='Password is required'
          password
        />
        {switchUser &&
          <input
            name='switch_user'
            ref={register()}
            type='hidden'
            value='switch'
          />
        }
      </fieldset>
      <button type='submit' style={{ marginTop: '10px' }}>
        Login
      </button>
      <p style={{ textAlign: 'center' }}>
        <a href={`https://${siteDomain}/users/password/new`} target='_blank' rel='noopener noreferrer'>
          Forgot your password?
        </a>
      </p>
      <p style={{ textAlign: 'center' }}>
        {!switchUser && `Don't have a ${siteName} account?`}
        {switchUser && `Want to create a new ${siteName} account?`}
        <a className='auth-switch' onClick={() => setDisplay('register')} role='button'>
          &nbsp;Create one
        </a>.
      </p>
    </form>
  );
};

export default Login;
