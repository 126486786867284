import {
  LOGIN,
  LOGIN_SUCCESS,
  MAGIC_LINK,
  REGISTER,
} from './constants';

export const login = (params, customerToken, resolve, reject) => ({
  type: LOGIN,
  params,
  customerToken,
  resolve,
  reject,
});

export const loginSuccess = ({ email, token, expirationDate }) => ({
  type: LOGIN_SUCCESS,
  email,
  token,
  expirationDate,
});

export const magicLink = (email, resolve, reject) => ({
  type: MAGIC_LINK,
  email,
  resolve,
  reject,
});

export const userRegister = (params, customerToken, resolve, reject) => ({
  type: REGISTER,
  params,
  customerToken,
  resolve,
  reject,
});
