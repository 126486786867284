import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PerkGiveaway from './PerkGiveaway';
import Referral from './Referral';
import { claim } from '../Confirmation/actions';
import {
  selectUpcomingTickets,
  selectPastEvents,
  selectExpiredTickets,
  selectBundles,
} from './selectors';
import { selectCustomerToken } from '../Profile/selectors';
import { selectReleaseOffers } from '../../helpers/metro/selectors';
import Bundle from './Bundle';

const PerkGiveaways = () => {
  const upcomingTickets = useSelector(selectUpcomingTickets);
  const pastEvents = useSelector(selectPastEvents);
  const expiredTickets = useSelector(selectExpiredTickets);
  const customerToken = useSelector(selectCustomerToken);
  const releaseOffers = useSelector(selectReleaseOffers);
  const bundles = useSelector(selectBundles);
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [hasOffers, setHasOffers] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [referral, setReferral] = useState({});
  const [field, setField] = useState('event_at_unix');
  const [bundleExpired, setBundleExpired] = useState([]);
  const [rescinded, setRescinded] = useState([]);
  const direction = 'desc';

  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'Tickets',
    });
  }, []);

  const claimPerk = (token) => {
    new Promise((resolve, reject) => {
      dispatch(claim(customerToken, token, null, 'release', resolve, reject));
    })
      .then(() => {
        setSelectedOffer(offers.find((p) => p.token === token));
        setTimeout(() => window.location.reload(), 5000);
      })
      .catch((e) => {
        setRescinded([...rescinded, token]);
        toastr.error('Oops', e.response.data.message);
      });
  };

  const updateOffersOrReferral = (t, off, ref, actionName) => {
    if (actionName === 'release') {
      if (releaseOffers) {
        setOffers(off);
        setHasOffers(!isEmpty(off));
      }
    }
    if (!isEmpty(ref)) {
      setReferral(ref);
    }
  };

  const allPastEvents = [...pastEvents, ...expiredTickets];
  const sortedUpcomingTickets = upcomingTickets.sort(
    (a, b) => a.event_at_unix - b.event_at_unix
  ).map((perk) => ({ ...perk, perk: { ...perk.perk, image_url: perk.perk.event_image_url } }));
  const sortedPastEvents = allPastEvents.sort((a, b) => {
    const sorting =
      direction === 'asc' ? a[field] - b[field] : b[field] - a[field];
    return sorting;
  }).map((perk) => ({ ...perk, perk: { ...perk.perk, image_url: perk.perk.event_image_url } }));

  return (
    <div className='grid-x grid-padding-x grid-padding-y perks'>
      <div className='small-12 cell float-center ds-container'>
        {!isEmpty(referral) && <Referral referral={referral} />}
        <div className='perk-selector'>
          {isEmpty(selectedOffer) && !isEmpty(offers) && (
            <h4>Thanks for releasing. Here's a couple more you might like:</h4>
          )}
          {isEmpty(selectedOffer) &&
            !isEmpty(offers) &&
            offers
              .filter((perk) => !rescinded.includes(perk.token))
              .sort((a, b) => a.event_at_unix - b.event_at_unix)
              .map((perk) => (
                <PerkGiveaway
                  claimPerk={claimPerk}
                  lastMinuteAllowed
                  wideButton
                  key={`perk-${perk.token}`}
                  perk={perk}
                  screen='release'
                />
              ))}
          {isEmpty(selectedOffer) && !isEmpty(offers) && (
            <p>
              None of these work for you either? Make sure your{' '}
              <strong>
                <u>
                  <NavLink to={`/profile/${customerToken}`}>
                    profile is filled out
                  </NavLink>
                </u>
              </strong>
              , and we'll keep trying to find you something you'll love. thanks!
            </p>
          )}
          {!hasOffers && (
            <p>
              Uh oh. Looks like we ran out of tickets that match your profile.
              Never fear though - we'll soon be emailing you another pair. Or,
              you can email us at{' '}
              <a
                href='mailto:domore@dostuffmedia.com'
                target='_blank'
                rel='noreferrer'
              >
                domore@dostuffmedia.com
              </a>{' '}
              and we'll try and find you something sooner!
            </p>
          )}
        </div>
        {!isEmpty(selectedOffer) && (
          <>
            <h3>
              You have claimed {selectedOffer.event.title}, please check your
              email!
            </h3>
          </>
        )}
        {isEmpty(selectedOffer) &&
          isEmpty(offers) &&
          bundles.length > 0 &&
          bundles
            .filter(
              (p) =>
                p.expires_at === null ||
                (moment(p.expires_at).isAfter(moment()) &&
                  p.tickets.length === 0)
            )
            .map((bundle) => (
              <Bundle
                key={`bundle-${bundle.token}`}
                bundle={bundle}
                setBundleExpired={setBundleExpired}
                bundleExpired={bundleExpired}
                updateOffersOrReferral={updateOffersOrReferral}
                claimPerk={claimPerk}
              />
            ))}
        {isEmpty(selectedOffer) && isEmpty(offers) && (
          <>
            {sortedUpcomingTickets.length > 0 && (
              <>
                <h2 className='sub-header'>Upcoming Experiences</h2>
                <p>Upcoming experiences you've already claimed. HAVE FUN:</p>
                {sortedUpcomingTickets.map((perkGiveaway) => (
                  <PerkGiveaway
                    multi={['transfer', 'release']}
                    key={`upcoming-perk-giveway-${perkGiveaway.token}`}
                    perkGiveaway={perkGiveaway}
                    upcoming
                    lastMinuteAllowed
                  />
                ))}
              </>
            )}
            {sortedPastEvents.length > 0 && (
              <>
                <h2 className='sub-header'>Previous Offers & Experiences</h2>
                <p>
                  Everything you've done (or could have) since joining DoMORE:
                </p>
                <p>
                  Sort by:{' '}
                  <input
                    type='radio'
                    name='sort'
                    value='notified_at_unix'
                    checked={field === 'notified_at_unix'}
                    onChange={(e) => setField(e.target.value)}
                    style={{ marginLeft: '5px' }}
                  />{' '}
                  Sent At{' '}
                  <input
                    type='radio'
                    name='sort'
                    value='event_at_unix'
                    checked={field === 'event_at_unix'}
                    onChange={(e) => setField(e.target.value)}
                    style={{ marginLeft: '8px' }}
                  />{' '}
                  Event Date
                </p>
                <div className='grid-x grid-padding-x'>
                  {sortedPastEvents.length > 0 &&
                    sortedPastEvents.map((perkGiveaway) => (
                      <PerkGiveaway
                        key={`past-perk-giveway-${perkGiveaway.token}`}
                        perkGiveaway={perkGiveaway}
                        past
                      />
                    ))}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PerkGiveaways;
