import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { selectAutoReg } from '../../helpers/metro/selectors';
import User from './User';
import Billing from './Billing';
import {
  selectCustomerStatus,
  selectCustomerEmail,
} from '../Profile/selectors';
import UpdateBillingInfo from './UpdateBillingInfo';
import CreateSubscription from './CreateSubscription';

const Account = () => {
  const autoReg = useSelector(selectAutoReg);
  const customerStatus = useSelector(selectCustomerStatus);
  const email = useSelector(selectCustomerEmail);
  const stripePromise = loadStripe(window.stripe_key ? window.stripe_key : '');
  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'Account',
    });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className='grid-x grid-padding-x grid-padding-y account'>
        <div className='small-12 cell float-center ds-container'>
          <div className='grid-x grid-margin-x grid-padding-y'>
            {['lead'].includes(customerStatus) && <CreateSubscription />}
            {['active', 'trialing'].includes(customerStatus) && <Billing email={email} />}
            {['past_due', 'unpaid', 'canceled'].includes(customerStatus) && (
              <UpdateBillingInfo />
            )}
            {(!autoReg || autoReg === '0') &&
              ['active', 'trialing'].includes(customerStatus) && <User />}
          </div>
        </div>
      </div>
    </Elements>
  );
};

export default Account;
