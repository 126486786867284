import React, { useState } from 'react';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import PayButton from './PayButton';
import { selectMetro } from '../../../helpers/metro/selectors';
import Injector from '../../../helpers/Injector';

const Register = ({
  cardElement,
  stripeRequest,
  isFreeTrial,
  payButtonSubmit,
  option,
  stripeError,
  name,
  email,
  newPath,
  newPathPicked,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { partner_name } = useParams();
  const metro = useSelector(selectMetro);

  return (
    <>
      {!submitting && <h4 className="required has-helper-text" style={{ fontSize: '28px' }}>You matched with 5 events</h4>}
      <div className='grid-x grid-padding-x'>
        {newPath && partner_name && newPathPicked && (
          <p>Your tickets to <strong>{newPathPicked.perk.name}</strong> are on their way! Sign up below to keep getting at least one pair of tix a month to {startCase(partner_name)} and other great {metro.title} partners.</p>
        )}
        <div className="small-12 large-6 cell" style={{ display: submitting ? 'none' : '', margin: 'auto' }}>
          <>
            <div className="ds-subscription-details">
              <div className="ds-subscription-header">
                {isFreeTrial ? 'Start trial and select your first free pair of tickets' : 'Subscription Plan'}
              </div>
              <div
                className={classNames('ds-subscription-subtotal', {
                  strikeout: isFreeTrial,
                })}
              >
                {option.subtotal_text && <Injector body={option.subtotal_text} />}
              </div>
              {option.discount_amount && <div className="ds-subscription-total"><Injector body={option.discount_amount} /></div>}
            </div>
            <div className="grid-x ds-subscription-final-total">
              <>
                {option.discount_info && <div className="cell small-6 ds-discount-info"><Injector body={option.discount_info} /></div>}
                {option.total_text && <div className="cell small-6 text-right ds-due-today"><Injector body={option.total_text} /></div>}
              </>
            </div>
            <div className="grid-x ds-subscription-contact-info">
              <p>
                Name: {name || 'Jon Thomas'}
                <br />
                Email: {email || 'jon@domsehing.com'}
              </p>
            </div>
          </>
          <PayButton setSubmitting={setSubmitting} payButtonSubmit={payButtonSubmit} />
          <form>
            <section style={{ marginBottom: 0 }}>
              <div className="payment-info stepper-payment-info">
                <fieldset>
                  <label htmlFor="card-element">
                    {cardElement}
                  </label>
                </fieldset>
                {stripeError && <div style={{ textAlign: 'center', fontSize: '.8em', color: 'red', marginTop: '20px' }}>{stripeError}</div>}
                <button disabled={submitting} type='button' onClick={() => stripeRequest(setSubmitting)}>
                  {isFreeTrial ? 'Start Trial' : 'Sign Up'}
                </button>
              </div>
            </section>
          </form>
        </div>
        {submitting && newPath && (
          <div className="small-12 large-6 cell text-center" style={{ margin: 'auto' }}>
            Thanks for signing up!
            <br />
            Setting up your Account!
            <br />
            <PuffLoader loading={submitting} size={25} />
          </div>
        )}
        {submitting && !newPath && (
          <div className="small-12 large-6 cell text-center" style={{ margin: 'auto' }}>
            Registering and retrieving matches!<br /><PuffLoader loading={submitting} size={25} />
          </div>
        )}
      </div>
    </>
  );
};

export default Register;
