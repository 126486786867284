import {
  CONFIRMATION,
  ERRORS,
} from './constants';

const initialState = {
  confirmation: {},
  confirmed: false,
  errors: {},
};

function giftRedeemReducer(state = initialState, action) {
  switch (action.type) {
    case ERRORS: {
      return {
        ...state,
        errors: action.data,
      };
    }
    case CONFIRMATION: {
      return {
        ...state,
        confirmation: action.data,
      };
    }
    default:
      return state;
  }
}

export default giftRedeemReducer;
