import {
  UPDATE_SUBSCRIPTION,
  UPDATE_CARD,
  GET_CARD_INFO,
  ANNUAL_UPGRADE,
  CANCEL_SUBSCRIPTION,
} from './constants';

export const updateSubscription = (status, token) => ({
  type: UPDATE_SUBSCRIPTION,
  status,
  token,
});

export const updateCard = (cardToken, resolve, reject) => ({
  type: UPDATE_CARD,
  resolve,
  reject,
  cardToken,
});

export const getCardInfo = (resolve, reject) => ({
  type: GET_CARD_INFO,
  resolve,
  reject,
});

export const annualUpgrade = (resolve, reject, stripeToken) => ({
  type: ANNUAL_UPGRADE,
  resolve,
  reject,
  stripeToken,
});

export const cancelSubscription = (resolve, reject) => ({
  type: CANCEL_SUBSCRIPTION,
  resolve,
  reject,
});
