import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import {
  LOGIN,
  LOGIN_SUCCESS,
  REGISTER,
  MAGIC_LINK,
} from './constants';
import {
  SET_CUSTOMER_AND_PROFILE,
} from '../Profile/constants';

export default function* watcherSaga() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(REGISTER, register);
  yield takeEvery(MAGIC_LINK, magicLink);
}

function* login({ params, customerToken, resolve, reject }) {
  try {
    const payload = yield call(loginRequest, params, customerToken);
    const { token, customer, expirationDate, profile } = payload.data;
    const { user } = customer;
    const { email } = user;
    yield localStorage.setItem('more_email', email);
    yield localStorage.setItem('more_token', token);
    yield localStorage.setItem('more_expirationDate', expirationDate);
    yield put({
      type: LOGIN_SUCCESS,
      token,
      expirationDate,
      email,
    });
    toastr.success('Success!', `Connected ${email} to your subscription!`);
    resolve();
    yield put({ type: SET_CUSTOMER_AND_PROFILE, customer, profile });
  } catch (e) {
    toastr.error('Login Failed!', e.response.data.error);
    reject();
  }
}

function* register({ params, customerToken, resolve, reject }) {
  try {
    const payload = yield call(registerRequest, params, customerToken);
    const { token, customer, expirationDate, profile } = payload.data;
    const { user } = customer;
    const { email } = user;
    yield localStorage.setItem('more_email', email);
    yield localStorage.setItem('more_token', token);
    yield localStorage.setItem('more_expirationDate', expirationDate);
    yield put({
      type: LOGIN_SUCCESS,
      token,
      expirationDate,
      email,
    });
    yield put({ type: SET_CUSTOMER_AND_PROFILE, customer, profile });
    resolve();
    toastr.success('Success!', `Connected ${email} to your subscription!`);
  } catch (e) {
    toastr.error('Registration Failed!', e.response.data.error);
    reject();
  }
}

function* magicLink({ email, resolve, reject }) {
  try {
    yield call(magicLinkRequest, email);
    resolve();
  } catch (e) {
    toastr.error('Invalid email address!', e.response.data.error);
    reject();
  }
}

const magicLinkRequest = (email) => {
  const request = axios.post('/l', {
    member: {
      email,
    },
  }).then(res => res);
  return request;
};

const loginRequest = (params, customerToken) => {
  const request = axios.post('/users/sign_in', {
    user: {
      ...params,
      customer_token: customerToken,
    },
  }).then(res => res);
  return request;
};

const registerRequest = (params, customerToken) => {
  const request = axios.post('/users', {
    customer_token: customerToken,
    user: params,
  }).then(res => res);
  return request;
};
