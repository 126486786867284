import React from 'react';
import { isEmpty, startCase } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GrClose } from 'react-icons/gr';
import Injector from '../../../helpers/Injector';
import { selectMetro } from '../../../helpers/metro/selectors';

const Landing = ({ option, setShowLanding, partner, history, newPath }) => {
  const { partner_name } = useParams();
  const metro = useSelector(selectMetro);

  return (
    <div className="grid-x grid-padding-x grid-padding-y profile-container">
      <div
        role="button"
        className="cancel-stepper"
        onClick={() => history.goBack()}
      >
        <GrClose />
      </div>
      <div className="small-12 large-12 cell float-center ds-container ds-landing">
        <section>
          {newPath && partner_name && (
            <p>
              DoMORE sends you a pair of tickets for just $5/month. From <strong>{startCase(partner_name)}</strong> and
              a bunch of other great Partners around {metro.title}.
            </p>
          )}
          {newPath && !partner_name && (
            <p>
              DoMORE sends you a pair of tickets for just $5/month. From a bunch of our great Partners around {metro.title}.
            </p>
          )}
          {!newPath && (
            <Injector
              body={option.top_block}
              overrides={{
                hasPartner: !isEmpty(partner),
                partner_name: partner.organization,
              }}
            />
          )}
        </section>
        <section>
          <div className="grid-x grid-padding-x form-profile-submit">
            <div className="small-6 large-3 cell float-center">
              <button onClick={() => setShowLanding(false)}>Continue</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landing;
