import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  REDEEM,
  ERRORS,
  RETRIEVE,
} from './constants';

export default function* watcherSaga() {
  yield takeEvery(REDEEM, redeem);
  yield takeEvery(RETRIEVE, retrieve);
}

function* redeem({ params, reject, resolve }) {
  try {
    const payload = yield call(redeemRequest, params);
    resolve(payload.data.url);
  } catch (e) {
    yield put({ type: ERRORS, data: e.response.data });
    reject();
  }
}

function* retrieve({ code, resolve, reject }) {
  try {
    const payload = yield call(retrieveGift, code);
    resolve(payload);
  } catch (e) {
    yield put({ type: ERRORS, data: e.response.data });
    reject();
  }
}

const redeemRequest = (params) => {
  const redeemUrl = `https://pay.do312.com/gifts/${params.code}/redeem`;
  const request = axios.post(redeemUrl, params).then(res => res);
  return request;
};

const retrieveGift = (code) => {
  const retrieveUrl = `https://pay.do312.com/gifts/${code}`;
  const request = axios.get(retrieveUrl).then(res => res);
  return request;
};
