import { get } from 'lodash';
import moment from 'moment';

import {
  LOGIN_SUCCESS,
} from './constants';

const isLoggedIn = () => {
  if (localStorage.getItem('more_token') === null) {
    return false;
  }
  if (localStorage.getItem('more_expirationDate') === null) {
    return false;
  }

  const currentTime = moment().unix();
  const expirationDate = parseInt(localStorage.getItem('more_expirationDate'), 10);
  if (currentTime < expirationDate) {
    return true;
  }

  return false;
};

const initialState = {
  token: get(localStorage, 'more_token', null),
  email: get(localStorage, 'more_email', null),
  expirationDate: get(localStorage, 'more_expirationDate', null),
  loggedIn: isLoggedIn(),
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { email, token, expirationDate } = action;
      return {
        ...status,
        email,
        token,
        expirationDate,
        loggedIn: true,
      };
    }

    default:
      return state;
  }
}

export default authReducer;
