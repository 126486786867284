import { takeEvery, select, call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import {
  SAVE_CUSTOMER_PROFILE,
  SET_CUSTOMER_AND_PROFILE,
  SAVE_CUSTOMER_PROFILE_LITE,
  SPOTIFY_IMPORT,
  SETUP_CUSTOMER,
  SUBSCRIBE,
  PICK_CITY,
  SET_OPTION,
  TRACK_LEAD,
  STORE_LEAD,
  SMS_OPTIN,
  CREATE_BUNDLE,
} from './constants';
import { SET_ALL_PERK_GIVEAWAYS } from '../PerkGiveaways/constants';
import { SETUP_METRO } from '../../helpers/metro/constants';
import { selectMetroId, selectSupportEmail } from '../../helpers/metro/selectors';
import {
  selectCustomerToken,
  selectIsNew,
  selectOption,
} from '../Profile/selectors';
import { memberSavePayload, setupPayload } from './decorators';

export default function* watcherSaga() {
  yield takeEvery(SAVE_CUSTOMER_PROFILE, saveCustomerProfile);
  yield takeEvery(SPOTIFY_IMPORT, spotifyImport);
  yield takeEvery(SETUP_CUSTOMER, setupCustomerProfile);
  yield takeEvery(SUBSCRIBE, subscribe);
  yield takeEvery(PICK_CITY, pickCity);
  yield takeEvery(TRACK_LEAD, trackLead);
  yield takeEvery(SMS_OPTIN, smsOptin);
  yield takeEvery(CREATE_BUNDLE, createBundle);
  yield takeEvery(SAVE_CUSTOMER_PROFILE_LITE, saveLiteProfile);
}

function* saveLiteProfile({ params, customer_token, resolve, reject }) {
  try {
    const payload = yield call(postSaveLite, params, customer_token);
    const { customer, profile, perk_giveaways, bundles } = payload.data;
    yield put({ type: SET_CUSTOMER_AND_PROFILE, profile, customer });
    yield put({
      type: SET_ALL_PERK_GIVEAWAYS,
      perk_giveaways,
      bundles,
    });
    resolve(payload.data);
  } catch (e) {
    reject(e);
  }
}

function* createBundle(action) {
  const { customer_token, ticket_dispatch_id, partner_name, optin, resolve, reject } = action;
  try {
    const payload = yield call(requestCreateBundle, customer_token, ticket_dispatch_id, partner_name, optin);
    resolve(payload.data);
  } catch (e) {
    reject(e);
  }
}

function* trackLead({ params, utm, resolve, reject }) {
  try {
    const metro_id = yield select(selectMetroId);
    const { email, first_name, last_name, phone_number, plan_option } = params;
    const requestParams = {
      email,
      first_name,
      last_name,
      phone_number,
      plan_option,
      utm,
      metro_id,
    };
    const payload = yield call(storeLead, requestParams);
    yield put({ type: STORE_LEAD, params: payload.data.lead });
    resolve();
  } catch (e) {
    reject(e);
  }
}

function* pickCity({ metro_id, option_name }) {
  try {
    const payload = yield call(requestPickCity, metro_id, option_name);
    const { option, settings, active_metro, neighborhoods, steps, partner_steps } = payload.data;
    yield put({ type: SET_OPTION, option });
    yield put({ type: SETUP_METRO, settings, active_metro, neighborhoods, steps, partner_steps });
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error('Error', `Oops. Something went wrong trying update profile! Please contact ${supportEmail} if this error persists. ERROR CODE: CP01`);
  }
}

function* subscribe({ params, resolve, reject }) {
  try {
    const finalParams = {
      ...params,
      experiences: params.experiences.flat().map(e => e.name).filter(e => e),
    };
    const metroId = yield select(selectMetroId);
    const { name: plan_name } = yield select(selectOption);
    const payload = yield call(postSubscribe, { ...finalParams, plan_name }, metroId);
    resolve(payload);
  } catch (e) {
    reject(e);
  }
}

function* smsOptin({ params, resolve, reject }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const payload = yield call(postOptin, params, customerToken);
    resolve(payload);
  } catch (e) {
    reject(e);
  }
}

function* saveCustomerProfile({ params, selectedNeighborhoods }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    const isNew = yield select(selectIsNew);
    const payload = yield call(postSave, params, customerToken, selectedNeighborhoods);
    const { customer, profile } = payload.data;
    yield put({ type: SET_CUSTOMER_AND_PROFILE, profile, customer });
    toastr.success('Updated!', (isNew ? 'Your preferences are saved, we\'ll be in touch soon with info on your first tickets. Feel free to close out this tab and go about your business. Thanks!' : 'Your preferences are saved. Feel free to close out this tab and go about your business. Thanks!'));
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error('Error', `Oops. Something went wrong trying update profile! Please contact ${supportEmail} if this error persists. ERROR CODE: PR01`);
  }
}

function* setupCustomerProfile({ params, selectedNeighborhoods, id, resolve, reject }) {
  try {
    const payload = yield call(postSetup, params, selectedNeighborhoods, id);
    resolve(payload);
  } catch (e) {
    reject(e);
    const supportEmail = yield select(selectSupportEmail);
    toastr.error('Error', `Oops. Something went wrong trying update profile! Please contact ${supportEmail} if this error persists. ERROR CODE: PRS01`);
  }
}

function* spotifyImport({ bands }) {
  try {
    const customerToken = yield select(selectCustomerToken);
    yield call(postImport, bands.items.map(i => ({ name: i.name, genres: i.genres })), customerToken);
  } catch (e) {
    const supportEmail = yield select(selectSupportEmail);
    toastr.error('Error', `Oops. Something went wrong! Please contact ${supportEmail} if this error persists. ERROR CODE: SI01`);
  }
}

const requestCreateBundle = (customer_token, ticket_dispatch_id, partner_name, optin) => {
  const optinPart = optin ? 'yes' : 'no';
  const params = {
    customer_token,
    ticket_dispatch_id,
    partner_name,
    optin: optinPart,
  };
  const request = axios.post('/crb', { crb: params }).then(res => res);
  return request;
};

const postOptin = (params, customer_token) => {
  const optinUrl = `/optin/${customer_token}`;
  const request = axios.post(optinUrl, { optin: params }).then(res => res);
  return request;
};

const storeLead = (params) => {
  const leadUrl = '/t';
  const request = axios.post(leadUrl, { lead: params }).then(res => res);
  return request;
};

const requestPickCity = (metro_id, option_name) => {
  const retrieveUrl = `/pc/${metro_id}/${option_name}`;
  const request = axios.get(retrieveUrl).then(res => res);
  return request;
};

const postSave = (params, customerToken, selectedNeighborhoods) => {
  const updatePerkGiveawayUrl = '/profile/save';
  const request = axios.post(updatePerkGiveawayUrl, {
    customer_token: customerToken,
    member: memberSavePayload(params, selectedNeighborhoods),
  }).then(res => res);
  return request;
};

const postSaveLite = (params, customerToken) => {
  const updatePerkGiveawayUrl = '/profile/save';
  const request = axios.post(updatePerkGiveawayUrl, {
    customer_token: customerToken,
    member: params,
  }).then(res => res);
  return request;
};

const postImport = (bands, customerToken) => {
  const updatePerkGiveawayUrl = '/profile/spotify_import';
  const request = axios.post(updatePerkGiveawayUrl, {
    customer_token: customerToken,
    bands,
  }).then(res => res);
  return request;
};

const postSetup = (params, neighborhoods, id) => {
  const setupCustomerUrl = '/checkout/setup';
  const request = axios.post(setupCustomerUrl, {
    setup: setupPayload(params, neighborhoods),
    id,
  }).then(res => res);
  return request;
};

const postSubscribe = (params, metroId) => {
  const subscribeUrl = `/subscribe/${metroId}`;
  const request = axios.post(subscribeUrl, { subscribe: params }).then(res => res);
  return request;
};
