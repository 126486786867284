import React, { useState } from 'react';
import { upperCase } from 'lodash';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailModal from './EmailModal';

const Card = ({ gift }) => {
  const [isModalOpen, toggleModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const copiedMessage = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="grid-x grid-margin-x ds-offer">
      <div className="medium-6 large-6" style={{ textAlign: 'center' }}>
        <div className='gift-card'>
          <div className='gift-card-text'>{upperCase(gift.code)}</div>
        </div>
      </div>
      <div className="medium-6 large-6 gift-card-buttons">
        {gift.status === 'redeemed' && <><p>Redeemed at {moment(gift.redeemed_at).format('MMM Do YYYY')} by {gift.redeemer.email}</p></>}
        {gift.status === 'active' &&
          <>
            <CopyToClipboard
              text={`https://domore.dostuffmedia.com/redeem?code=${gift.code.toUpperCase()}`}
              onCopy={() => copiedMessage()}
            >
              <button type='button'>{!copied ? 'Copy Redeem Link' : 'Redeem link copied to clipboard'}</button>
            </CopyToClipboard>
          </>
        }
      </div>
      <EmailModal isModalOpen={isModalOpen} closeModal={toggleModalOpen} />
    </div>
  );
};

export default Card;
