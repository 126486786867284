export const EVENT_MESSAGES = {
  new_member: 'Thanks for subscribing!',
};
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const PHONE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const SET_CUSTOMER_AND_PROFILE = 'SET_CUSTOMER_AND_PROFILE';
export const SAVE_CUSTOMER_PROFILE = 'SAVE_CUSTOMER_PROFILE';
export const SPOTIFY_IMPORT = 'SPOTIFY_IMPORT';
export const SPOTIFY_IMPORT_FINISHED = 'SPOTIFY_IMPORT_FINISHED';
export const SETUP_CUSTOMER = 'SETUP_CUSTOMER';
export const SUBSCRIBE = 'SUBSCRIBE';
export const PICK_CITY = 'PICK_CITY';
export const SET_OPTION = 'SET_OPTION';
export const TOGGLE_LAST_MINUTE_ALLOWED = 'TOGGLE_LAST_MINUTE_ALLOWED';
export const SMS_OPTIN = 'SMS_OPTIN';
export const CREATE_BUNDLE = 'CREATE_BUNDLE';
export const SAVE_CUSTOMER_PROFILE_LITE = 'SAVE_CUSTOMER_PROFILE_LITE';
export const SET_SMS_OPTIN = 'SET_SMS_OPTIN';

export const TRACK_LEAD = 'TRACK_LEAD';
export const STORE_LEAD = 'STORE_LEAD';

export const GENRE_MAPPING = [
  { label: 'Blues / Gospel', genres: ['blues', 'gospel'] },
  { label: 'Classical', genres: ['classical'] },
  { label: 'Country / Bluegrass', genres: ['country', 'bluegrass'] },
  { label: 'EDM / House / Techno', genres: ['edm', 'house', 'techno'] },
  {
    label: 'Folk / Singer-Songwriter / Americana',
    genres: ['folk', 'singer-songwriter', 'new americana'],
  },
  { label: 'Hip Hop / Rap', genres: ['hip hop', 'rap'] },
  {
    label: 'Indie Rock / Alternative',
    genres: ['indie rock', 'alternative rock'],
  },
  { label: 'Jam', genres: ['jam'] },
  { label: 'Jazz', genres: ['jazz'] },
  { label: 'Latin / Reggaeton', genres: ['latin', 'reggaeton'] },
  { label: 'Metal / Post-Hardcore', genres: ['metal', 'post-hardcore'] },
  { label: 'Pop', genres: ['pop'] },
  { label: 'Punk / Emo', genres: ['punk', 'emo'] },
  {
    label: 'Rock and Roll / Classic Rock',
    genres: ['rock and roll', 'classic rock'],
  },
  { label: 'R&B / Soul', genres: ['r&b', 'soul'] },
  {
    label: 'Synthpop / Alternative Dance',
    genres: ['synthpop', 'alternative dance'],
  },
  { label: 'World', genres: ['world'] },
];

export const DAYS_OF_WEEK = ['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// This is here because we need to be able to map  potential city names
// coming from referrals with the city param
export const cityMapping = {
  austin: {
    metro: 'DoStuff',
    metro_list: 'Do312, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoMoreAustin.com',
    cityname: 'Austin',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoMORE Austin',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74535/DoMORE_Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74532/DoMORE-White.png',
  },
  vancouver: {
    cityurl: 'vancouver',
    metro: 'Do604',
    metro_list: 'Do604, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do604.com',
    cityname: 'Vancouver',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do604 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/78452/Do604More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/78451/Do604More-Horizontal-White.png',
  },
  philadelphia: {
    metro: 'Do215',
    metro_list: 'Do215, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'do215.com',
    cityname: 'Philadelphia',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoMORE Austin',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74535/DoMORE_Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74532/DoMORE-White.png',
  },
  philly: {
    metro: 'Do215',
    metro_list: 'Do215, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'do215.com',
    cityname: 'Philadelphia',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoMORE Austin',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74535/DoMORE_Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74532/DoMORE-White.png',
  },
  boston: {
    metro: 'Do617',
    metro_list: 'Do617, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do617.com',
    cityname: 'Boston',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do617 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74543/Do617More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74392/Do617More-Horizontal-White.png',
  },
  'the-bay-area': {
    metro: 'DoTheBay',
    metro_list: 'DoTheBay, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoTheBay.com',
    cityname: 'The Bay Area',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoTheBay MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75222/DTBMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75221/DTBMore-Horizontal-White.png',
  },
  'bay-area': {
    metro: 'DoTheBay',
    metro_list: 'DoTheBay, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoTheBay.com',
    cityname: 'The Bay Area',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoTheBay MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75222/DTBMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75221/DTBMore-Horizontal-White.png',
  },
  bay: {
    metro: 'DoTheBay',
    metro_list: 'DoTheBay, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoTheBay.com',
    cityname: 'The Bay Area',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoTheBay MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75222/DTBMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75221/DTBMore-Horizontal-White.png',
  },
  sf: {
    metro: 'DoTheBay',
    metro_list: 'DoTheBay, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoTheBay.com',
    cityname: 'The Bay Area',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoTheBay MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75222/DTBMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75221/DTBMore-Horizontal-White.png',
  },
  'san-francisco': {
    metro: 'DoTheBay',
    metro_list: 'DoTheBay, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoTheBay.com',
    cityname: 'The Bay Area',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoTheBay MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75222/DTBMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/75221/DTBMore-Horizontal-White.png',
  },
  chicago: {
    metro: 'Do312',
    metro_list: 'Do312, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do312.com',
    cityname: 'Chicago',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do312 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74540/Do312More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74397/Do312More-Horizontal-White.png',
  },
  'dallas-fort-worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'dallas%20fort%20worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'dallas%20%2F%20ft.%20worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'dallas / ft. worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'dallas%20%2F%20fort%20worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'dallas fort worth': {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  dallas: {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  dfw: {
    metro: 'Do214',
    metro_list: 'Do214, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do214.com',
    cityname: 'Dallas / Fort Worth',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do214 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74538/Do214More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74395/Do214More-Horizontal-White.png',
  },
  'denver & boulder': {
    metro: 'Do303',
    metro_list: 'Do303, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do303.com',
    cityname: 'Denver',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do303 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74539/Do303More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74396/Do303More-Horizontal-White.png',
  },
  'denver%20&%20boulder': {
    metro: 'Do303',
    metro_list: 'Do303, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do303.com',
    cityname: 'Denver',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do303 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74539/Do303More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74396/Do303More-Horizontal-White.png',
  },
  denver: {
    metro: 'Do303',
    metro_list: 'Do303, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do303.com',
    cityname: 'Denver',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do303 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74539/Do303More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74396/Do303More-Horizontal-White.png',
  },
  indianapolis: {
    metro: 'Do317',
    metro_list: 'Do317, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do317.com',
    cityname: 'Indianapolis',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do317 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74548/Do317More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74407/Do317More-Horizontal-white-300.png',
  },
  'los-angeles': {
    metro: 'DoLA',
    metro_list: 'DoLA, Do312, DoNYC and 17 others',
    plan_name: 'monthly',
    domain: 'DoLA.com',
    cityname: 'Los Angeles',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoLA MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74544/DoLAMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74400/DoLAMore-Horizontal-White.png',
  },
  'los%20angeles': {
    metro: 'DoLA',
    metro_list: 'DoLA, Do312, DoNYC and 17 others',
    plan_name: 'monthly',
    domain: 'DoLA.com',
    cityname: 'Los Angeles',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoLA MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74544/DoLAMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74400/DoLAMore-Horizontal-White.png',
  },
  'los angeles': {
    metro: 'DoLA',
    metro_list: 'DoLA, Do312, DoNYC and 17 others',
    plan_name: 'monthly',
    domain: 'DoLA.com',
    cityname: 'Los Angeles',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoLA MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74544/DoLAMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74400/DoLAMore-Horizontal-White.png',
  },
  la: {
    metro: 'DoLA',
    metro_list: 'DoLA, Do312, DoNYC and 17 others',
    plan_name: 'monthly',
    domain: 'DoLA.com',
    cityname: 'Los Angeles',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoLA MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74544/DoLAMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74400/DoLAMore-Horizontal-White.png',
  },
  louisville: {
    metro: 'Do502',
    metro_list: 'Do502, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do502.com',
    cityname: 'Louisville',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do502 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74541/Do502More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74398/Do502More-Horizontal-White.png',
  },
  nashville: {
    metro: 'Do615',
    metro_list: 'Do615, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do615.com',
    cityname: 'Nashville',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do615 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74542/Do615More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74399/Do615More-Horizontal-White.png',
  },
  'new-york-city': {
    metro: 'DoNYC',
    metro_list: 'DoNYC, Do312, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoNYC.com',
    cityname: 'New York City',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoNYC MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74545/DoNYCMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74401/DoNYCMore-Horizontal-White.png',
  },
  'new york city': {
    metro: 'DoNYC',
    metro_list: 'DoNYC, Do312, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoNYC.com',
    cityname: 'New York City',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoNYC MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74545/DoNYCMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74401/DoNYCMore-Horizontal-White.png',
  },
  'new%20york%20city': {
    metro: 'DoNYC',
    metro_list: 'DoNYC, Do312, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoNYC.com',
    cityname: 'New York City',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoNYC MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74545/DoNYCMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74401/DoNYCMore-Horizontal-White.png',
  },
  nyc: {
    metro: 'DoNYC',
    metro_list: 'DoNYC, Do312, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoNYC.com',
    cityname: 'New York City',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoNYC MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74545/DoNYCMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74401/DoNYCMore-Horizontal-White.png',
  },
  houston: {
    metro: 'Do713',
    metro_list: 'Do713, Do312, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do713.com',
    cityname: 'Houston',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do713 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74545/DoNYCMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/77529/Do713-MORE-Logo-White.png',
  },
  portland: {
    metro: 'DoPDX',
    metro_list: 'DoPDX, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoPDX.com',
    cityname: 'Portland',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoPDX MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74546/DoPDXMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74402/DoPDXMore-Horizontal-White.png',
  },
  'san-antonio': {
    metro: 'Do210',
    metro_list: 'Do210, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do210.com',
    cityname: 'San Antonio',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do210 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74537/Do210More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74394/Do210More-Horizontal-White.png',
  },
  'san%20antonio': {
    metro: 'Do210',
    metro_list: 'Do210, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do210.com',
    cityname: 'San Antonio',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do210 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74537/Do210More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74394/Do210More-Horizontal-White.png',
  },
  'san antonio': {
    metro: 'Do210',
    metro_list: 'Do210, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do210.com',
    cityname: 'San Antonio',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do210 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74537/Do210More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74394/Do210More-Horizontal-White.png',
  },
  'san-diego': {
    metro: 'DoSD',
    metro_list: 'DoSD, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoSD.com',
    cityname: 'San Diego',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoSD MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74547/DoSDMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74403/DoSDMore-Horizontal-White.png',
  },
  'san%20diego': {
    metro: 'DoSD',
    metro_list: 'DoSD, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoSD.com',
    cityname: 'San Diego',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoSD MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74547/DoSDMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74403/DoSDMore-Horizontal-White.png',
  },
  'san diego': {
    metro: 'DoSD',
    metro_list: 'DoSD, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'DoSD.com',
    cityname: 'San Diego',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'DoSD MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74547/DoSDMore-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74403/DoSDMore-Horizontal-White.png',
  },
  seattle: {
    metro: 'Do206',
    metro_list: 'Do206, DoNYC, DoLA and 17 others',
    plan_name: 'monthly',
    domain: 'Do206.com',
    cityname: 'Seattle',
    disclaimer: 'Cancel anytime.',
    early_access_description: '',
    early_access_headline: 'You Know You Want to Do More',
    early_access_tag: 'FLASH SALE',
    button_one: 'SIGN UP',
    button_two: 'MATCH ME WITH TICKETS',
    button_three: 'BUILD MY PROFILE',
    button_four: 'I LOVE MEGAN. SIGN ME UP',
    button_five: 'GET STARTED',
    doxyzmore: 'Do206 MORE',
    logo_black:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74536/Do206More-Horizontal-Black.png',
    logo_white:
      'https://dostuff-assets.s3.amazonaws.com/property_asset/74393/Do206More-Horizontal-White.png',
  },
};
