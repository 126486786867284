import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { cancelSubscription } from './actions';
import { selectCustomerEmail } from '../Profile/selectors';

const Billing = () => {
  const dispatch = useDispatch();
  const [cancelling, setCancelling] = useState(false);
  const email = useSelector(selectCustomerEmail);

  const cancel = () => {
    setCancelling(true);
    new Promise((resolve, reject) =>
      dispatch(cancelSubscription(resolve, reject))
    ).then(() => {
      toastr.success('Success', 'Subscription cancelled');
      setTimeout(() => {
        location.reload();
      }, 2000);
    });
  };

  return (
    <div className="cell small-12 large-6 billing-info">
      <div className="card">
        <div className="card-divider">
          <h4>Billing Information</h4>
        </div>
        <div className="card-section">
          <p>
            <button
              disabled={cancelling}
              type="button"
              onClick={() => cancel()}
            >
              {cancelling ? 'Canceling Subscription, Please wait...' : 'Cancel Subscription'}
            </button>
          </p>
          <hr />
          <p>
            <button
              onClick={() =>
                window.open(
                  'https://billing.stripe.com/p/login/8wM8xz1UWa0W9UsaEE',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              Manage Account
            </button>
          </p>
          <p style={{ fontSize: 16 }}>
            Use email address <em>{email}</em>
          </p>
          <p>
            For any questions email us at{' '}
            <a
              href="mailto:domore@dostuffmedia.com"
              target="_blank"
              rel="noreferrer"
            >
              domore@dostuffmedia.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Billing;
