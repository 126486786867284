import React, { useEffect } from 'react';

const Faq = () => {
  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'FAQ',
    });
  }, []);

  return (
    <div className='grid-x grid-padding-x grid-padding-y profile-container'>
      <div className='small-12 cell float-center ds-container'>
        <h1 className='profile-title' style={{ fontSize: '64px' }}>
          FAQ
        </h1>
        <section>
          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            What sort of events should I expect?
          </h4>
          <p>
            Experiences range from concerts, festivals, pop-ups, museums,
            sports, movie premieres, arts + culture — basically anything that’s
            fun or cool in your city. Plus, we always give you two tickets to
            each event, allowing you to bring a guest along with you!
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            When do the events take place? Will I have time to plan?
          </h4>
          <p>
            Events could be as far as 45 days out — or just a few days out! Some
            members prefer to plan, others like to jump on last-minute ticket
            options as they pop up. You can pick what works for you.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            When do I get to select my tickets?
          </h4>
          <p>
            You’ll get an alert for Ticket Drops at least once within any 30-day
            window. The exact timing during that window is a surprise! During
            busy seasons you’ll get more alerts. We’ll also give you more
            opportunities to select tickets if none of the initial options work
            for you the first time.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            How does it work? How do I actually get tickets?
          </h4>
          <p>
            You will receive a text or email alert with your unique “Ticket
            Drop.” A Ticket Drop is a selection of 3-7 ticket offers, tailored
            specific to you, happening in your area within the next 45 days. You
            can claim or transfer any one selection of those offers. If there’s
            nothing that works for you, you can simply click the button to
            receive another Drop in 7 days.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            How does DOMORE get tickets?
          </h4>
          <p>
            We’re able to get tickets to events because we have a super tight
            team and a ton of local venue and promoter partners that we work
            with on a regular basis to help promote their shows. Our partners
            are looking to us to introduce them to fun, cool people who are
            (probably) going to spend money at the venue. And Artists are
            constantly looking to build new fan-bases in every city they visit.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            How do I cancel?
          </h4>
          <p>
            We heard from members that cancellation was confusing. We’ve updated
            it to make it as easy as possible. Everyone can cancel their
            membership at any time through their member profile — or by emailing
            us at{' '}
            <a
              href='mailto:domore@dostuffmedia.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              domore@dostuffmedia.com
            </a>
            . Our full cancellation policy can be found{' '}
            <a
              href='https://dostuffmedia.com/domore/cancelation-policy'
              target='_blank'
              rel='noopener noreferrer'
            >
              here
            </a>
            . If we ever drop the ball on a cancellation we will refund your
            transactions. Helpful note: it makes it easier if you email us from
            the email associated with your DoMORE account.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            Can I only claim tickets once a month?
          </h4>
          <p>
            No! You can claim tickets whenever you get a Ticket Drop alert. If
            you get 3 alerts in a single month — good for you! You can claim
            tickets to three different events.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            Does the event always take place in the same month tickets are sent?
          </h4>
          <p>
            Nope! Tickets can be for events happening a month or more in the
            future.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            How do I get my tickets?
          </h4>
          <p>
            Our confirmation texts and emails will let you know the specific
            details for each event. Some tickets are emailed to you, while other
            times you’ll go to the box office, show your valid photo ID, and
            your name will be on the guestlist.{' '}
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            What if none of the event choices work for me?
          </h4>
          <p>
            One of the coolest parts of DoMORE is you can transfer your tickets
            to a friend. Or give us a week and we’ll drum up some other options.{' '}
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            How can I get extra entries to giveaways?
          </h4>
          <p>
            Through your member profile head to the account tab and click
            “connect account.” Sign into your DoXYZ site account, and entries
            are automatically applied!
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            Is there a deadline to transfer?
          </h4>
          <p>
            Yes, you can transfer tickets to most events up until 10am the day
            before the event!
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            Can I purchase additional tickets?
          </h4>
          <p>
            Of course! You can click on the event details on your claimed event
            that takes you to the event page, from there just click the “buy
            tickets” option and purchase the additional tickets! You can also
            purchase additional tickets at the box office when you arrive to
            pick up your claimed pair.
          </p>

          <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
            I’m getting E-tickets, what now?
          </h4>
          <p>
            E-tickets are normally sent within 24 hours of your event, but you
            should have them by the time doors open! Your confirmation email
            will let you know what email to look for AND we’ll send you a
            message once all tickets have been sent.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Faq;
