import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import BasicInfo from './BasicInfo';
import GeneralPreferences from './GeneralPreferences';
import MusicPreferences from './MusicPreferences';
import Misc from './Misc';
import { selectInitialProfileForm } from './selectors';
import {
  selectCityName,
  selectNeighborhoodGeoJson,
} from '../../helpers/metro/selectors';
import { saveCustomerProfile } from './actions';
import EventMessage from './EventMessage';
import { EMAIL_REGEX } from './constants';

const Profile = ({ cableCarRoute }) => {
  const { event } = useParams();
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const initialProfileForm = useSelector(selectInitialProfileForm);
  const cityName = useSelector(selectCityName);
  const baseNeighborhoods = useSelector(selectNeighborhoodGeoJson);
  const { handleSubmit, control, register, setValue, watch, reset, errors } =
    useForm({
      reValidateMode: 'onSubmit',
      defaultValues: {
        ...initialProfileForm,
      },
    });

  useEffect(() => reset(initialProfileForm), [initialProfileForm]);

  useEffect(() => {
    window.gtag('event', 'screen_view', {
      app_name: 'member_portal',
      screen_name: 'Profile',
    });
  }, []);

  const onSubmit = (params) => {
    if (params.email) {
      params.email.match(EMAIL_REGEX);
    }
    dispatch(saveCustomerProfile(params));
  };
  return (
    <div className='grid-x grid-padding-x grid-padding-y profile-container'>
      <div className='small-12 cell float-center ds-container'>
        <h1 className='profile-title' style={{ fontSize: '64px' }}>
          Tell us about yourself
        </h1>
        <EventMessage event={event} />
      </div>
      <div className='small-12 cell float-center ds-container'>
        <form onSubmit={handleSubmit(onSubmit)} className='form-profile'>
          <BasicInfo
            control={control}
            register={register}
            cityName={cityName}
            errors={errors}
          />
          <GeneralPreferences
            mapRef={mapRef}
            control={control}
            register={register}
            neighborhoods={baseNeighborhoods}
          />
          <MusicPreferences
            cableCarRoute={cableCarRoute}
            control={control}
            register={register}
            setValue={setValue}
            watch={watch}
          />
          <Misc cityName={cityName} register={register} />
          <div className='grid-x grid-padding-x form-profile-submit'>
            <div className='small-12 large-3 cell float-center'>
              <button type='submit'>Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
