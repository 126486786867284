import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInterval } from '../../hooks/useInterval';
import { checkSession, claim } from './actions';
import { SESSION_CHECK_INTERVAL } from './constants';
import Perk from './Perk';

const Confirmation = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('Your account is being setup!');
  const [delay, setDelay] = useState(SESSION_CHECK_INTERVAL);
  const [perks, setPerks] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [claimed, setClaimed] = useState(false);
  const [customerToken, setCustomerToken] = useState('');

  const params = useParams();
  const sessionId = params.sessionId;

  const claimPerk = (token, setHasClaimed) => {
    new Promise((resolve, reject) => {
      dispatch(
        claim(customerToken, token, sessionId, 'register', resolve, reject)
      );
    }).then(() => {
      setPerks(perks.filter((p) => p.token === token));
      setClaimed(true);
      setHasClaimed(true);
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 5000);
    });
  };

  useInterval(() => {
    new Promise((resolve, reject) => {
      dispatch(checkSession(sessionId, 'confirmation', resolve, reject));
    }).then((data) => {
      const {
        status_message,
        track,
        customer: { email },
        order_id: orderId,
        tracking,
      } = data;
      const product = tracking.transactionProducts[0];
      if (track) {
        window.gtag('event', 'purchase', {
          transaction_id: `${tracking.transactionId}`,
          value: tracking.transactionTotal,
          tax: 0,
          shipping: 0,
          currency: 'USD',
          items: [
            {
              item_id: product.sku,
              item_name: `${product.name} - ${product.variation}`,
              affiliation: 'DoMORE',
              discount: 0,
              index: 0,
              item_brand: product.name,
              item_category: product.category,
              price: product.price,
              quantity: product.quantity,
            },
          ],
        });
        window.ScarabQueue.push(['setEmail', email]);
        window.ScarabQueue.push(['cart', []]);
        window.ScarabQueue.push([
          'purchase',
          {
            orderId,
            items: [{ item: '001', price: 5, quantity: 1 }],
          },
        ]);
        window.ScarabQueue.push(['go']);
      }
      if (!data.perks) {
        setMessage(status_message);
        window.location.replace(data.url);
      } else {
        setPerks(data.perks);
        setRedirectUrl(data.url);
        setCustomerToken(data.customer_token);
        setDelay(null);
      }
    });
  }, delay);

  return (
    <div className="grid-x grid-padding-x grid-padding-y perks">
      <div className="small-12 cell float-center ds-container">
        {perks.length === 0 && (
          <>
            <div style={{ marginBottom: '35px' }} className="loader">
              Loading...
            </div>
            <p style={{ textAlign: 'center' }}>{message}</p>
          </>
        )}
        {claimed && (
          <p style={{ textAlign: 'center' }}>
            Thanks for claiming! You will be redirected to your profile!
          </p>
        )}
        {perks.map((perk) => (
          <Perk
            key={`perk-claim-${perk.token}`}
            perk={perk}
            claimed={claimed}
            claimPerk={claimPerk}
          />
        ))}
      </div>
    </div>
  );
};

export default Confirmation;
