import React from 'react';
// import Perk from '../../Confirmation/Perk';
import PerkGiveaway from '../../PerkGiveaways/PerkGiveaway';

const filterPerks = (perks, picking, selectedPerk, picked) => {
  if (picked) {
    return perks.filter((p) => selectedPerk.token === p.token);
  }
  return perks;
};

const PickTickets = ({
  picked,
  perks,
  claimPerk,
  togglePerk,
  selectedPerk,
  picking,
  partner_token,
}) => (
  <>
    <h4 className='required has-helper-text' style={{ fontSize: '28px' }}>
      {picked ? 'Ticket claimed!' : 'Pick Tickets'}
    </h4>
    <p style={{ color: '#CF0034' }}>
      Click on a ticket to select, then press CLAIM
    </p>
    {perks && perks.length === 0 && (
      <p>
        Uh oh. Looks like we ran out of tickets that match your profile. Never
        fear t'o'gh - we'll soon be emailing you another pair. Or, you can email
        us at{' '}
        <a
          href='mailto:domore@dostuffmedia.com'
          target='_blank'
          rel='noreferrer'
        >
          dom'r'@dostuffmedia.com
        </a>{' '}
        and we'll try and find you something sooner!
      </p>
    )}
    {filterPerks(perks, picking, selectedPerk, picked).map((perk) => (
      <PerkGiveaway
        actionResolve={claimPerk}
        key={`perk-${perk.id}`}
        perk={perk}
        perks={perks}
        picking={picking}
        selectedPerk={selectedPerk}
        picked={picked}
        screen='pick_tickets'
        togglePerk={togglePerk}
      />
    ))}
    {perks && perks.length > 0 && !selectedPerk && !partner_token && (
      <p>
        Don't like any of these? No problem, just click next to fill out the
        rest of your profile and we'll send you an email soon with a great pair
        of tix
      </p>
    )}
  </>
);

export default PickTickets;
