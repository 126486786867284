import React, { useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSiteName, selectCityName } from '../../helpers/metro/selectors';
import Login from './Login/Login';
import Register from './Register/Register';

const Auth = ({ isOpen, switchUser, onClose, connectAccount }) => {
  const siteName = useSelector(selectSiteName);
  const cityName = useSelector(selectCityName);
  const [display, setDisplay] = useState('login');

  return (
    <Modal
      isOpen={isOpen}
      className='members-modal'
    >
      <div className="auth-modal">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="small-12 medium-7 cell ds-auth-modal float-center">
            <div className={classNames('confirmation-body', switchUser && 'has-close-button')}>
              {switchUser && <span role='button' className='close-button' onClick={() => onClose(false)}>x</span>}
              <h3 className='float-center' style={{ textAlign: 'center' }}>
                {switchUser && !connectAccount && `Change ${siteName} account`}
                {switchUser && connectAccount && `Connect ${siteName} account`}
              </h3>
              <p style={{ textAlign: 'center' }}>
                {switchUser && !connectAccount && `Change the ${siteName} account connected to your DoMORE ${cityName} subscription`}
                {switchUser && connectAccount && `Connect your DoMORE ${cityName} subscription to your ${siteName} account.`}
              </p>
              {display === 'login' ? <Login onClose={onClose} switchUser={switchUser} setDisplay={setDisplay} /> : <Register onClose={onClose} switchUser={switchUser} setDisplay={setDisplay} />}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Auth;
