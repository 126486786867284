import React from 'react';
import { useSelector } from 'react-redux';
import Handlebars from 'handlebars';
import moment from 'moment';
import { 
  selectProfileFullName,
  selectProfileFirstName,
  selectProfileLastName,
  selectCustomerToken,
  selectCustomerEmail,
} from '../components/Profile/selectors';
import {
  selectMetroSettings,
  selectSiteDomain,
} from './metro/selectors';

const Injector = ({ body, perkGiveaway, overrides }) => {
  let perkGiveawayVars = {};
  if (perkGiveaway) {
    perkGiveawayVars = {
      event_url: `https://${useSelector(selectSiteDomain)}/events/${perkGiveaway.perk.event.id}`,
      event_title: perkGiveaway.perk.event.title,
      venue_title: perkGiveaway.perk.event.venue.title,
      venue_url: `https://${useSelector(selectSiteDomain)}/venues/${perkGiveaway.perk.event.venue.id}`,
      perk_title: perkGiveaway.perk.name,
      event_date: moment(perkGiveaway.perk.event.begin_time).format('H:ma on ddd, MMM D'),
      claim_by_date: moment(perkGiveaway.claim_by).format('H:ma on ddd, MMM D'),
      offer_expires_message: `<span style='color:#ce0037'><em>**this offer expires at ${moment(perkGiveaway.claim_by).format('H:ma on ddd, MMM D')}**</em></span>`,
      current_month: moment().format('MMMM'),
      perk_month: moment(perkGiveaway.perk.available_period).format('MMMM'),
    };
  }

  const settings = useSelector(selectMetroSettings);

  const variables = {
    customer_full_name: useSelector(selectProfileFullName),
    customer_first_name: useSelector(selectProfileFirstName),
    customer_last_name: useSelector(selectProfileLastName),
    customer_email: useSelector(selectCustomerEmail),
    customer_token: useSelector(selectCustomerToken),
    '3_months_from_now': moment().add(3, 'months').format('MMMM D, YYYY'),
    '2_months_from_now': moment().add(2, 'months').format('MMMM D, YYYY'),
    '1_month_from_now': moment().add(1, 'months').format('MMMM D, YYYY'),
    ...settings,
    ...overrides,
    ...perkGiveawayVars,
  };

  const template = Handlebars.compile(body.replaceAll('<p><br></p>', ''));
  return <div dangerouslySetInnerHTML={{ __html: template(variables) }} />;
};

export default Injector;
