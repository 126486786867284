import { takeEvery, call, put, select } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { SEARCH_REQUEST, SET_SEARCH_RESULTS } from './constants';
import { search } from './api';
import { selectMetroId } from '../metro/selectors';

export default function* watcherSaga() {
  yield takeEvery(SEARCH_REQUEST, fetchResults);
}

function* fetchResults(action) {
  const metroId = yield select(selectMetroId);
  const { term, entity } = action;
  try {
    const results = yield call(search, `${entity}s`, term, metroId);
    yield put({ type: SET_SEARCH_RESULTS, results: results.data.hits.hits });
  } catch (e) {
    toastr.error('Unable to search', '', {
      timeOut: 5000,
    });
  }
}
