import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Errors from './Errors';
import { EMAIL_REGEX } from '../Profile/constants';

const BasicInfo = ({ register, control, errors }) => (
  <section>
    <Errors errors={errors} />
    <fieldset className='form-row no-bg user-info'>
      <label htmlFor='first_name'>
        <span>First Name</span>
        <input
          name='first_name'
          ref={register({ required: 'First name is required' })}
          placeholder='Enter your first name'
        />
      </label>
      <label htmlFor='last_name'>
        <span>Last Name</span>
        <input
          name='last_name'
          ref={register({ required: 'Last name is required' })}
          placeholder='Enter your last name'
        />
      </label>
      <label htmlFor='email'>
        <span>Email</span>
        <input
          name='email'
          ref={register({
            required: 'Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
          placeholder='Enter your email'
        />
      </label>
      <label htmlFor='date_of_birth'>
        <span>Birthdate</span>
        <Controller
          as={InputMask}
          control={control}
          mask='99/99/9999'
          name='date_of_birth'
          placeholder='MM/DD/YYY'
          maskPlaceholder={null}
          rules={{ required: 'Date of birth is required' }}
        />
      </label>
      <label htmlFor='phone_number'>
        <span>Phone Number</span>
        <Controller
          as={InputMask}
          control={control}
          mask='999-999-9999'
          name='phone_number'
          placeholder='312-555-4242'
          maskPlaceholder={null}
          type='tel'
          rules={{
            required: 'Phone number is required',
          }}
        />
      </label>
    </fieldset>
  </section>
);

export default BasicInfo;
