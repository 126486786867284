import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectExperiences,
  selectNeighborhoodGeoJson,
} from '../../helpers/metro/selectors';
import CheckboxGroup from '../../helpers/form/CheckboxGroup';

const GeneralPreferences = ({ control, register }) => {
  const experiences = useSelector(selectExperiences);
  const neighborhoods = useSelector(selectNeighborhoodGeoJson);

  return (
    <>
      <h2 className="sub-header">Event Preferences</h2>
      <section>
        <CheckboxGroup
          items={['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
          fieldName='days'
          register={register}
          control={control}
          questionText='What nights do you prefer to go out?'
        />
      </section>
      <section>
        <CheckboxGroup
          items={experiences}
          fieldName='experiences'
          register={register}
          control={control}
          columns
          questionText='Which of the following types of experiences are you interested in?'
        />
      </section>
      <section>
        <CheckboxGroup
          items={neighborhoods}
          fieldName='neighborhoods'
          register={register}
          control={control}
          columns
          questionText='Which of the following neighborhoods are you interested in?'
        />
      </section>
    </>
  );
};

export default GeneralPreferences;
