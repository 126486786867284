import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { get } from 'lodash';
import { useInterval } from '../../hooks/useInterval';
import { checkSession } from '../Confirmation/actions';
import { SESSION_CHECK_INTERVAL } from '../Confirmation/constants';
import Card from './Card';

const confirmationMessage = (confirmation, customer) => {
  if (confirmation === null || confirmation === '') {
    return '';
  }
  const vars = confirmation.match(/{{(([^}][^}]?|[^}]}?)*)}}/g);
  if (vars === null) {
    return confirmation;
  }
  let message = confirmation;
  vars.forEach((v) => {
    const vr = v.replace('{{', '').replace('}}', '');
    const val = get(customer, vr.split('.')[1]);
    message = message.replace(`{{${vr}}}`, val);
  });
  return message;
};

const Gifts = () => {
  const dispatch = useDispatch();

  const [delay, setDelay] = useState(SESSION_CHECK_INTERVAL);
  const [gs, setGs] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const sendDataToGTM = useGTMDispatch();

  const params = useParams();
  const sessionId = params.sessionId;

  useInterval(() => {
    new Promise((resolve, reject) => {
      dispatch(checkSession(sessionId, 'gifts', resolve, reject));
    }).then(payload => {
      if (payload.status === 'paid' && payload.gifts && payload.gifts.length > 0) {
        if (payload.track) {
          sendDataToGTM(payload.tracking);
        }
        setDelay(null);
        setGs(payload.gifts);
        setCustomer(payload.customer);
        setConfirmation(payload.confirmation);
      }
    });
  }, delay);

  return (
    <div className="grid-x grid-padding-x grid-padding-y perks">
      <div className="small-12 cell float-center ds-container">
        {gs === null &&
        <>
          <div style={{ marginBottom: '35px' }} className="loader">Loading...</div>
          <p style={{ textAlign: 'center' }}>Wrapping your gifts!</p>
        </>
        }
        {gs &&
          <>
            <p style={{ textAlign: 'center' }}>{confirmationMessage(confirmation, customer)}</p>
            {gs.map(gift => <Card key={`gift-${gift.code}`} gift={gift} />)}
          </>
        }
      </div>
    </div>
  );
};

export default Gifts;
