import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { selectCustomerToken } from '../Profile/selectors';

const BundleModal = ({
  isModalOpen,
  setModalOpen,
  ticketRequest,
  token,
  setReason,
}) => {
  const { register, handleSubmit } = useForm();
  const history = useHistory();
  const customerToken = useSelector(selectCustomerToken);

  const onSubmit = (data) => {
    const reason = 'do_not_like';
    setReason(reason);
    const params = {
      message: data.message,
      reason,
    };
    setModalOpen(false);
    ticketRequest(token, params);
    toastr.success(
      'Success!',
      "A ticket has been submitted to our support team. We'll be in touch!"
    );
    history.push(`/tickets/${customerToken}`);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel='Perk Bundle Modal'
      className='members-modal'
    >
      <div className='auth-modal'>
        <div className='grid-x grid-padding-x grid-padding-y'>
          <div className='small-12 medium-7 cell ds-auth-modal float-center'>
            <div className='confirmation-body has-close-button'>
              <span
                role='button'
                className='close-button'
                onClick={() => setModalOpen(false)}
              >
                x
              </span>
              <h3 className='float-center' style={{ textAlign: 'center' }}>
                Need Help? Message us here!
              </h3>
              <p style={{ textAlign: 'center', fontSize: '.9em' }}>
                What's up? Let us know how we can help and we will get back to
                you within 48 hours - ish. ❤️
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className='form-row'>
                  <label htmlFor='member_note'>
                    <textarea
                      style={{
                        width: '100%',
                        minHeight: '120px',
                        border: '1px solid #c1bebe',
                        padding: '12px 16px',
                        fontSize: '16px',
                      }}
                      name='message'
                      placeholder="Tell me why, Ain't nothin' but a heartache"
                      ref={register({ required: true })}
                    />
                  </label>
                </fieldset>
                <button type='submit'>Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BundleModal;
