import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  selectErrors,
} from './selectors';
import { redeem, retrieve } from './actions';
import { AVAILABLE_METROS } from './constants';

const getGiftCode = () => {
  const url_string = window.parent.location.href;
  const url = new URL(url_string);
  const code = url.searchParams.get('code');
  if (code) {
    return code.toUpperCase();
  }
  return '';
};

const GiftRedeem = () => {
  const dispatch = useDispatch();
  const [retrieving, setRetrieving] = useState(true);
  const [gift, setGift] = useState(null);
  const [redeeming, setRedeeming] = useState(false);
  const codeErrors = useSelector(selectErrors);
  const { register, handleSubmit, form, errors } = useForm({
    defaultValues: {
      code: getGiftCode(),
      metro_id: 60,
    },
  });

  useEffect(() => {
    const code = getGiftCode();
    if (code) {
      return new Promise((resolve, reject) => dispatch(retrieve(code, resolve, reject))).then(res => {
        setGift(res.data.gift);
        form.setValue('code', code);
        setRetrieving(false);
      }).catch(() => setRetrieving(false)); 
    }
    setRetrieving(false);
  }, []);

  const onSubmit = data => (
    new Promise((resolve, reject) => {
      setRedeeming(true);
      dispatch(redeem(data, resolve, reject));
    }).then(url => window.location.replace(url)).catch(() => setRedeeming(false))
  );

  return (
    <>
      {!retrieving &&
        <div className='ra-container'>
          {gift &&
            <div className='ra-notice'>Redeem your gift from {gift.purchaser.first_name}</div>
          }
          {codeErrors && codeErrors.error &&
            <div className='ra-errors' dangerouslySetInnerHTML={{ __html: codeErrors.error }} />
          }
          <form form={form} onSubmit={handleSubmit(onSubmit)}>
            <input
              name="email"
              className="ra-input"
              placeholder='Please enter your email...'
              ref={register({ required: true })}
            />
            {errors && errors.email && <div className='ra-field-error'>Email is required!</div>}
            <input
              name="code"
              className="ra-input"
              placeholder='Please enter your gift code...'
              ref={register({ required: true })}
            />
            {errors && errors.code && <div className='ra-field-error'>Gift code is required!</div>}
            <select
              name='metro_id'
              className='ra-select'
              ref={register({ required: true })}
              placeholder='Select your city'
              defaultValue='3'
            >
              {AVAILABLE_METROS.map(metro => <option key={`metro-picker-${metro.id}`} value={metro.id}>{metro.title}</option>)}
            </select>
            {errors && errors.metro_id && <div className='ra-field-error'>City is required!</div>}
            <button
              type='submit'
              className={classNames('ra-button ra-submit-button', {
                valid: isEmpty(errors),
                invalid: !isEmpty(errors),
                active: !redeeming,
                inactive: redeeming,
              })}
              disabled={redeeming}
            >
              {redeeming ? 'Redeeming...' : 'Redeem'}
            </button>
          </form>
        </div>
      }
      {retrieving &&
        <div className='ra-container'>
          {codeErrors.error &&
            <div className='ra-errors' dangerouslySetInnerHTML={{ __html: codeErrors.error }} />
          }
          {!codeErrors && <div className="loader">Loading...</div>}
        </div>
      }
    </>
  );
};

export default GiftRedeem;
