import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';
import { BiCheck } from 'react-icons/bi';
import Select from 'react-select';
import classNames from 'classnames';
import { get } from 'lodash';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { selectIsFreeTrial } from '../selectors';
import { selectAvailableMetros } from '../../../helpers/metro/selectors';
import { pickCity } from '../actions';
import { cityMapping } from '../constants';

const CityPicker = ({ option_name }) => {
  const [currentStep] = useState(0);
  const { search } = useLocation();
  const utmSearchParams = new URLSearchParams(search);
  const { city_name } = useParams();
  const cityParam = utmSearchParams.get('city');
  const stepProgressPercentages = [0, 16.5, 35, 45, 55, 67, 67, 100];
  const isFreeTrial = useSelector(selectIsFreeTrial);
  const availableMetros = useSelector(selectAvailableMetros);
  const [submitted] = useState(false);
  const perkReferral = false;
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultCity = cityParam || city_name;
    if (defaultCity) {
      const cityInfo = get(cityMapping, defaultCity, null);
      if (cityInfo) {
        const metro = availableMetros.find(m => m.domain.toLowerCase() === cityInfo.domain.toLowerCase());
        if (metro) {
          dispatch(pickCity(metro.id, option_name)); 
        }
      }
    }
  }, []);

  const doPickCity = ({ value: metroId }) => {
    dispatch(pickCity(metroId, option_name));
  };

  return (
    <div className='grid-x grid-padding-x grid-padding-y profile-container'>
      <div
        role='button'
        className='cancel-stepper'
        onClick={() => history.goBack()}
      >
        <GrClose />
      </div>
      <div
        className={classNames('small-11 cell float-center ds-container', {
          'stepper-container': currentStep < 7,
        })}
      >
        <ProgressBar
          percent={stepProgressPercentages[currentStep]}
          hasStepZero
          filledBackground='linear-gradient(to right, #4DB81A, #4DB81A)'
        >
          <Step>
            {() => (
              <div
                className={classNames('indexedStep', {
                  accomplished: currentStep > 0,
                  active: currentStep >= 0 && currentStep < 5,
                })}
              >
                <button className='stepper-step'>
                  {currentStep > 1 ? <BiCheck /> : '1'}
                </button>
                <div className='label'>Start</div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <div
                className={classNames('indexedStep', {
                  accomplished,
                  active: currentStep >= 2 && currentStep < 5,
                })}
              >
                <button className='stepper-step'>
                  {currentStep >= 5 ? <BiCheck /> : '2'}
                </button>
                <div className='label'>Preferences</div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
              >
                <button className='stepper-step'>
                  {currentStep >= 7 ? <BiCheck /> : '3'}
                </button>
                <div className='label'>
                  {isFreeTrial ? 'Start Trial' : 'Sign Up'}
                </div>
              </div>
            )}
          </Step>
          <Step>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
              >
                <button className='stepper-step'>
                  {currentStep > 7 || submitted ? <BiCheck /> : '4'}
                </button>
                <div className='label'>
                  {perkReferral ? 'Tickets' : 'Pick Tickets'}
                </div>
              </div>
            )}
          </Step>
        </ProgressBar>
        <section
          className='ds-stepper-section ds-stepper-section-name'
        >
          <Select
            options={availableMetros.map(m => ({ value: m.id, label: m.title }))}
            placeholder="Select Your City"
            onChange={doPickCity}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                width: '50%',
                margin: 'auto',
                marginTop: '20px',
              }),
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default CityPicker;
