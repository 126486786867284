import moment from 'moment';
import { GENRE_MAPPING } from './constants';

export const setupPayload = (params, neighborhoods) => {
  const checkboxGroups = {};
  const genres = GENRE_MAPPING.filter(g => params.genres.map(s => s.name).includes(g.label)).map(selected => selected.genres).flat();
  const { name, email } = params;
  const splitName = name.split(' ');
  const first_name = splitName[0];
  const last_name = splitName[splitName.length - 1];
  ['experiences', 'neighborhoods'].forEach(k => {
    checkboxGroups[k] = params[k].filter(i => i.name).map(j => j.name);
  });
  return {
    first_name,
    last_name,
    email,
    genres,
    neighborhoods,
    ...checkboxGroups,
  };
};

export const memberSavePayload = (params) => {
  const checkboxGroups = {};
  let bars;
  let venues;
  let bands;
  ['experiences', 'days', 'neighborhoods'].forEach(k => {
    checkboxGroups[k] = params[k].filter(i => i.name).map(j => j.name);
  });

  if (params.bars) {
    bars = params.bars.map(bar => ({ id: bar.id, venue_id: bar.venue_id, _destroy: bar._destroy, group_name: bar.group_name })); 
  } else {
    bars = [];
  }

  if (params.venues) {
    venues = params.venues.map(venue => ({ id: venue.id, venue_id: venue.venue_id, _destroy: venue._destroy, group_name: venue.group_name })); 
  } else {
    venues = [];
  }

  if (params.bands) {
    bands = params.bands.map(band => ({ id: band.id, band_id: band.band_id, _destroy: band._destroy }));
  } else {
    bands = [];
  }

  const more_preference_venues_attributes = [
    ...bars,
    ...venues,
  ];
  const more_preference_bands_attributes = bands;
  const { first_name, last_name, email, phone_number, lived, children, occupation, referrer, signup_reason, improve_survey } = params;
  const birthdate = moment(params.date_of_birth, 'MM/DD/YYYY').format('YYYY-MM-DD');
  const genre_list = GENRE_MAPPING.filter(g => params.genres.map(s => s.name).includes(g.label)).map(selected => selected.genres).flat();
  const drink = params.drink === 'true';
  return {
    ...checkboxGroups,
    first_name,
    last_name,
    email,
    phone_number,
    lived,
    children,
    occupation,
    birthdate,
    genre_list,
    drink,
    referrer,
    signup_reason,
    improve_survey,
    more_preference_venues_attributes,
    more_preference_bands_attributes,
  };
};
