import { all, fork } from 'redux-saga/effects';
import perkGiveawaySagas from '../components/PerkGiveaways/sagas';
import authSagas from '../components/Auth/sagas';
import searchSagas from '../helpers/elasticsearch/sagas';
import profileSagas from '../components/Profile/sagas';
import accountSagas from '../components/Account/sagas';
import sessionSagas from '../components/Confirmation/sagas';
import giftRedeemSagas from '../components/GiftRedeem/sagas';
import pickerSagas from '../components/Picker/sagas';

export default function* () {
  yield all([
    fork(perkGiveawaySagas),
    fork(authSagas),
    fork(searchSagas),
    fork(profileSagas),
    fork(accountSagas),
    fork(sessionSagas),
    fork(giftRedeemSagas),
    fork(pickerSagas),
  ]);
}
