import React, { useEffect } from 'react';
import moment from 'moment';
import Injector from '../../../helpers/Injector';

const bodies = {
  active: '<span style="max-width: unset;">Someone very nice has invited you to join {{domore_title}}, and join them at<br /> <strong>{{event_title}}</strong> on <strong>{{event_date}}</strong>.</span>',
  available: '<span style="max-width: unset;">Someone very nice has invited you to join {{domore_title}}, and join them at<br /> <strong>{{event_title}}</strong> on <strong>{{event_date}}</strong>.</span>',
  claimed: '<span style="max-width: unset;">You tickets for <strong>{{event_title}}</strong> have been claimed!</span>',
  confirmation: '<span style="max-width: unset;">You tickets for <strong>{{event_title}}</strong> have been claimed!<br /> Check your email for confirmation!</span>',
  expired: '<span style="max-width: unset;">All the tickets for <strong>{{event_title}}</strong> are taken unfortunately.<br />But you can still sign up for {{domore_title}} and get a great pair of tickets like these every month for just $5/month.</span>',
};

const Referral = ({ customerToken, confirmation, perkReferral: { status, perk: { event: { title: event_title, begin_time } } } }) => {
  useEffect(() => {
    if (confirmation) {
      setTimeout(() => (window.location.href = `/tickets/${customerToken}`), 4000);
    }
  }, []);
  return (
    <Injector
      body={bodies[(confirmation ? 'confirmation' : status)]}
      overrides={{
        event_title,
        event_date: moment(begin_time).format('ddd MMMM Do'),
      }}
    />
  );
};

export default Referral;
