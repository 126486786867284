import React from 'react';
import Modal from 'react-modal';
import moment from 'moment';

const ClaimModal = ({ perk, isOpen, toggleModal, claimPerk }) => (
  <>
    <Modal
      isOpen={isOpen}
      contentLabel='Last Minute Tix Modal'
      className='members-modal'
    >
      <div className='auth-modal'>
        <div className='grid-x grid-padding-x grid-padding-y'>
          <div className='small-12 medium-7 cell ds-auth-modal float-center'>
            <div className='confirmation-body has-close-button'>
              <span
                role='button'
                className='close-button'
                onClick={() => toggleModal()}
              >
                x
              </span>
              <h3 className='float-center' style={{ textAlign: 'center' }}>
                Claim 2 tickets to <br /> {perk.event_title} on <br />
                {moment(perk.event_date).format('LL')}
              </h3>
              <h5 style={{ textAlign: 'center' }}>
                Reminder - you cannot transfer last minute tix, or claim more
                than one in a 7 day period.
              </h5>
              <button
                onClick={() => {
                  claimPerk(perk.token);
                  toggleModal();
                }}
              >
                Confirm and Claim
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </>
);

export default ClaimModal;
