export const perks = {
  size: 25,
  claimed_in_last_30_days: false,
  end_datetime: 1693762679,
  start_datetime: 1693589879,
  q: {
    size: 25,
    _source: [
      'name',
      'event_date',
      'venue_title',
      'event_title',
      'image',
      'token',
      'genres',
      'experiences',
      'neighborhoods',
      'event_id',
      'token',
    ],
    query: {
      bool: {
        must_not: [
          {
            terms: {
              id: [733],
            },
          },
        ],
        filter: [
          {
            range: {
              guestlist_send_at_unix: {
                gte: 1693589879,
                lte: 1693762679,
              },
            },
          },
          {
            term: {
              metro_id: 1,
            },
          },
          {
            range: {
              remaining: {
                gt: 0,
              },
            },
          },
          {
            term: {
              exclusive: false,
            },
          },
          {
            terms: {
              status: ['active'],
            },
          },
        ],
      },
    },
  },
  perks: [
    {
      image:
        'http://localhost:2501/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35592d095b02dbd0aa37e961f04abfd0734c0fcb/0fc7a35c-54e7-4fb9-af0b-518368f2f98c.jpg',
      neighborhoods: ['northside'],
      venue_title: "Beck's on Congress",
      event_id: 4,
      genres: ['techno', 'blues'],
      event_date: '2023-08-28',
      name: 'Chad Tracy & Jane Bond gdfg',
      event_title: "Jane's Addiction",
      experiences: ['Classes'],
      token: 'dxl6Dc9CuuP88rNKLdukJA',
      event_url: '//dothebay.baw/events/4',
      related_bands_with_ids: [
        {
          title: 'Toadies',
          id: 32487,
        },
        {
          title: 'Meat Puppets',
          id: 33415,
        },
        {
          title: 'Primus',
          id: 34916,
        },
        {
          title: 'Blind Melon',
          id: 40283,
        },
        {
          title: 'Local H',
          id: 42311,
        },
        {
          title: 'Stone Temple Pilots',
          id: 42524,
        },
        {
          title: 'Mudhoney',
          id: 43055,
        },
        {
          title: 'Living Colour',
          id: 52540,
        },
        {
          title: 'Alice In Chains',
          id: 59293,
        },
        {
          title: 'SOUL ASYLUM',
          id: 64967,
        },
        {
          title: 'Soundgarden',
          id: 68578,
        },
        {
          title: 'Candlebox',
          id: 71634,
        },
        {
          title: 'Days Of The New',
          id: 72257,
        },
      ],
    },
    {
      image:
        'http://localhost:2501/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbHNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebe79c806a9c035f4b549f22eac86ef0c0574e71/173edb48-2098-4913-a67d-64fe4de676aa.png',
      neighborhoods: ['northside'],
      venue_title: 'Austin Folk House Bed and Breakfast',
      event_id: 52,
      genres: [
        'art-pop',
        'brighton-indie',
        'chamber-pop',
        'dark-pop',
        'dream-pop',
        'etherpop',
        'indietronica',
        'metropopolis',
        'neo-synthpop',
      ],
      event_date: '2033-09-01',
      name: 'Bat For Lashes',
      event_title: 'Bat For Lashes',
      experiences: ['Concerts'],
      token: 'RBHiFmPUh2ZKLIlU9L52EQ',
      event_url: '//dothebay.baw/events/52',
      related_bands_with_ids: [],
    },
  ],
  records: [
    {
      id: 480,
      token: 'wieufhbw98aksjdh',
      name: 'Chad Tracy & Jane Bond gdfg',
      start_date: '2021-06-11',
      neighborhoods: ['northside'],
      experiences: ['Classes'],
      event_image_url:
        'http://localhost:2501/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBWkU9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--35592d095b02dbd0aa37e961f04abfd0734c0fcb/0fc7a35c-54e7-4fb9-af0b-518368f2f98c.jpg',
      genres: ['techno', 'blues'],
      related_bands_with_ids: [
        {
          title: 'Toadies',
          id: 32487,
        },
        {
          title: 'Meat Puppets',
          id: 33415,
        },
        {
          title: 'Primus',
          id: 34916,
        },
        {
          title: 'Blind Melon',
          id: 40283,
        },
        {
          title: 'Local H',
          id: 42311,
        },
        {
          title: 'Stone Temple Pilots',
          id: 42524,
        },
        {
          title: 'Mudhoney',
          id: 43055,
        },
        {
          title: 'Living Colour',
          id: 52540,
        },
        {
          title: 'Alice In Chains',
          id: 59293,
        },
        {
          title: 'SOUL ASYLUM',
          id: 64967,
        },
        {
          title: 'Soundgarden',
          id: 68578,
        },
        {
          title: 'Candlebox',
          id: 71634,
        },
        {
          title: 'Days Of The New',
          id: 72257,
        },
      ],
      guestlist_sent: true,
      event: {
        id: 4,
        venue_id: 54696,
        title: "Jane's Addiction",
        description: '',
        begin_date: '2033-09-01',
        begin_time: '2033-09-01T12:21:58.000-05:00',
        end_date: '2033-09-01',
        end_time: '2033-09-01T08:00:00.000-05:00',
        created_at: '2009-06-15T14:32:42.000-05:00',
        updated_at: '2023-09-01T12:21:58.000-05:00',
        permalink: 'janes-addiction',
        popularity: 15081,
        demoter: 0,
        repeat_until: null,
        repeat_token: null,
        requires_rsvp: 0,
        is_private: 0,
        user_id: 1,
        votes_count: 15081,
        comments_count: 0,
        is_band_battle: 0,
        buy_tickets: null,
        is_all_star_pick: false,
        offer: null,
        repeat_dow: null,
        is_advertised: false,
        allstar_votes_count: 0,
        category_id: 500,
        is_featured: false,
        festival_id: 12,
        win_tickets: null,
        rsvp_text: null,
        font_size: '1.6',
        metro_id: 1,
        approved: true,
        scrape_id: null,
        pending_dupe: false,
        conference_id: null,
        conference_category_id: null,
        external_id: null,
        ticket_info: null,
        ticket_onsale: null,
        ticket_presale_code: null,
        long_offer: '',
        rsvp_opt_in: false,
        rsvp_opt_in_text: null,
        is_guestlist: false,
        guestlist_sponsor_logo_url: null,
        guestlist_slots: null,
        guestlist_slots_visible: null,
        guestlist_email: null,
        guestlist_send_hours_prior: null,
        guestlist_sent: false,
        rsvp_ask_zip: false,
        rsvp_ask_birth: false,
        rsvp_ask_affiliation: false,
        rsvp_confirm: true,
        is_afterparty: false,
        indexed_by_search: false,
        alt_description: null,
        keywords: null,
        show_doors: false,
        rsvp_allow_guest: false,
        flickr_id: null,
        flickr_tag: null,
        display_slideshow: false,
        is_locked: false,
        slideshow_credits: null,
        presented_by: null,
        custom_css: null,
        giveaway_spots: 0,
        giveaway_email: '',
        giveaway_notes: '',
        rsvp_use_passcode: false,
        rsvp_passcode: null,
        eventbrite_id: null,
        alt_url: null,
        is_ongoing: false,
        rsvp_custom_field: null,
        ticket_onsale_time: null,
        indexed_by_live_search: false,
        tickets_sold_out: null,
        facebook_event_id: null,
        rsvp_type: 10,
        alt_title: null,
        guestlist_closed_message: null,
        ask_21: true,
        venue: {
          id: 54696,
          neighborhood_id: null,
          title: "Ophelia's Electric",
          description: '',
          url: '',
          city: 'f',
          state: 'TX',
          zip: '78757',
          created_at: '2022-05-25T16:53:54.000-05:00',
          updated_at: '2023-07-19T14:39:04.000-05:00',
          permalink: 'tesdfsdf',
          events_count: 0,
          phone: '',
          address: 'sf',
          flickr_tag: null,
          popularity: 1,
          is_friend: 0,
          comments_count: 0,
          is_official: 0,
          user_id: null,
          is_advertiser: false,
          keyword: null,
          not_in_austin: false,
          festival_id: null,
          metro_id: 1,
          festival_sort_order: null,
          offer: null,
          patrons_count: 0,
          category_id: null,
          monthly_spend: null,
          average_spend_per_person: '15.0',
          guestlist_email: null,
          guestlist_send_hours_prior: null,
          importance: 0,
          latitude: null,
          longitude: null,
          indexed_by_search: false,
          keywords: ', The Ritz, The Blue Theatre, Chicago 2016',
          facebook_url: '',
          twitter: '',
          facebook_id: 0,
          hours: '',
          giveaway_email: '',
          indexed_by_live_search: false,
          is_locked: false,
          cached_rating: null,
          geocoded_at: null,
          instagram_url: null,
        },
      },
      copy_context: {
        id: 155,
        claim: 'Claim Body',
        release: 'Release Body',
        transfer: 'Transfer Body',
        api_perk_id: 480,
        created_at: '2021-06-22T18:12:02.852-05:00',
        updated_at: '2021-06-22T18:12:02.852-05:00',
        claim_subject: 'Claim Subject',
        release_subject: 'Release Subject',
        transfer_subject: 'Transfer Subject',
        cards_bind_subject: 'Nice one son',
        cards_bind: null,
        perk_confirmation: null,
        perk_confirmation_subject: null,
        offer_details: '<p>Testd</p>',
        offer_details_subject: 'Test',
        templates: {
          claim: {
            body: 'Claim Body',
            subject: 'Claim Subject',
          },
          release: {
            body: 'Release Body',
            subject: 'Release Subject',
          },
          transfer: {
            body: 'Transfer Body',
            subject: 'Transfer Subject',
          },
          offer: {
            body: '<p>Testd</p>',
            subject: 'Test',
          },
        },
      },
      partner: {
        organization: 'Nice one',
        logo_url: '',
      },
    },
    {
      id: 739,
      token: 'wieufhbw98aksjd23h',
      name: 'Bat For Lashes',
      start_date: null,
      neighborhoods: ['northside'],
      experiences: ['Concerts'],
      event_image_url:
        'http://localhost:2501/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbHNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebe79c806a9c035f4b549f22eac86ef0c0574e71/173edb48-2098-4913-a67d-64fe4de676aa.png',
      genres: [
        'art-pop',
        'brighton-indie',
        'chamber-pop',
        'dark-pop',
        'dream-pop',
        'etherpop',
        'indietronica',
        'metropopolis',
        'neo-synthpop',
      ],
      related_bands_with_ids: [],
      guestlist_sent: false,
      event: {
        id: 52,
        venue_id: 32,
        title: 'Bat For Lashes',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet ac justo non sagittis. Nunc elementum est eu metus congue, sit amet interdum lorem tincidunt. Maecenas aliquam hendrerit est, vitae vulputate odio commodo ut. Donec placerat congue lacus et ultrices. In non libero lorem. Morbi et interdum ante. Pellentesque vulputate viverra dui, vel tincidunt quam sodales eget. Quisque feugiat tempus augue, sit amet dapibus eros molestie eget. Vivamus augue nibh, commodo et lectus vel, scelerisque imperdiet tortor. In eget mi a quam posuere rhoncus at vel augue. Phasellus vehicula nunc risus, in sollicitudin tortor egestas sit amet. Nunc mattis nulla ac mi auctor tristique.\r\n\r\nIn non dapibus metus. Mauris dictum, diam in fermentum scelerisque, lacus nulla maximus lectus, non pellentesque ante ex molestie nunc. Aliquam nec nulla tristique, tincidunt felis et, lacinia lorem. Praesent euismod accumsan nulla. In efficitur nibh et metus eleifend ultrices. Nunc gravida tempor aliquam. Vivamus eu placerat urna. Phasellus et cursus lorem. In hac habitasse platea dictumst.\r\n\r\nMauris malesuada tortor neque, ut sodales lorem accumsan vitae. Cras interdum nulla consequat sem porta bibendum in eget magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras mi orci, rhoncus nec porttitor quis, blandit nec sem. Etiam et elit id sapien egestas finibus sed sed mauris. Quisque suscipit orci eget orci pellentesque varius. Nullam feugiat ante at mollis aliquam. Ut sagittis sollicitudin sagittis. Fusce tortor ligula, auctor vitae dapibus non, ornare in mauris. Aliquam erat volutpat. Vestibulum suscipit quam ante, eget sagittis ante lacinia eu.',
        begin_date: '2033-09-01',
        begin_time: '2033-09-01T12:00:00.000-05:00',
        end_date: '2035-08-16',
        end_time: '2035-08-16T13:15:00.000-05:00',
        created_at: '2009-06-15T14:32:46.000-05:00',
        updated_at: '2023-09-01T12:34:50.000-05:00',
        permalink: 'bat-for-lashes',
        popularity: 7129,
        demoter: 0,
        repeat_until: null,
        repeat_token: null,
        requires_rsvp: 0,
        is_private: 0,
        user_id: 1,
        votes_count: 7129,
        comments_count: 0,
        is_band_battle: 0,
        buy_tickets: '',
        is_all_star_pick: false,
        offer: null,
        repeat_dow: null,
        is_advertised: false,
        allstar_votes_count: 0,
        category_id: 500,
        is_featured: false,
        festival_id: 12,
        win_tickets: null,
        rsvp_text: null,
        font_size: '1.6',
        metro_id: 1,
        approved: true,
        scrape_id: null,
        pending_dupe: false,
        conference_id: null,
        conference_category_id: null,
        external_id: '',
        ticket_info: '$20',
        ticket_onsale: null,
        ticket_presale_code: null,
        long_offer: '',
        rsvp_opt_in: false,
        rsvp_opt_in_text: null,
        is_guestlist: false,
        guestlist_sponsor_logo_url: null,
        guestlist_slots: null,
        guestlist_slots_visible: null,
        guestlist_email: null,
        guestlist_send_hours_prior: null,
        guestlist_sent: false,
        rsvp_ask_zip: false,
        rsvp_ask_birth: false,
        rsvp_ask_affiliation: false,
        rsvp_confirm: true,
        is_afterparty: false,
        indexed_by_search: false,
        alt_description: null,
        keywords: '',
        show_doors: false,
        rsvp_allow_guest: false,
        flickr_id: null,
        flickr_tag: null,
        display_slideshow: false,
        is_locked: false,
        slideshow_credits: null,
        presented_by: '',
        custom_css: null,
        giveaway_spots: 0,
        giveaway_email: '',
        giveaway_notes: '',
        rsvp_use_passcode: false,
        rsvp_passcode: null,
        eventbrite_id: null,
        alt_url: null,
        is_ongoing: true,
        rsvp_custom_field: null,
        ticket_onsale_time: '0001-01-01T00:00:00.000-05:50',
        indexed_by_live_search: false,
        tickets_sold_out: false,
        facebook_event_id: null,
        rsvp_type: 10,
        alt_title: null,
        guestlist_closed_message: null,
        ask_21: true,
        venue: {
          id: 32,
          neighborhood_id: 19,
          title: 'Austin Folk House Bed and Breakfast',
          description:
            '<p>Welcome to our little hotel! We have the closest accommodations to the University of Texas at Austin. You should contact us for game weekends, orientation and graduation.</p>\r\n<p>We take a lot of pride in taking care of our guests.</p>',
          url: 'http://www.austinfolkhouse.com',
          city: 'Austin',
          state: 'TX',
          zip: '60645',
          created_at: '2006-09-06T20:17:05.000-05:00',
          updated_at: '2023-07-19T13:36:39.000-05:00',
          permalink: 'austin-folk-house',
          events_count: 0,
          phone: '(512) 472-6700',
          address: '506 W. 22nd St.',
          flickr_tag: 'austinfolkhouse',
          popularity: 0,
          is_friend: 0,
          comments_count: 0,
          is_official: 1,
          user_id: null,
          is_advertiser: false,
          keyword: '',
          not_in_austin: false,
          festival_id: null,
          metro_id: 1,
          festival_sort_order: null,
          offer: null,
          patrons_count: null,
          category_id: null,
          monthly_spend: null,
          average_spend_per_person: '15.0',
          guestlist_email: null,
          guestlist_send_hours_prior: null,
          importance: 0,
          latitude: null,
          longitude: null,
          indexed_by_search: false,
          keywords: null,
          facebook_url: null,
          twitter: '',
          facebook_id: 0,
          hours: null,
          giveaway_email: '',
          indexed_by_live_search: false,
          is_locked: false,
          cached_rating: null,
          geocoded_at: null,
          instagram_url: null,
        },
      },
      copy_context: {
        id: 363,
        claim: '<p>Click the button below to claim your tickets!</p>',
        release: '<p>Click the button below to release your tickets!</p>',
        transfer:
          '<p>Please provide the following information and click the button below to transfer your perk!</p><p>DoMORE ticket offers can be transferred or gifted, but&nbsp;<strong>may</strong> <strong>never be&nbsp;resold.</strong></p><p>Violation of these terms will result in immediate termination of your DoMORE Membership.</p>',
        api_perk_id: 739,
        created_at: '2023-06-16T12:37:10.118-05:00',
        updated_at: '2023-06-16T12:37:10.118-05:00',
        claim_subject: 'Claim Tickets to {{event_title}}',
        release_subject: 'Release tickets to {{event_title}}',
        transfer_subject: 'Gift Your Tickets',
        cards_bind_subject: 'Nice one son',
        cards_bind: null,
        perk_confirmation: null,
        perk_confirmation_subject: null,
        offer_details:
          '<p><a href="{{event_details}}" rel="noopener noreferrer" target="_blank" style="background-color: rgb(255, 255, 255);">Click here for Event Details.</a></p>',
        offer_details_subject: '{{event_title}}',
        templates: {
          claim: {
            body: '<p>Click the button below to claim your tickets!</p>',
            subject: 'Claim Tickets to {{event_title}}',
          },
          release: {
            body: '<p>Click the button below to release your tickets!</p>',
            subject: 'Release tickets to {{event_title}}',
          },
          transfer: {
            body:
              '<p>Please provide the following information and click the button below to transfer your perk!</p><p>DoMORE ticket offers can be transferred or gifted, but&nbsp;<strong>may</strong> <strong>never be&nbsp;resold.</strong></p><p>Violation of these terms will result in immediate termination of your DoMORE Membership.</p>',
            subject: 'Gift Your Tickets',
          },
          offer: {
            body:
              '<p><a href="{{event_details}}" rel="noopener noreferrer" target="_blank" style="background-color: rgb(255, 255, 255);">Click here for Event Details.</a></p>',
            subject: '{{event_title}}',
          },
        },
      },
      partner: {
        organization: 'Smithy',
        logo_url:
          'http://localhost:2501/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBCZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--94f0d3dc00090525493b94ffe304899c08c02f9e/8769980946644468862.jpg',
      },
    },
  ],
};
export const mockPerks = [
  {
    image:
      'https://cloudinary-assets.dostuffmedia.com/res/dostuff-media/image/upload/band-147774/1529419775.jpg',
    neighborhoods: ['northside'],
    venue_title: 'Amazing Stadium',
    genres: [
      'alternative-pop',
      'alternative-rock',
      'boston-rock',
      'dance-rock',
      'dream-pop',
      'garage-rock',
      'indie-rock',
      'lilith',
      'lo-fi',
      'new-wave',
      'noise-pop',
      'post-punk',
      'rock',
      'canadian-hardcore',
      'canadian-metal',
      'canadian-punk',
      'hardcore-punk',
      'indie-punk',
      'modern-rock',
      'post-hardcore',
      'freak-folk',
      'neo-psychedelic',
      'psychedelic-space-rock',
      'space-rock',
    ],
    event_date: '2022-10-01',
    name: 'Testing Sending Again',
    event_title: 'JOHN: HEAVEN / WORLD TOUR',
    experiences: ['Cannabis', 'Classes', 'Comedy'],
    token: 'n1CyAQ82au2ix91IM_7yKg',
  },
  {
    image:
      'https://assets0.dostuffmedia.com/uploads/aws_asset/aws_asset/10085229/a1344d13-f0d7-424e-b9e9-e813b16d2a31.jpg',
    neighborhoods: ['downtown'],
    venue_title: 'Park on the water',
    genres: [
      'alternative-pop',
      'alternative-rock',
      'boston-rock',
      'dance-rock',
      'dream-pop',
      'garage-rock',
      'indie-rock',
      'lilith',
      'lo-fi',
      'new-wave',
      'noise-pop',
      'post-punk',
      'rock',
      'canadian-hardcore',
      'canadian-metal',
      'canadian-punk',
      'hardcore-punk',
      'indie-punk',
      'modern-rock',
      'post-hardcore',
      'freak-folk',
      'neo-psychedelic',
      'psychedelic-space-rock',
      'space-rock',
    ],
    event_date: '2022-10-15',
    name: 'Artists Filter',
    event_title: 'BEST OF THE BIG CITY',
    experiences: ['Classes', 'Concerts', 'Cannabis'],
    token: 'vQlZPJ18RqAJg9YZROApQA',
  },
  {
    image:
      'https://assets0.dostuffmedia.com/uploads/aws_asset/aws_asset/10642157/44f860df-1e60-4ec3-9647-0499d778b27a.jpg',
    neighborhoods: ['downtown'],
    venue_title: 'The Music Center',
    genres: [
      'alternative-rock',
      'art-rock',
      'melancholia',
      'oxford-indie',
      'permanent-wave',
      'rock',
      'alternative-hip-hop',
      'big-beat',
      'downtempo',
      'electronic',
      'hip-hop',
      'nu-jazz',
      'trip-hop',
      'turntablism',
      'grunge',
      'post-grunge',
    ],
    event_date: '2022-11-17',
    name: 'Fuck Sake',
    event_title: 'The White Keys feat. Pre Jabrone',
    experiences: ['Cannabis', 'Concerts', 'Drinks'],
    token: 'Jvlei_DLEWGdcAkmzXvu8A',
  },
];
