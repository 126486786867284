import { SPOTIFY_IMPORT_FINISHED } from '../constants';
// Initial Store State
const initialState = {
  connected: {
    MainChannel: false,
    OtherChannel: false,
  },
  imported_bands: [],
};

// Redux Reducer function
export default function spotifyReducer(state = initialState, action) {
  switch (action.type) {
    case 'redux-cablecar/DISCONNECTED':
    case 'redux-cablecar/CONNECTED':
      return {
        ...state,
        connected: {
          ...state.connected,
          [action.meta.channel]: action.type === 'redux-cablecar/CONNECTED',
        },
      };

    case 'NEW_MSG':
    case 'redux-cablecar/NEW_MSG':
      return {
        state,
      };

    case SPOTIFY_IMPORT_FINISHED:
      return {
        ...state,
        imported_bands: action.payload.bands,
      };

    default:
      return state;
  }
}
