import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectCityName,
  selectSupportEmail,
} from '../../helpers/metro/selectors';

const EventMessage = ({ event }) => {
  const supportEmail = useSelector(selectSupportEmail);
  const cityName = useSelector(selectCityName);
  if (event === 'new') {
    return (
      <>
        <p>
          Welcome to DoMORE {cityName}! Keep a watchful eye on your inbox, you'll hear from us as soon as you're off the waitlist and your three months officially begin.
        </p>
        <p>
          Speaking of your inbox... make sure you just got a confirmation email from us. If not, check your spam folder and move the sucker to that inbox.  Now, let's get this party started, shall we? And by party, we mean filling out a brief survey telling us about yourself and the kinds of stuff you like doing. The more we know about you, the better we can hook you up with tickets to stuff you're into.
        </p>
        <p>
          Thanks again for wanting to #DoMORE with us, it's going to be awesome. And please don't hesitate to hit us up with any thoughts, questions and/or feedback: <a href={`mailto: ${supportEmail}`}>{supportEmail}</a>
        </p>
      </>
    );
  }
  if (event === 'claimed') {
    return (
      <>
        <p>Great job claiming your first free tickets! Check your email for confirmation.</p>
        <p>
          We've built you a DoMORE {cityName} portal. Here you can see past and future events you're matched with, and build your profile with more preferences for better matches.
        </p>
      </>
    );
  }
  if (event === 'unclaimed') {
    return (
      <p>
        We've built you a DoMORE {cityName} portal. Here you can see past and future events you're matched with, and build your profile with more preferences for better matches.
      </p>
    );
  }
  return null;
};

export default EventMessage;
