import {
  REDEEM,
  CONFIRMATION,
  ERRORS,
  RETRIEVE,
} from './constants';

export const redeem = (params, resolve, reject) => ({
  type: REDEEM,
  params,
  resolve,
  reject,
});

export const confirmation = (data) => ({
  type: CONFIRMATION,
  data,
});

export const errors = (data) => ({
  type: ERRORS,
  data,
});

export const retrieve = (code, resolve, reject) => ({
  type: RETRIEVE,
  code,
  resolve,
  reject,
});
