import React from 'react';

const Errors = ({ errors }) => (
  <div className="callout alert">
    <ul style={{ color: 'red' }}>
      {Object.keys(errors).map((field => <li key={`error-for-${field}`}>{errors[field].message}</li>))}
    </ul>
  </div>
);

export default Errors;
