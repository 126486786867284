import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PuffLoader from 'react-spinners/PuffLoader';
import { selectSupportEmail } from '../../helpers/metro/selectors';
import { updateCard } from './actions';
import { selectCustomerToken } from '../Profile/selectors';

const StripeInput = () => {
  const supportEmail = useSelector(selectSupportEmail);
  const customerToken = useSelector(selectCustomerToken);
  const [stripeError, setStripeError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [cardUpdated, setCardUpdated] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const stripeRequest = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      if (error.code === 'incomplete_zip') {
        setStripeError('Zipcode is required!');
      } else {
        setStripeError(
          `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
        );
      }
    } else {
      return new Promise((resolve, reject) =>
        dispatch(updateCard(token.id, resolve, reject))
      )
        .then(() => {
          cardElement.clear();
          setCardUpdated(true);
          setTimeout(() => {
            location.href = `/profile/${customerToken}`;
          }, 4000);
        })
        .catch(() => {
          setUpdating(false);
          setStripeError(
            `There was a problem with your card.  Please try again.  If the problem persists contact ${supportEmail}`
          );
        });
    }
  };

  return (
    <>
      <div>
        {updating && (
          <p style={{ textAlign: 'center' }}>
            {updating &&
              !cardUpdated &&
              'Updating your information!  May take a minute.'}
            {updating &&
              cardUpdated &&
              'Billing information updated!  Activating account!'}
            <br />
            <PuffLoader loading={updating} size={25} />
          </p>
        )}
        {!updating && (
          <p>
            Our attempt to charge your card was unsuccessful. To prevent
            suspension, reach out to your bank or update your billing
            information.
          </p>
        )}
      </div>
      <div style={updating ? { display: 'none' } : {}}>
        <form>
          <section style={{ marginBottom: 0 }}>
            <div className='payment-info stepper-payment-info'>
              {stripeError && (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '.8em',
                    color: 'red',
                    marginBottom: '10px',
                  }}
                >
                  {stripeError}
                </div>
              )}
              <fieldset>
                <label htmlFor='card-element'>
                  <CardElement id='card-element' className='field' />
                </label>
              </fieldset>
              <button
                disabled={updating}
                type='button'
                onClick={() => {
                  setUpdating(true);
                  stripeRequest();
                }}
              >
                Update
              </button>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default StripeInput;
