import React from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';

const EmailModal = ({ isModalOpen, closeModal }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => console.log(data);

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Email Gift"
      className='members-modal'
    >
      <div className='card members-modal-body'>
        <div className='card-divider'><h4>Email gift to friend!</h4></div>
        <div className='card-section'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset className='form-row'>
              <label htmlFor='name'>
                <span>Name</span>
                <input name="name" placeholder='Enter friends name...' className="field" ref={register({ required: true })} />
              </label>
            </fieldset>
            <fieldset className='form-row'>
              <label htmlFor='email'>
                <span>Email</span>
                <input name="email" placeholder='Enter friends email...' className="field" ref={register({ required: true })} />
              </label>
            </fieldset>
            {errors.name && <div className='required-field-error'>First name is required!</div>}
            {errors.email && <div className='required-field-error'>Email is required!</div>}
            <button>Email</button>
          </form>
          <br />
          <div style={{ textAlign: 'center', marginTop: '15px' }}>
            <a role='button' onClick={() => closeModal(false)} style={{ fontSize: '15px', color: 'red' }}>Cancel</a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmailModal;
