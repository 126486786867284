import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import perkGiveawaysReducer from '../components/PerkGiveaways/reducer';
import metroReducer from '../helpers/metro/reducer';
import profileReducer from '../components/Profile/reducer';
import authReducer from '../components/Auth/reducer';
import elasticsearchReducer from '../helpers/elasticsearch/reducer';
import spotifyReducer from '../components/Profile/Spotify/reducer';
import giftRedeemReducer from '../components/GiftRedeem/reducer';

export default combineReducers({
  metro: metroReducer,
  perk_giveaways: perkGiveawaysReducer,
  profile: profileReducer,
  toastr: toastrReducer,
  auth: authReducer,
  elasticsearch: elasticsearchReducer,
  spotify: spotifyReducer,
  gift_redeem: giftRedeemReducer,
});
