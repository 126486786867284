import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import * as Sentry from '@sentry/react';
import { selectGTMId } from './helpers/metro/selectors';
import Header from './components/layout/Header';
import PerkGiveaways from './components/PerkGiveaways/PerkGiveaways';
import ShowPerkGiveaway from './components/PerkGiveaways/Show/Show';
import Profile from './components/Profile/Profile';
import Account from './components/Account/Account';
import Faq from './components/Faq/Faq';
import { selectModalVisibility } from './components/PerkGiveaways/selectors';
import TransferModal from './components/PerkGiveaways/TransferModal';
import Confirmation from './components/Confirmation/Confirmation';
import Gifts from './components/Gifts/Gifts';
import { Stepper } from './components/Profile/Stepper';
import Picker from './components/Picker/Picker';
import MagicLink from './components/Auth/MagicLink';
import ShortToken from './components/Profile/ShortToken';
import CurrentOffers from './components/PerkGiveaways/CurrentOffers';
import SmsOptin from './components/Profile/SmsOptin';
import { selectCustomerEmail } from './components/Profile/selectors';
import Annual from './components/Account/Annual';

const stripePromise = loadStripe(window.stripe_key ? window.stripe_key : '');

const App = ({ cableCarRoute }) => {
  const isModalOpen = useSelector(selectModalVisibility);
  const gtmId = useSelector(selectGTMId);
  const gtmParams = { id: gtmId };
  const email = useSelector(selectCustomerEmail);
  Sentry.setUser({
    email,
  });
  return (
    <Router>
      <div className="grid-x">
        <div className="small-12 cell ds-main">
          <Switch>
            <Route path="/faq/:customerToken">
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <Faq />
                </GTMProvider>
              </Elements>
            </Route>
            <Route
              path={[
                '/perks/:customerToken/:token/:action',
                '/tickets/:customerToken/:token/:action',
              ]}
            >
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <ShowPerkGiveaway />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path={['/perks/:customerToken', '/tickets/:customerToken']}>
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <PerkGiveaways />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path={'/offers/:customerToken'}>
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <CurrentOffers />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path="/lmp/:customerToken">
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <Picker />
                </GTMProvider>
              </Elements>
            </Route>
            <Route
              path={[
                '/profile/:customerToken/:event',
                '/profile/:customerToken/',
              ]}
            >
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <Profile cableCarRoute={cableCarRoute} />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path={['/account/:customerToken']}>
              <GTMProvider state={gtmParams}>
                <Header />
                <Account cableCarRoute={cableCarRoute} />
              </GTMProvider>
            </Route>
            <Route path={['/annual/:customerToken']}>
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header />
                  <Annual cableCarRoute={cableCarRoute} />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path={['/sms/:customerToken']}>
              <Header noNav doMoreLogo />
              <SmsOptin />
            </Route>
            <Route path="/confirmation/:sessionId">
              <GTMProvider state={gtmParams}>
                <Header noNav />
                <Confirmation />
              </GTMProvider>
            </Route>
            <Route path="/gift-cards/:sessionId">
              <GTMProvider state={gtmParams}>
                <Header noNav />
                <Gifts />
              </GTMProvider>
            </Route>
            <Route path="/register/:plan_name/r/:referral_code">
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header noNav />
                  <Stepper />
                </GTMProvider>
              </Elements>
            </Route>
            <Route
              path={[
                '/register/:plan_name/p/:partner_name',
                '/:city_name/register/:plan_name/p/:partner_name',
              ]}
            >
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header noNav partner />
                  <Stepper newPath optionName="monthly" />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path="/register/:plan_name">
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header noNav />
                  <Stepper newPath optionName="monthly" />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path="/:city_name/register/:plan_name">
              <Elements stripe={stripePromise}>
                <GTMProvider state={gtmParams}>
                  <Header noNav />
                  <Stepper newPath optionName="monthly" />
                </GTMProvider>
              </Elements>
            </Route>
            <Route path="/s/:short_token">
              <ShortToken />
            </Route>
            <Route path="/">
              <Header noNav />
              <MagicLink />
            </Route>
          </Switch>
        </div>
      </div>
      <TransferModal isModalOpen={isModalOpen} />
    </Router>
  );
};

export default App;
